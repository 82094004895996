import { atom } from "recoil";

export type PlaceTrade = {
  orderType: number;
  orderDirection: number;
  price: number;
  quantity: number;
  leverage: number;
};

export const placeTradeState = atom<PlaceTrade>({
  key: "placeTradeState",
  default: {
    orderType: 0,
    orderDirection: 0,
    price: 0.0,
    quantity: 0.0,
    leverage: 1.0,
  },
});
