import { atom } from "recoil";

type AccountStateValue = { negative: boolean; decimal: string };

type AccountState = {
  balance?: AccountStateValue;
  buying_power?: AccountStateValue;
  equity?: AccountStateValue;
  leverage?: AccountStateValue;
  total_position_value?: AccountStateValue;
  unrealized_pnl?: AccountStateValue;
};

export const accountState = atom<AccountState>({
  key: "accountState",
  default: {},
});
