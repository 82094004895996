import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Image from "../Image";
import styles from "./Footer.module.sass";

const menu = [
  // {
  //   title: "Market",
  //   url: "/",
  // },
  {
    title: "Portfolio",
    url: "/",
  },
  {
    title: "Bridge",
    url: "/bridge",
  },
  {
    title: "Trade",
    url: "/trade",
  },
  // {
  //   title: "Lite",
  //   url: "/lite",
  // },
];

const socialMenu = [
  {
    title: "Discord",
    url: "https://discord.gg/JEdvcZAb7A",
  },
  {
    title: "Twitter",
    url: "https://twitter.com/VortexProtocol",
  },
];

const documentationMenu = [
  {
    title: "Documentation",
    url: "https://docs.vortexprotocol.io/",
  },
  {
    title: "How to Use Vortex",
    url: "https://docs.vortexprotocol.io/how-to-use-vortex",
  },
  {
    title: "Sei Network",
    url: "https://www.seinetwork.io/",
  },
];

const socials = [
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/VortexProtocol",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <a className={styles.logo} href="https://vortexprotocol.io">
              <Image
                className={styles.picDesktop}
                src="/images/logo-light.svg"
                srcDark="/favicon-32x32.png"
                alt="Vortex"
              />
            </a>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                footer nav
                <Icon name="arrow-down" size="24" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={cn(styles.link, {
                      [styles.active]:
                        x.url === window.location.pathname ||
                        x.url.slice(1) ===
                        window.location.pathname.split("/")[1],
                    })}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {socialMenu.map((x, index) => (
                <a
                  className={styles.link}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  {x.title}
                </a>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {documentationMenu.map((x, index) => (
                <a
                  className={styles.link}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  {x.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2022 Vortex Markets. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
