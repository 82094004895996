import { selectorFamily } from "recoil";
import { oraclePricesState } from "../atoms/oraclePrices";

export const pairOraclePricesState = selectorFamily({
  key: "PairOraclePricesState",
  get:
    (pair: string) =>
    ({ get }) => {
      return get(oraclePricesState)[pair];
    },
});
