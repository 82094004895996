/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useRecoilValue } from "recoil";
// @ts-ignore
import { queryClient } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module";
import Dropdown from "../../../components/Dropdown";
import TradingViewLite from "../TradingViewLite";
import DepthChart from "../DepthChart";
import { lcdURL, useUrl } from "../../../context";
import useHistoricalPrices from "../../../context/hooks/useHistoricalPrices";
import styles from "./Charts.module.sass";
import { orderBookState } from "../../../recoil/atoms";

const timeframes = ["5s", "15s", "30s", "1m", "3m", "5m"];
const timeframesMapping = {
  "5s": 5,
  "15s": 15,
  "30s": 30,
  "1m": 60,
  "3m": 180,
  "5m": 300,
};
const numTimeframeDatapointsMapping = {
  "5s": 20000,
  "15s": 10000,
  "30s": 5000,
  "1m": 2500,
  "3m": 833,
  "5m": 500,
};

const navigation = ["Candlesticks", "Line", "Depth"];

const Charts = () => {
  const [activeIndexDates, setActiveIndexDates] = useState(0);
  const [activeIndexNav, setActiveIndexNav] = useState(0);
  const [timeframe, setTimeframe] = useState(timeframes[1]);
  const { getCandlesticks } = useHistoricalPrices();
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const container = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(2000);
  const [containerHeight, setContainerHeight] = useState<number>(1600);
  const [candlesticks, setCandlesticks] = useState<any[]>();
  const orderBook = useRecoilValue(orderBookState);

  let interval: any;

  const queryCandlesticks = async () => {
    const client = await queryClient({ addr: lcdURL });

    try {
      await getCandlesticks(
        client,
        priceDenomString,
        assetDenomString,
        //@ts-ignore
        timeframesMapping[timeframes[activeIndexDates]],
        //@ts-ignore
        numTimeframeDatapointsMapping[timeframes[activeIndexDates]]
      ).then((res) => {
        if (res && res.length > 0) {
          setCandlesticks(res);
        }
      });

      return null;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (interval) clearInterval(interval);

    queryCandlesticks().then();

    interval = setInterval(() => {
      if (!pair) return;

      queryCandlesticks().then();
    }, 60000);

    return () => clearInterval(interval);
  }, [pair, activeIndexDates]);

  useEffect(() => {
    if (container.current) {
      setContainerWidth(container.current.offsetWidth);
      setContainerHeight(container.current.offsetHeight);
    }
  }, [container]);

  const setDropdownTimestamp = (x: string) => {
    setTimeframe(x);
    setActiveIndexDates(timeframes.indexOf(x));
  };

  const renderActiveChart = () => {
    if (activeIndexNav === 1) {
      return (
        <TradingViewLite
          key={activeIndexNav}
          mode="line"
          height={containerHeight}
          width={containerWidth}
          candlesticksData={candlesticks}
          fundingHistoryData={undefined}
        />
      );
    }

    // if (activeIndexNav === 2) {
    //   return (
    //     <TradingViewLite
    //       key={activeIndexNav}
    //       mode="funding"
    //       height={containerHeight}
    //       width={containerWidth}
    //       fundingHistoryData={pairFundingRate}
    //       candlesticksData={null}
    //     />
    //   );
    // }

    if (activeIndexNav === 2) {
      return (
        <DepthChart
          key={activeIndexNav}
          width={containerWidth}
          height={containerHeight}
          shortBook={orderBook && orderBook[pair].shortBook}
          longBook={orderBook && orderBook[pair].longBook}
        />
      );
    }

    return (
      <TradingViewLite
        key={activeIndexNav}
        mode="candlesticks"
        height={containerHeight}
        width={containerWidth}
        candlesticksData={candlesticks}
        fundingHistoryData={undefined}
      />
    );
  };

  return (
    <div className={styles.charts}>
      <div className={styles.head}>
        {activeIndexNav <= 1 ? (
          <div className={styles.group}>
            <button className={styles.interval} key="Time">
              Interval
            </button>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={timeframe}
              setValue={(x: string) => setDropdownTimestamp(x)}
              options={timeframes}
              classLabel={undefined}
              label={undefined}
              classDropdownArrow={undefined}
              classDropdownBody={undefined}
              classDropdownOption={undefined}
            />
            <div className={styles.nav}>
              <button className={styles.time} key="Time">
                Interval
              </button>
              {timeframes.map((x, index) => (
                <button
                  className={cn(styles.dates, {
                    [styles.active]: index === activeIndexDates,
                  })}
                  onClick={() => setActiveIndexDates(index)}
                  key={index}
                >
                  {x.toLowerCase()}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div> </div>
        )}

        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(
                styles.button,
                styles.navButton,
                "tabSelect tooltip",
                {
                  active: index === activeIndexNav,
                }
              )}
              onClick={() => setActiveIndexNav(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.inner}>
        <div className={styles.iframe} ref={container}>
          {renderActiveChart()}
        </div>
      </div>
    </div>
  );
};

export default Charts;
