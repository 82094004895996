/* eslint-disable no-console */
import styles from "../Account.module.sass";

const AccountItem = ({ title, negative, type, data = "" }) => {
  const itemStyle = type ? styles[type] : null;
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.currency}>{title}</div>
      </div>
      <div className={styles.col}>
        <div className={itemStyle}>
          {negative && `-`}
          {typeof data === "number" ? data.toFixed(2) : data}
        </div>
      </div>
    </div>
  );
};

export default AccountItem;
