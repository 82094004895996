export const accountItems = [
  {
    title: "Account Balance",
    key: "balance",
    direction: "balance_negative",
    type: "currency",
  },
  {
    title: "Buying Power",
    type: "currency",
    key: "buying_power",
    direction: "buying_power_negative",
  },
  {
    title: "Total Position Value",
    type: "currency",
    key: "total_position_value",
    direction: "total_position_value_negative",
  },
  {
    title: "Equity",
    type: "currency",
    key: "equity",
    direction: "equity_negative",
  },
  {
    title: "Unrealized PnL",
    type: "currency",
    key: "unrealized_pnl",
    direction: "unrealized_pnl_negative",
  },
  {
    title: "Leverage",
    type: "leverage",
  },
];
