import baseDenomTokenId from "./baseDenomTokenId";

const minimalDenom: { [key: string]: string } = {
  UST2: baseDenomTokenId,
  ATOM: "uatom",
  USDC: "uusdc",
  BTC: "ubtc",
  ETH: "ueth",
  SEI: "usei",
  SOL: "usol",
};

export default minimalDenom;
