const statuses: { [key: number | string]: number | string } = {
  PLACED: 0,
  FAILED_TO_PLACE: 1,
  CANCELLED: 2,
  FULFILLED: 3,
  UNRECOGNIZED: -1,
  0: "PLACED",
  1: "FAILED_TO_PLACE",
  2: "CANCELLED",
  3: "FULFILLED",
  "-1": "UNRECOGNIZED",
};

export default statuses;
