import { chainId, chainName } from "..";

export const supportedWallets = {
  keplr: {
    title: "Keplr",
    image: "/images/wallets/wallet-connect-keplr.png",
    chain: "cosmos",
    chainID: chainId,
    chainName,
  },
  leap: {
    title: "Leap",
    image: "/images/wallets/wallet-connect-leap.png",
    chain: "cosmos",
    chainID: chainId,
    chainName,
  },
  // metamask: {
  //   title: "MetaMask",
  //   image: "/images/wallets/wallet-connect-metamask.png",
  //   chain: "ethereum",
  //   chainID: "",
  //   chainName: "",
  // },
};
