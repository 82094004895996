import { userSettlementsState } from "./../../../../recoil/atoms/userSettlements";
import { fetchUserSettlementsState } from "./../../../../recoil/selectors/userSettlements";
import React, { useContext, useEffect, useMemo } from "react";
import cn from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { WalletContext } from "../../../../context";
import styles from "../Table.module.sass";
import { formatDateFromTimestamp, numberWithCommas } from "../../../../utils";
import { EmptySet } from "../../../../components/EmptySet";

type TradeHistoryProps = {
  tableContentClassName?: string;
};

const TradeHistory = ({ tableContentClassName }: TradeHistoryProps) => {
  const wallet = useContext(WalletContext).wallet || { cosmosAccounts: [] };

  const keplrWalletAddress = useMemo(
    () => wallet.cosmosAccounts[0],
    [wallet.cosmosAccounts]
  );

  const queryUserSettlementsInfo = useRecoilValue(
    fetchUserSettlementsState(keplrWalletAddress)
  );
  const [userSettlements, setUserSettlements] =
    useRecoilState(userSettlementsState);

  useEffect(() => {
    if (keplrWalletAddress) {
      queryUserSettlementsInfo().then(setUserSettlements);
    }
  }, [keplrWalletAddress]);

  const renderTableHeader = () => (
    <div className={cn(styles.headerRow, tableContentClassName)}>
      <div className={styles.col}>
        <div className="sorting">ID</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Trade History</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">
          <div className={styles.inlineRow}>
            Expected Price
            <button className={cn("currency", styles.button)}>UST2</button>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="sorting">
          <div className={styles.inlineRow}>
            Execution Price
            <button className={cn("currency", styles.button)}>UST2</button>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="sorting">
          <div className={styles.inlineRow}>Amount</div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Type</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Timestamp</div>
      </div>
      <div />
    </div>
  );

  const renderTableContent = () => {
    if (!keplrWalletAddress) return null;
    if (userSettlements.length === 0) {
      return <EmptySet small text="No Settled Orders" />;
    }

    return userSettlements.map((x, index) => {
      const order_details = x.order_id.split("-");
      const contractAddr = order_details[0];
      const priceDenom = order_details[1];
      const assetDenom = order_details[2];
      const order_id = order_details[3];

      return (
        <div className={styles.row} key={x.settlement_id}>
          <div className={styles.col}>{order_id}</div>

          <div className={styles.col}>
            <button
              className={cn(x.position_direction.toLowerCase(), styles.button)}
            >
              {x.position_direction}
            </button>
          </div>

          <div className={styles.col}>
            <div className={styles.marketPositionText}>
              {`${numberWithCommas(x.expected_price, "currency")}`}
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.marketPositionText}>
              {`${numberWithCommas(x.execution_price, "currency")}`}
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.inlineRow}>
              <div className={styles.marketPositionText}>
                {`${numberWithCommas(x.quantity, "quantity")}`}
              </div>
              <button className={cn("currency", styles.button)}>
                {assetDenom}
              </button>
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.marketPositionText}>{x.order_type}</div>
          </div>

          <div className={styles.col}>
            <div className={styles.faintLargerText}>
              {formatDateFromTimestamp(x.timestamp)}
            </div>
          </div>
          <div />
        </div>
      );
    });
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableScroll}>
        {renderTableHeader()}
        <div className={styles.content}>
          <>{renderTableContent()}</>
        </div>
      </div>
    </div>
  );
};

export default TradeHistory;
