import { useContext } from "react";
import { SeiSigningCosmWasmClient } from "@sei-js/core";
import { coins } from "@cosmjs/proto-signing";
import {
  baseDenom,
  baseDenomTokenId,
  contractAddr,
  conversions,
  tendermintURL,
} from "../constants";
import WalletContext from "../WalletContext";
import { unitsAdjustedAmount } from "../../utils/transaction";

const useFundAccount = () => {
  const { cosmosAccounts, cosmosSigner } = useContext(WalletContext).wallet || {
    cosmosSigner: null,
    cosmosAccounts: [],
  };
  const [firstAccount] = cosmosAccounts;

  const fundAccount = async (amount: number) => {
    if (!cosmosSigner) {
      return undefined;
    }

    const signingCosmWasmClient =
      await SeiSigningCosmWasmClient.connectWithSigner(
        tendermintURL,
        cosmosSigner
      );

    const fee = {
      amount: coins(15000, "usei"),
      gas: "300000",
    };

    const denom = baseDenom;
    const unitsConversion = conversions[denom];

    try {
      return await signingCosmWasmClient.execute(
        firstAccount,
        contractAddr,
        { deposit: {} },
        fee,
        "deposit ust2",
        coins(
          unitsAdjustedAmount(Number(amount), unitsConversion),
          baseDenomTokenId
        )
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { fundAccount };
};

export default useFundAccount;
