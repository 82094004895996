import {
  FundingRateType,
  FundingRateOnchainType,
  FundingRateEpochEntry,
} from "./../context/types/FundingRateType";

export const formatNumber = (
  amount: number | string | undefined,
  options?: {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    locale?: string;
  }
) => {
  if (amount === undefined) return "";
  let amountNumber = amount;
  if (typeof amountNumber === "string") amountNumber = parseFloat(amountNumber);
  return amountNumber.toLocaleString(options?.locale || "en-us", {
    maximumFractionDigits: options?.maximumFractionDigits,
    minimumFractionDigits: options?.minimumFractionDigits,
  });
};

export const formatCurrency = (
  amount: number | string | undefined,
  options?: {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    locale?: string;
    currency?: string;
    addPositiveSign: boolean;
  }
) => {
  if (!amount) return "---";
  let amountNumber = amount;
  if (typeof amountNumber === "string") amountNumber = parseFloat(amountNumber);
  let formatter = new Intl.NumberFormat(options?.locale || "en-US", {
    style: "currency",
    currency: options?.currency || "USD",
    minimumFractionDigits: options?.minimumFractionDigits || 2,
    maximumFractionDigits: options?.maximumFractionDigits || 2,
  });

  const formattedAmount = formatter.format(amountNumber);

  if (amountNumber > 0)
    return `${options?.addPositiveSign ? "+" : ""}${formattedAmount}`;
  return formattedAmount;
};

export const formatFundingRateToChart = (
  fundingHistoryData: FundingRateType
) => {
  const latestEpoch = fundingHistoryData.slice(-1)[0].epoch;
  const currentTime = new Date(Date.now());
  return fundingHistoryData.map((fundingHistory: FundingRateEpochEntry, i) => {
    const millisecondsToSubtract =
      (Number(latestEpoch) - Number(fundingHistory.epoch)) * 60 * 1000;
    const totalMilliseconds = currentTime.getTime();
    const newDate = new Date(totalMilliseconds - millisecondsToSubtract);
    const formattedTime = Date.parse(newDate.toUTCString()) / 1000;
    return {
      time: formattedTime,
      value: Number(fundingHistory.funding_rate).toFixed(4),
    };
  });
};

export const formatFundingRateOnchainToChart = (
  fundingHistoryData: FundingRateOnchainType
) => {
  return fundingHistoryData.epochs.map((fundingHistory: any, i) => {
    const price = fundingHistoryData.negatives[i]
      ? -fundingHistoryData.price_diffs[i]
      : fundingHistoryData.price_diffs[i];
    return {
      time: fundingHistoryData.epochs[i],
      value: (Number(price) / 3600).toFixed(4),
    };
  });
};
