import axios from "axios";
import { useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { orderBookState } from "../../recoil/atoms";
import { contractAddr } from "../constants";
import offchainURL from "../constants/offchainURL";
import { OrderbookType } from "../types";
import useUrl from "./useUrl";
import { WalletContext } from "../index";
import { orderBookRefreshState } from "../../recoil/atoms/orderbook";

const useFetchOrderBooks = () => {
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [orderBook, setOrderBook] = useRecoilState(orderBookState);

  const orderBookRefresh = useRecoilValue(orderBookRefreshState);

  let interval: NodeJS.Timer | undefined;

  const wallet = useContext(WalletContext).wallet || { cosmosAccounts: [] };

  const getOrderbook = async () => {
    let longBook: OrderbookType[] = [];
    let shortBook: OrderbookType[] = [];

    try {
      await axios
        .get(
          `${offchainURL}/longbook/${contractAddr}-${priceDenomString}-${assetDenomString}`
        )
        .then((results) => {
          longBook =
            results?.data?.sort(
              (a: any, b: any) => parseFloat(b.price) - parseFloat(a.price)
            ) || [];
        });

      await axios
        .get(
          `${offchainURL}/shortbook/${contractAddr}-${priceDenomString}-${assetDenomString}`
        )
        .then((results) => {
          shortBook =
            results?.data?.sort(
              (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
            ) || [];
        });
      return {
        shortBook,
        longBook,
        bestLongPrice: longBook.length > 0 ? Number(longBook[0]?.price) : 100,
        bestShortPrice:
          shortBook.length > 0 ? Number(shortBook[0]?.price) : 1.0,
        worstShortPrice:
          shortBook.length > 0 ? Number(shortBook.slice(-1)[0]?.price) : 100,
        worstLongPrice:
          longBook.length > 0 ? Number(longBook.slice(-1)[0]?.price) : 1.0,
      };
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // const getOrderbookOnchain = async () => {
  //   const [assetDenomString, priceDenomString] = pair.split("-");
  //   const client = await getQueryClient();
  //
  //   const shortBookResponse =
  //     await client.seiprotocol.seichain.dex.shortBookAll({
  //       contractAddr,
  //       priceDenom: priceDenomString,
  //       assetDenom: assetDenomString,
  //     });
  //   const longBookResponse = await client.seiprotocol.seichain.dex.longBookAll({
  //     contractAddr,
  //     priceDenom: priceDenomString,
  //     assetDenom: assetDenomString,
  //   });
  //
  //   const sortedShortbook = shortBookResponse.ShortBook.sort(
  //     (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
  //   );
  //   const sortedLongbook = longBookResponse.LongBook.sort(
  //     (a: any, b: any) => parseFloat(b.price) - parseFloat(a.price)
  //   );
  //   return {
  //     shortBook: sortedShortbook,
  //     longBook: sortedLongbook,
  //     bestLongPrice: sortedLongbook.length > 0 ? sortedLongbook[0]?.price : 100,
  //     bestShortPrice:
  //       sortedShortbook.length > 0 ? sortedShortbook[0]?.price : 1.0,
  //     worstShortPrice:
  //       sortedShortbook.length > 0 ? sortedShortbook.slice(-1)[0].price : 100,
  //     worstLongPrice:
  //       sortedLongbook.length > 0 ? sortedLongbook.slice(-1)[0].price : 1.0,
  //   };
  // };

  useEffect(() => {
    if (interval) clearInterval(interval);

    getOrderbook().then((orderBookEntry) => {
      setOrderBook({ ...orderBook, [pair]: orderBookEntry });
    });

    interval = setInterval(() => {
      if (!pair) return;

      getOrderbook().then((orderBookEntry) => {
        setOrderBook({ ...orderBook, [pair]: orderBookEntry });
      });
    }, 60000);
    return () => clearInterval(interval);
  }, [pair, orderBookRefresh]);
};

export default useFetchOrderBooks;
