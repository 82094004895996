import { markets } from "../../context/constants/tokenPairs";
import { selectorFamily } from "recoil";
import { useQueryContract } from "../../context";

export const fetchOpenPositionsState = selectorFamily({
  key: "fetchOpenPositionsState",
  get:
    (walletAddress: string) =>
    ({}) => {
      const { getPosition } = useQueryContract();

      const fetchMarketPosition = async () => {
        if (walletAddress) {
          const positions: any = {};
          await Promise.all(
            Object.keys(markets).map(async (key) => {
              positions[key] = await getPosition(
                walletAddress,
                key.split("-")[1],
                key.split("-")[0]
              );
            })
          );
          return positions;
        }
      };
      return () => fetchMarketPosition();
    },
});
