import { selectorFamily } from "recoil";
import { orderBookState } from "../atoms";

export const pairOrderBookState = selectorFamily({
  key: "PairOrderBookState",
  get:
    (pair: string) =>
    ({ get }) => {
      return get(orderBookState)[pair];
    },
});
