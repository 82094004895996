import { useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import styles from "../../Table.module.sass";
import { numberWithCommas, openInNewTab } from "../../../../../utils";
import Modal from "../../../../../components/Modal";
import { markets } from "../../../../../context/constants/tokenPairs.ts";
import ClosePositionModal from "../../../../../components/ClosePositionModal";

const PositionRow = (props) => {
  const { direction, marketPositions, assetDenomString, priceDenomString } =
    props;
  const [showClosePositionModal, setShowClosePositionModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const onClickClosePosition = async (data) => {
    setShowClosePositionModal(true);
  };

  const notifyOnSubmit = async (tx) => {
    if (tx !== undefined && tx.transactionHash !== null) {
      const txExploreURL = `https://testnet-explorer.brocha.in/sei%20atlantic%202/tx/${tx.transactionHash}`;
      toast(
        <div className={styles.normalText}>
          🌊 View the status in the Orders tab
          <div
            className={styles.exploreLink}
            onClick={() => openInNewTab(txExploreURL)}
          >
            <span>View on Explorer</span>
          </div>
        </div>
      );
    } else {
      toast(<div>🚫 Order was not placed</div>);
    }
  };

  return (
    marketPositions &&
    marketPositions.long_position &&
    marketPositions.short_position && (
      <div
        className={styles.row}
        key={priceDenomString + assetDenomString + direction}
      >
        <div className={styles.col} style={{ minWidth: 300, maxWidth: 300 }}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img
                alt="Avatar"
                src={`/images/tokens/${assetDenomString.toUpperCase()}.png`}
              />
            </div>
            <div className={styles.details}>
              <span className={styles.subtitle}>
                {markets[`${assetDenomString}-${priceDenomString}`].name}
              </span>
              <span className={styles.currency}>{assetDenomString}</span>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <button
            className={cn(direction === 0 ? "long" : "short", styles.button)}
          >
            {direction === 0 ? "Long" : "Short"}
          </button>
        </div>
        <div className={styles.col}>
          <div className={styles.inlineRow}>
            {direction === 0 ? (
              <div className={styles.marketPositionText}>
                {marketPositions.long_position.negative ? "-" : ""}
                {numberWithCommas(marketPositions.long_position.decimal, null)}
              </div>
            ) : (
              <div className={styles.marketPositionText}>
                {marketPositions.short_position.negative ? "-" : ""}
                {numberWithCommas(marketPositions.short_position.decimal, null)}
              </div>
            )}
            <button className={cn("currency", styles.button)}>
              {assetDenomString}
            </button>
          </div>
        </div>
        {/* <div className={styles.col}> */}
        {/*   <div className={styles.inlineRow}> */}
        {/*     {numberWithCommas(averageOpen)} */}
        {/*   </div> */}
        {/* </div> */}
        <div className={styles.col}>
          <div className={styles.inlineRow}>
            {direction === 0 ? (
              <div className={styles.marketPositionText}>
                {marketPositions.long_position_margin_debt.negative ? "-" : ""}
                {numberWithCommas(
                  marketPositions.long_position_margin_debt.decimal
                )}
              </div>
            ) : (
              <div className={styles.marketPositionText}>
                {marketPositions.short_position_margin_debt.negative ? "-" : ""}
                {numberWithCommas(
                  marketPositions.short_position_margin_debt.decimal
                )}
              </div>
            )}
            <button className={cn("currency", styles.button)}>
              {priceDenomString}
            </button>
          </div>
        </div>
        <div className={styles.col}>
          {direction === 0 ? (
            <div className={styles.marketPositionText}>
              {marketPositions.long_position_last_funding_payment_epoch}
            </div>
          ) : (
            <div className={styles.marketPositionText}>
              {marketPositions.short_position_last_funding_payment_epoch}
            </div>
          )}
        </div>

        {((direction === 0 && !marketPositions.long_position.negative) ||
          (direction === 1 && !marketPositions.short_position.negative)) && (
          <div className={styles.col}>
            <button
              className={styles.callToAction}
              onClick={() => onClickClosePosition(direction, marketPositions)}
            >
              Close Position
            </button>
          </div>
        )}

        <Modal
          classname={styles.modal}
          visible={showClosePositionModal}
          onClose={() => setShowClosePositionModal(false)}
        >
          <ClosePositionModal
            notifyOnSubmit={notifyOnSubmit}
            assetDenomString={assetDenomString}
            priceDenomString={priceDenomString}
            onTradeCloseModal={() => setShowClosePositionModal(false)}
            marketPosition={marketPositions}
            orderDirection={direction}
          />
        </Modal>
      </div>
    )
  );
};

export default PositionRow;
