import React, { useState, useEffect, useContext, useMemo } from "react";
import cn from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { WalletContext } from "../../../context";
import WalletConnectSection from "../../../components/WalletConnectSection";
import Modal from "../../../components/Modal";
import DepositModal from "../../../components/DepositModal";
import Withdraw from "../../../components/WithdrawModal";
import { accountState } from "../../../recoil/atoms";
import { fetchAccountInfo } from "../../../recoil/selectors";
import { numberWithCommas } from "../../../utils";
import styles from "./Account.module.sass";
import { AccountItem } from "./components";
import { accountItems } from "./config";

const Account = () => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleDeposit, setVisibleDeposit] = useState(false);

  const wallet = useContext(WalletContext).wallet || { cosmosAccounts: [] };

  const keplrWalletAddress = useMemo(
    () => wallet.cosmosAccounts[0],
    [wallet.cosmosAccounts]
  );

  const queryAccountInfo = useRecoilValue(fetchAccountInfo(keplrWalletAddress));

  useEffect(() => {
    if (keplrWalletAddress) {
      queryAccountInfo().then(setAccountInfo);
    }
  }, [keplrWalletAddress]);

  return (
    <div className={styles.account}>
      {!keplrWalletAddress && <WalletConnectSection maxWidth="100px" />}
      {!!keplrWalletAddress && accountInfo !== {} && (
        <>
          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>Account</div>
            <div className={styles.rowBtns}>
              <button
                className={cn("button-primary", styles.button)}
                onClick={() => setVisibleDeposit(true)}
              >
                Deposit
              </button>
              <button
                className={cn(
                  "button-secondary",
                  styles.button,
                  styles.secondary
                )}
                onClick={() => setVisibleWithdraw(true)}
              >
                Withdraw
              </button>
            </div>
          </div>
          <div className={styles.table}>
            {!!accountItems &&
              accountItems.length > 0 &&
              accountItems.map((item, i) => {
                if (item.title === "Leverage" && accountInfo.leverage) {
                  return (
                    <AccountItem
                      title={item.title}
                      type={item.type}
                      data={numberWithCommas(
                        Number(accountInfo.leverage.decimal),
                        false
                      )}
                      direction={accountInfo.leverage.negative}
                      key={i}
                    />
                  );
                }

                const getData = () => {
                  const accountInfoItem = accountInfo[item.key];
                  if (!accountInfoItem) return "";

                  const accountItemBalance = Number(accountInfoItem.decimal);

                  const balanceString = numberWithCommas(
                    accountItemBalance,
                    item.type
                  );

                  const prefix = accountInfoItem.negative ? "-" : "";

                  return `${prefix}${balanceString} USD`;
                };

                return (
                  <AccountItem
                    title={item.title}
                    type={item.type}
                    data={getData()}
                    direction={
                      accountInfo[item.key]
                        ? accountInfo[item.key].negative
                        : false
                    }
                    key={i}
                  />
                );
              })}
          </div>
          <Modal
            visible={visibleDeposit}
            onClose={() => setVisibleDeposit(false)}
          >
            <DepositModal onClose={() => setVisibleDeposit(false)} />
          </Modal>
          <Modal
            visible={visibleWithdraw}
            onClose={() => setVisibleWithdraw(false)}
          >
            <Withdraw onClose={() => setVisibleWithdraw(false)} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default Account;
