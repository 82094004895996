/* eslint-disable no-console */
import { queryClient } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module";
import { contractAddr, lcdURL } from "../constants";

const useTrades = () => {
  const parseFetchedTrades = (settlements) => {
    const parsedSettlements = [];

    for (let i = 0; i < settlements.length; i += 1) {
      const settlementEntry = settlements[i];
      const timestamp = new Date().toLocaleTimeString();

      parsedSettlements.push({
        price: parseFloat(settlementEntry.execution_price).toFixed(
          3
        ),
        quantity: parseFloat(settlementEntry.quantity).toFixed(3),
        timestamp,
        positiondirection: settlementEntry.position_direction,
      });

      if (parsedSettlements.length >= 26) {
        return parsedSettlements;
      }
    }
    return parsedSettlements;
  };

  const getSettlementsAll = async (priceDenom, assetDenom) => {
    try {
      const client = await queryClient({ addr: lcdURL });

      const settlements = await client.queryGetAllSettlements(
        contractAddr,
        priceDenom,
        assetDenom
      );

      return settlements.data.SettlementsList;
    } catch {
      return [];
    }
  };

  return { parseFetchedTrades, getSettlementsAll };
};

export default useTrades;
