/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import cn from "classnames";
import { useRecoilState } from "recoil";
import { WalletContext } from "../../../../../context";
import { accountState } from "../../../../../recoil/atoms";
import styles from "./ConfirmTransaction.module.sass";
import { ConfirmTransactionProps } from "./types";
import { formatNumber } from "../../../../../utils/formatting";

const ConfirmTransaction = ({
  onClick,
  classButton,
  buttonText,
  summary,
  isButtonLoading,
}: ConfirmTransactionProps) => {
  const pauseButton = () => {
    const submitButton: any = document?.getElementById("submitButton");
    if (submitButton) {
      submitButton["disabled"] = true;
    }
    setTimeout(() => {
      submitButton["disabled"] = false;
    }, 4000);
  };

  const [accountInfo] = useRecoilState(accountState);

  const { wallet } = useContext(WalletContext);
  const [error, setError] = useState("");
  useEffect(() => {
    let isMounted = true;
    let tempError = "";
    if (wallet?.cosmosAccounts?.length === 0) {
      tempError = "Wallet Not Connected";
    } else if (
      Number.isNaN(Number(summary[0].amount)) ||
      Number.isNaN(Number(summary[1].amount))
    ) {
      tempError = "Order Can't Be Empty";
    } else if (
      Number(summary[0].amount) <= 0 ||
      Number(summary[1].amount) <= 0
    ) {
      tempError = "Please enter a positive amount";
    } else if (
      accountInfo?.buying_power?.negative ||
      (summary[4] &&
        Number(accountInfo?.buying_power?.decimal) <= summary[4].amount)
    ) {
      tempError = "Insufficient funds. Deposit into Vortex.";
    }

    if (isMounted) setError(tempError);
    return () => {
      isMounted = false;
    };
  }, [summary[1], wallet]);

  const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 4 };

  return (
    <div className={styles.box}>
      <div className={styles.table}>
        {summary.map((x, index) => (
          <div className={styles.tableRow} key={index}>
            <div className={styles.col}>
              <div className={styles.tip}>{x.title}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.value}>{`${formatNumber(
                x.amount,
                formatOptions
              )} ${x.denom}`}</div>
            </div>
          </div>
        ))}
      </div>
      <button
        id="submitButton"
        disabled={Boolean(error)}
        onClick={() => {
          onClick();
          pauseButton();
        }}
        className={cn(classButton, styles.button)}
      >
        {error || (isButtonLoading ? "Awaiting signing..." : buttonText)}
      </button>
    </div>
  );
};

export default ConfirmTransaction;