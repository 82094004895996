import React, { useRef, useEffect, useState } from "react";
import { createChart, IChartApi, ISeriesApi } from "lightweight-charts";
import { formatFundingRateToChart } from "../../../utils/formatting";
import {
  tradingViewCandlestickOptions,
  tradingViewCandlestickColorOptions,
  tradingViewLineColorOptions,
} from "./tradingViewLiteConfigs";

import styles from "./TradingViewLite.module.sass";
import { TradingViewLiteProps } from "./types";
import { EmptySet } from "../../../components/EmptySet";
import ReactLoading from "react-loading";

const TradingViewLite = ({
  mode,
  candlesticksData,
  fundingHistoryData,
  height,
  width,
}: TradingViewLiteProps) => {
  const [chart, setChart] = useState<IChartApi | null>(null);
  const [candleSeries, setCandleSeries] =
    useState<ISeriesApi<"Candlestick"> | null>(null);
  const [areaSeries, setAreaSeries] = useState<ISeriesApi<any> | null>(null);
  const chartContainerRef = useRef();

  useEffect(() => {
    if (!chartContainerRef?.current) return;
    const c = createChart(
      chartContainerRef.current,
      tradingViewCandlestickOptions(width, height)
    );

    if (mode === "candlesticks") {
      const candleSeries = c.addCandlestickSeries(
        tradingViewCandlestickColorOptions
      );
      setCandleSeries(candleSeries);
      if (candlesticksData) candleSeries.setData(candlesticksData);
      c.timeScale().fitContent();
      setChart(c);
    }

    if (mode === "line") {
      const areaSeries = c.addLineSeries(tradingViewLineColorOptions);
      setAreaSeries(areaSeries);
      const lineFormatData = candlesticksData?.map((candlestick) => ({
        time: candlestick.time,
        value: candlestick.close,
      }));
      if (lineFormatData) {
        areaSeries.setData(lineFormatData);
      }
      c.timeScale().fitContent();
      setChart(c);
    }

    if (mode === "funding") {
      const areaSeries = c.addLineSeries(tradingViewLineColorOptions);
      const lineFormatData = formatFundingRateToChart(fundingHistoryData);

      if (lineFormatData !== null) {
        areaSeries.setData(lineFormatData as any);
      }
      c.timeScale().fitContent();
      setChart(c);
    }
  }, [chartContainerRef, mode, candlesticksData, fundingHistoryData]);

  useEffect(() => {
    if (candlesticksData && candleSeries !== null) {
      candleSeries.setData(candlesticksData);

      const lineFormatData = candlesticksData.map((candlestick) => ({
        time: candlestick.time,
        value: candlestick.close,
      }));
      if (lineFormatData !== null && areaSeries !== null) {
        areaSeries.setData(lineFormatData);
      }
    }
  }, [chartContainerRef, candlesticksData]);

  useEffect(() => {
    if (chart) {
      chart.resize(width - 24, height);
    }
  }, [height, width]);

  if (candlesticksData === undefined && mode === "candlesticks") {
    return (
      <div className={styles.loading}>
        <ReactLoading type="spin" color="#223E50" height={50} width={50} />
      </div>
    );
  }

  if (candlesticksData?.length === 0)
    return <EmptySet text={`No ${mode} data`} />;

  return (
    <div className={styles.trades}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div ref={chartContainerRef as any} />
        </div>
      </div>
    </div>
  );
};

export default TradingViewLite;
