import cn from "classnames";
import Wallet from "../../components/Wallet";
import TradeHistory from "../Exchange/Table/TradeHistory";
import styles from "./History.module.sass";

const History = () => (
  <Wallet>
    <div className={styles.top}>
      <h4 className={cn("h4", styles.text, styles.header)}>Trade History</h4>
      <div className={styles.body}>
        <TradeHistory tableContentClassName={styles.table} />
      </div>
    </div>
  </Wallet>
);

export default History;
