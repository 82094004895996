import baseDenomTokenId from "./baseDenomTokenId";

export const seiChainDepositOptions = [
  {
    name: "SEI Network",
    axelarName: "sei",
    symbol: "SEI",
    supportedWallets: ["keplr", "leap"],
    tokens: [
      { name: "USD Coin", symbol: "UST2", assetDenom: baseDenomTokenId },
      // { name: "USD Coin", symbol: "aUSDC", assetDenom: "ibc/6D45A5CD1AADE4B527E459025AC1A5AEF41AE99091EF3069F3FEAACAFCECCD21" },
    ],
  },
];
