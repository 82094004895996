import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { ToastContainer } from "react-toastify";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./Page.module.sass";

const Page = ({ headerHide, children, footerHide, headerWide }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <div className={styles.page}>
      {!headerHide && <Header headerWide={headerWide} />}
      <div className={styles.inner}>
        {children}
        {!footerHide && <Footer />}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default withRouter(Page);
