import React, { useState } from "react";
import cn from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Trades from "../Trades";
import Orderbook from "../Orderbook";
import Dropdown from "../../../components/Dropdown";
import { getNumberOfDecimals } from "../../../utils";
import { pairOrderBookState } from "../../../recoil/selectors";
import { useUrl } from "../../../context";
import styles from "./Balance.module.sass";

const navigation = ["Orderbook", "Trades"];

const roundingOptions = ["1", "0.1", "0.01", "0.001"];

const Balance = () => {
  const [activeIndexNav, setActiveIndexNav] = useState(0);
  const [decimalRounding, setDecimalRounding] = useState(roundingOptions[2]);

  const { pair } = useUrl();

  const pairOrderBook = useRecoilValue(pairOrderBookState(pair));
  const setOrderbookInfo = useSetRecoilState(pairOrderBookState(pair));

  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, "tabSelect tooltip", {
                active: index === activeIndexNav,
              })}
              onClick={() => setActiveIndexNav(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        <div name="roundingDropdown">
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            classDropdownArrow={styles.dropdownArrow}
            classDropdownBody={styles.dropdownBody}
            classDropdownOption={styles.dropdownOption}
            value={decimalRounding}
            setValue={setDecimalRounding}
            onClick={() => pairOrderBook().then(setOrderbookInfo)}
            options={roundingOptions}
          />
        </div>
      </div>
      {activeIndexNav === 0 && (
        <Orderbook decimalRounding={getNumberOfDecimals(decimalRounding)} />
      )}
      {activeIndexNav === 1 && <Trades />}
    </div>
  );
};

export default Balance;
