import { suggestChain } from "@sei-js/core";
import { chainName, chainId, lcdURL, tendermintURL } from "../../../context";

export const connectKeplr = async (
  chainIdInput = chainId,
  chainNameInput = chainName,
  rpcUrl = tendermintURL,
  restUrl = lcdURL
) => {
  if (!window.keplr) {
    alert("Keplr Wallet not detected, please install extension");
    return {
      accounts: null,
    };
  }
  if (!window.keplr.experimentalSuggestChain) {
    alert(
      "Please use latest version of the Keplr extension to access experimental features"
    );
  }

  try {
    await suggestChain("keplr", {
      chainId: chainIdInput,
      rpcUrl,
      restUrl,
      chainName: chainNameInput,
    });
  } catch {
    alert("Failed to suggest the chain");
  }

  // You should request Keplr to enable the wallet.
  // This method will ask the user whether or not to allow access if they haven't visited this website.
  // Also, it will request user to unlock the wallet if the wallet is locked.
  // If you don't request enabling before usage, there is no guarantee that other methods will work.
  await window.keplr.enable(chainId);

  const sendingSigner = await window.keplr.getOfflineSigner(chainId);
  if (!sendingSigner)
    throw new Error(`Failed to get sendingSigner for ${chainId}`);

  // // You can get the address/public keys by `getAccounts` method.
  // // It can return the array of address/public key.
  // // But, currently, Keplr extension manages only one address/public key pair.
  // // XXX: This line is needed to set the sender address for SigningCosmosClient.
  const accounts = await sendingSigner.getAccounts();

  return { accounts, signer: sendingSigner };
};
