import { useEffect } from "react";
/* eslint-disable no-console */
import { contractAddr } from "../constants";
import useClient from "./useClient";
import { MarketSummaryType, MarketSummaryOnchainType } from "../types";
import axios from "axios";
import offchainURL from "../constants/offchainURL";
import useUrl from "./useUrl";
import { useRecoilState } from "recoil";
import { marketSummaryState } from "../../recoil/atoms";

const useMarketSummary = () => {
  const { getQueryClient } = useClient();
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [marketSummary, setMarketSummary] = useRecoilState(marketSummaryState);
  let interval: NodeJS.Timer | undefined;

  const getMarketSummary = async () => {
    let result: MarketSummaryType[] = [];

    try {
      await axios
        .get(
          `${offchainURL}/marketSummary24hrs/${contractAddr}-${priceDenomString}-${assetDenomString}`
        )
        .then((results) => {
          result = results.data;
        });
      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getMarketSummaryOnchain = async () => {
    const client = await getQueryClient();
    const lookbackInSeconds = 60 * 60 * 24;
    let result: MarketSummaryOnchainType;

    try {
      result = await client.seiprotocol.seichain.dex.getMarketSummary({
        contractAddr,
        priceDenom: priceDenomString,
        assetDenom: assetDenomString,
        lookbackInSeconds: lookbackInSeconds as any,
      });
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    if (interval) clearInterval(interval);

    getMarketSummary().then((pairMarketSummaryState) => {
      setMarketSummary({ ...marketSummary, [pair]: pairMarketSummaryState });
    });

    interval = setInterval(() => {
      if (!pair) return;

      getMarketSummary().then((pairMarketSummaryState) => {
        setMarketSummary({ ...marketSummary, [pair]: pairMarketSummaryState });
      });
    }, 60000);
    return () => clearInterval(interval);
  }, [assetDenomString, priceDenomString, pair]);
};

export default useMarketSummary;
