import { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import TextInput from "../TextInput";
import { useFundAccount, WalletContext } from "../../context";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchAccountBalanceState,
  fetchAccountInfo,
} from "../../recoil/selectors";
import { openInNewTab, truncateAddress } from "../../utils";
import { seiChainDepositOptions } from "../../context/constants/deposit";
import DropdownTokens from "../DropdownTokens";
import { accountBalanceState, accountState } from "../../recoil/atoms";
import styles from "./DepositModal.module.sass";
import { DepositModalProps } from "./types";

const DepositModal = ({ onClose }: DepositModalProps) => {
  const { fundAccount } = useFundAccount();
  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);
  const [fromChain] = useState(seiChainDepositOptions[0]);
  const [fromToken, setFromToken] = useState(
    seiChainDepositOptions[0].tokens[0]
  );

  const walletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];

  const queryAccountInfo = useRecoilValue(fetchAccountInfo(walletAddress));
  const setAccountBalanceState = useSetRecoilState(accountBalanceState);
  const queryAccountBalanceState = useRecoilValue(
    fetchAccountBalanceState(walletAddress)
  );
  const setAccountInfo = useSetRecoilState(accountState);

  useEffect(() => {
    if (!amount) {
      setError("Amount is required");
    } else if (!Number(amount)) {
      setError("Amount is invalid");
    } else if (Number(amount) <= 0) {
      setError("Amount must be positive number");
    } else {
      setError("");
    }
  }, [amount]);

  const notifyOnSubmit = async (tx: any) => {
    if (tx !== undefined && tx.transactionHash !== null) {
      const txExploreURL = `https://testnet-explorer.brocha.in/sei%20atlantic%202/tx/${tx.transactionHash}`;
      toast(
        <div className={styles.normalText}>
          🌊 ${amount} UST2 deposit submitted into Vortex.
          <div
            className={styles.exploreLink}
            onClick={() => openInNewTab(txExploreURL)}
          >
            <span>View on Explorer</span>
          </div>
        </div>
      );
    } else {
      toast(<div>🚫 An error in Deposit occurred.</div>);
    }
  };

  const handleSubmit = async () => {
    if (!amount) {
      return;
    }
    const numberAmount = Number(amount);
    if (!numberAmount) {
      return;
    }
    setSubmitting(true);
    await fundAccount(numberAmount)
      .then((tx) => {
        if (tx !== undefined && tx.transactionHash !== undefined)
          notifyOnSubmit(tx);
        onClose();
        queryAccountInfo().then(setAccountInfo);
        queryAccountBalanceState().then(setAccountBalanceState);
        setSubmitting(false);
      })
      .catch((e) => {
        toast.error(e.message);
        setSubmitting(false);
      });
  };

  const buttonText = error || (submitting ? "Submitting..." : "Deposit");

  return (
    <div className={styles.deposit}>
      <h1 className={cn("h4", styles.title)}>Deposit</h1>
      <div className={styles.description}>
        Fund your Vortex account using your connected Sei Chain wallet address.
      </div>
      <div className={styles.row}>
        {/* <DropdownTokens */}
        {/*   className={styles.dropdown} */}
        {/*   value={fromChain} */}
        {/*   setValue={setFromChain} */}
        {/*   options={seiChainDepositOptions} */}
        {/* /> */}
        <DropdownTokens
          type="narrow"
          className={styles.dropdown}
          value={fromToken}
          setValue={setFromToken}
          options={fromChain.tokens}
        />
      </div>
      <TextInput
        className={styles.field}
        label="Amount"
        name="Amount"
        placeholder="0"
        type="text"
        required
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <div className={styles.bottom}>
        <div className={styles.summary}>
          <p className={styles.label}>Fee</p>
          <p className={styles.value}>
            0.005 <span className={styles.label}>SEI</span>
          </p>
        </div>
        {walletAddress && (
          <div className={styles.summary}>
            <p className={styles.label}>Deposit Address</p>
            <p className={styles.value}>{truncateAddress(walletAddress)}</p>
          </div>
        )}
        {/* <div className={styles.summary}> */}
        {/*   <p className={styles.label}>Total Deposit</p> */}
        {/*   <p className={styles.value}> */}
        {/*     {amount - 0.005} <span className={styles.label}>SEI</span> */}
        {/*   </p> */}
        {/* </div> */}
        <button
          id="submitButton"
          disabled={Boolean(error || submitting)}
          onClick={handleSubmit}
          className={cn("button", styles.button)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default DepositModal;
