import { selectorFamily } from "recoil";
import { fundingRateState } from "../atoms";

export const pairFundingRateState = selectorFamily({
  key: "PairFundingRateState",
  get:
    (pair: string) =>
    ({ get }) => {
      return get(fundingRateState)[pair];
    },
});
