import React, { useMemo, useState } from "react";
import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../Icon";
import Modal from "../Modal";
import DepositModal from "../DepositModal";
import WithdrawModal from "../WithdrawModal";
import styles from "./Wallet.module.sass";
import { WalletProps } from "./types";
import { WALLET_NAV_ITEMS } from "./config";

const Wallet = ({ className, children }: WalletProps) => {
  const { pathname } = useLocation();
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState<boolean>(false);
  const [visibleDeposit, setVisibleDeposit] = useState<boolean>(false);

  const activeItem = useMemo(
    () =>
      WALLET_NAV_ITEMS.find((x) => pathname.includes(x.url)) ||
      WALLET_NAV_ITEMS[0],
    [WALLET_NAV_ITEMS, pathname]
  );

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
            <div
              className={styles.top}
              onClick={() => setVisibleMenu(!visibleMenu)}
            >
              <div
                className={styles.bg}
                style={{ backgroundColor: activeItem.color }}
              />
              {activeItem.title}
            </div>
            <div className={styles.menu}>
              {WALLET_NAV_ITEMS.map(
                (item, index) =>
                  item.url && (
                    <NavLink
                      className={cn(styles.item, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      to={item.url}
                      key={index}
                    >
                      {item.color && (
                        <div
                          className={styles.bg}
                          style={{ backgroundColor: item.color }}
                        />
                      )}
                      {item.icon && <Icon name={item.icon} size="20" />}
                      {item.title}
                    </NavLink>
                  )
              )}
            </div>
          </div>

          <div className={styles.btns}>
            <button
              className={cn("button-primary button-small", styles.button)}
              onClick={() => setVisibleDeposit(true)}
            >
              Deposit
            </button>
            <button
              className={cn("button-secondary button-small", styles.button)}
              onClick={() => setVisibleWithdraw(true)}
            >
              Withdraw
            </button>
          </div>
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal visible={visibleDeposit} onClose={() => setVisibleDeposit(false)}>
        <DepositModal onClose={() => setVisibleDeposit(false)} />
      </Modal>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <WithdrawModal onClose={() => setVisibleWithdraw(false)} />
      </Modal>
    </>
  );
};

export default Wallet;
