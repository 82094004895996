import { FundingRateType } from "./../types/FundingRateType";
import { useEffect } from "react";
import { fundingRateState } from "./../../recoil/atoms/fundingRate";
import { useRecoilState } from "recoil";
/* eslint-disable no-console */
import axios from "axios";
import offchainURL from "../constants/offchainURL";
import { contractAddr } from "../constants";
import useUrl from "./useUrl";

// Every epoch is ~1 minute
const useFundingRate = () => {
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [fundingRate, setFundingRate] = useRecoilState(fundingRateState);

  let interval: NodeJS.Timer | undefined;

  const getFundingPaymentRate = async () => {
    let result: FundingRateType[] = [];

    try {
      await axios
        .get(
          `${offchainURL}/fundingRate/${contractAddr}-${priceDenomString}-${assetDenomString}`
        )
        .then((results) => {
          result = results.data;
        });
      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    if (interval) clearInterval(interval);

    getFundingPaymentRate().then((pairFundingRate) => {
      setFundingRate({ ...fundingRate, [pair]: pairFundingRate });
    });

    interval = setInterval(() => {
      if (!pair) return;

      getFundingPaymentRate().then((pairFundingRate) => {
        setFundingRate({ ...fundingRate, [pair]: pairFundingRate });
      });
    }, 60000);
    return () => clearInterval(interval);
  }, [assetDenomString, priceDenomString, pair]);
};

export default useFundingRate;
