import { useEffect, useState } from "react";
import { connectKeplr } from "../../components/WalletConnectModal/WalletUtils/Keplr";
import { connectLeap } from "../../components/WalletConnectModal/WalletUtils/Leap";

type UseAutoWalletConnectProps = {
  wallet: any;
  setWallet: (value: any) => void;
};

const useAutoWalletConnect = ({
  wallet,
  setWallet,
}: UseAutoWalletConnectProps) => {
  const storedValue = localStorage.getItem("WALLET_AUTO_CONNECT");
  const defaultState = storedValue ? JSON.parse(storedValue) : {};
  const [autoConnect, setAutoConnect] = useState(defaultState);

  const connectToCosmosWallet = async () => {
    if (autoConnect.leap) {
      try {
        const { accounts, signer } = await connectLeap();
        const getWallet = () => {
          const updateWallet = { ...wallet };
          updateWallet.cosmosAccounts = accounts?.map(
            (account: any) => account.address
          );
          updateWallet.cosmosSigner = signer;
          return updateWallet;
        };
        setWallet(getWallet());
      } catch {
        console.log("Keplr auto-connect failed");
      }
    }
    if (autoConnect.keplr) {
      try {
        const { accounts, signer } = await connectKeplr();
        const getWallet = () => {
          const updateWallet = { ...wallet };
          updateWallet.cosmosAccounts = accounts?.map(
            (account: any) => account.address
          );
          updateWallet.cosmosSigner = signer;
          return updateWallet;
        };
        setWallet(getWallet());
      } catch {
        console.log("Keplr auto-connect failed");
      }
    }
  };

  //todo: add other wallet providers here
  //todo: find a better way to make sure window is not undefined (wallet script injection has happened)
  const autoConnectToWallets = async () =>
    setTimeout(async () => connectToCosmosWallet(), 50);

  useEffect(
    () =>
      localStorage.setItem("WALLET_AUTO_CONNECT", JSON.stringify(autoConnect)),
    [autoConnect]
  );

  useEffect(() => {
    if (autoConnect) autoConnectToWallets().then();
  }, [autoConnect]);

  return { autoConnect, setAutoConnect };
};

export default useAutoWalletConnect;
