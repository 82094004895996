/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";
import {
  denoms,
  baseDenom,
  orderType,
  useQueryContract,
  WalletContext,
  useUrl,
} from "../../../../../context";
import {
  getOrderPricing,
  getOrderSummary,
} from "../../../../../utils/transaction";
import usePlaceTrade from "../../../../../context/hooks/usePlaceTrade";
import ConfirmTransaction from "../ConfirmTransaction";
import LeverageBar from "../LeverageBar";
import {
  accountState,
  openOrdersState,
  placeTradeState,
} from "../../../../../recoil/atoms";
import {
  fetchAccountBalanceState,
  fetchAccountInfo,
  fetchOpenOrdersState,
  pairOrderBookState,
} from "../../../../../recoil/selectors";
import { accountBalanceState } from "../../../../../recoil/atoms/balance";
import { fetchOpenPositionsState } from "../../../../../recoil/selectors/openPositions";
import { openPositionsState } from "../../../../../recoil/atoms/openPositions";
import styles from "./Action.module.sass";
import { ActionProps } from "./types";
import { fetchUserSettlementsState } from "../../../../../recoil/selectors/userSettlements";
import { userSettlementsState } from "../../../../../recoil/atoms/userSettlements";
import { formatNumber } from "../../../../../utils/formatting";
import Image from "../../../../../components/Image";

const Action = ({
  notifyOnSubmit,
  priceDenomString,
  assetDenomString,
  classButton,
  buttonText,
}: ActionProps) => {
  const walletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];
  const [placeTrade, setPlaceTrade] = useRecoilState(placeTradeState);

  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const queryAccountInfo = useRecoilValue(fetchAccountInfo(walletAddress));
  const queryOpenOrdersInfo = useRecoilValue(
    fetchOpenOrdersState(walletAddress)
  );
  const setOpenOrders = useSetRecoilState(openOrdersState);

  const queryUserSettlementsInfo = useRecoilValue(
    fetchUserSettlementsState(walletAddress)
  );
  const setUserSettlements = useSetRecoilState(userSettlementsState);

  const queryOpenPositionsInfo = useRecoilValue(
    fetchOpenPositionsState(walletAddress)
  );
  const setOpenPositions = useSetRecoilState(openPositionsState);

  const setBalanceState = useSetRecoilState(accountBalanceState);
  const queryAccountBalanceState = useRecoilValue(
    fetchAccountBalanceState(walletAddress)
  );

  const { getOrderFeeEstimate } = useQueryContract();
  const [orderFeeEstimate, setOrderFeeEstimate] = useState(0.0);
  const [isOpeningOrder, setIsOpeningOrder] = useState<boolean>(false);

  const [orderAmount, setOrderAmount] = useState<string>("0.0");
  const [orderAmountPriceDenom, setOrderAmountPriceDenom] = useState(0.0);
  const [showPriceDenom, setShowPriceDenom] = useState(false);

  const { pair } = useUrl();

  const pairOrderBook = useRecoilValue(pairOrderBookState(pair));
  let marketPrice = 1.0;

  useEffect(() => {
    try {
      if (
        pairOrderBook &&
        pairOrderBook.bestShortPrice &&
        pairOrderBook.bestLongPrice
      ) {
        marketPrice =
          placeTrade.orderDirection === 0
            ? pairOrderBook.bestShortPrice
            : pairOrderBook.bestLongPrice;
      }
    } catch (err) {
      console.error(err);
    }

    if (placeTrade.orderType === 0) {
      setPlaceTrade({
        ...placeTrade,
        price: marketPrice,
      });
    }
  }, [placeTrade.orderType, pairOrderBook, assetDenomString, priceDenomString]);

  const [errors, setErrors] = useState({ amount: "" });
  const { openOrder } = usePlaceTrade();

  const orderAmountFloat = parseFloat(orderAmount);

  const handlePriceInputChange = (value: string) => {
    const price = parseFloat(value);
    setPlaceTrade({
      ...placeTrade,
      price: price,
    });
    setOrderAmountPriceDenom(orderAmountFloat * price);
  };

  const handleOrderAmountChange = (value: string) => {
    setOrderAmount(value);
  };

  const handleOrderAmountPriceDenomChange = (value: any) => {
    setOrderAmountPriceDenom(parseFloat(value));
  };

  useEffect(() => {
    // When the order amount input change, update the price denom order amount too
    // and set the value of place trade
    const price = placeTrade.price || 0;
    setOrderAmountPriceDenom(orderAmountFloat * price);

    setPlaceTrade({
      ...placeTrade,
      quantity: orderAmountFloat
        ? orderAmountFloat
        : +Number(orderAmount).toFixed(6),
    });
  }, [orderAmount]);

  useEffect(() => {
    const price = placeTrade.price || 0;
    const quantity = +Number(orderAmountPriceDenom) / price;
    setPlaceTrade({
      ...placeTrade,
      quantity: quantity,
    });
  }, [orderAmountPriceDenom]);

  const handleLeverageChange = (value: any) => {
    setPlaceTrade({
      ...placeTrade,
      leverage: value,
    });
  };

  const handleSubmit = () => {
    if (!placeTrade.quantity) {
      setErrors({ ...errors, amount: "Amount must be greater than 0" });
      return;
    }

    const assetDenom = denoms[assetDenomString];
    const marketOrderPrice = placeTrade.orderDirection
      ? pairOrderBook.worstLongPrice
      : pairOrderBook.worstShortPrice;
    setIsOpeningOrder(true);
    openOrder(
      placeTrade.orderType === 0 ? orderType.MARKET : orderType.LIMIT,
      placeTrade.orderDirection,
      placeTrade.orderType === 0 ? marketOrderPrice : placeTrade.price,
      placeTrade.quantity,
      baseDenom,
      assetDenom,
      placeTrade.leverage
    ).then((tx) => {
      if (tx !== undefined && tx.transactionHash !== undefined)
        notifyOnSubmit(tx);
      queryAccountInfo().then(setAccountInfo);
      queryOpenOrdersInfo().then(setOpenOrders);
      queryUserSettlementsInfo().then(setUserSettlements);
      queryAccountBalanceState().then(setBalanceState);
      queryOpenPositionsInfo().then(setOpenPositions);
      setIsOpeningOrder(false);
    });
  };

  useEffect(() => {
    const calculateEstimate = async () => {
      if (
        walletAddress &&
        placeTrade.quantity > 0 &&
        placeTrade.price &&
        placeTrade.leverage
      ) {
        const priceDenom = denoms[priceDenomString];
        const assetDenom = denoms[assetDenomString];
        const estimate = await getOrderFeeEstimate(
          walletAddress,
          placeTrade.orderType === 0 ? orderType.MARKET : orderType.LIMIT,
          placeTrade.orderDirection,
          placeTrade.price,
          placeTrade.quantity,
          priceDenom,
          assetDenom,
          placeTrade.leverage
        );
        if (estimate && estimate.order_fee_estimate) {
          setOrderFeeEstimate(Number(estimate.order_fee_estimate.decimal));
        }
      }
    };
    calculateEstimate().then();
  }, [walletAddress, placeTrade]);

  const { total, orderPrice, fee } = getOrderPricing(
    placeTrade.price,
    placeTrade.quantity,
    orderFeeEstimate
  );
  const summary = getOrderSummary(
    orderPrice,
    placeTrade.quantity,
    placeTrade.leverage,
    fee,
    total,
    priceDenomString,
    assetDenomString
  );

  const availableBalance = useMemo(
    () =>
      (accountInfo?.balance?.decimal &&
        parseFloat(accountInfo.balance.decimal)) ||
      0,
    [accountInfo?.balance?.decimal]
  );

  const renderPercentageSelect = () => {
    const OPTIONS = [0.1, 0.25, 0.5, 0.75, 1];

    return (
      <div className={styles.percentageContainer}>
        {OPTIONS.map((option) => {
          const percentageAmount =
            (availableBalance * option) / placeTrade.price;

          const isActive =
            formatNumber(percentageAmount, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            }) ===
            formatNumber(orderAmountFloat, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            });

          return (
            <div
              key={option}
              className={cn(
                styles.percentage,
                isActive ? styles.percentageActive : ""
              )}
              onClick={() =>
                handleOrderAmountChange(percentageAmount.toLocaleString())
              }
            >
              {option * 100}%
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>Price</div>
        {placeTrade.orderType === 0 && (
          <div className={styles.tip}>(Price determined by market)</div>
        )}
      </div>
      {placeTrade.orderType === 1 ? (
        <label className={styles.field}>
          <input
            className={styles.input}
            type="number"
            name="price"
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            onFocus={(e) => {
              e.target.select();
            }}
            // step=".000001"
            autoComplete="off"
            value={
              placeTrade && placeTrade.price ? Number(placeTrade.price) : 1.0
            }
            onChange={(e) => handlePriceInputChange(e.target.value)}
            required
          />
          <div className={styles.currency}>
            {
              <Image
                size={24}
                srcDark={`/images/tokens/${priceDenomString}.png`}
              />
            }
            {priceDenomString}
          </div>
        </label>
      ) : (
        <label className={styles.disabled}>
          <input
            className={styles.input}
            type="number"
            name="price"
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            value={placeTrade && placeTrade.price ? placeTrade.price : 0.0}
            autoComplete="off"
            required
            disabled
          />
          <div className={styles.currency}>
            {
              <Image
                size={24}
                srcDark={`/images/tokens/${priceDenomString}.png`}
              />
            }
            {priceDenomString}
          </div>
        </label>
      )}
      <div className={styles.row}>
        <div className={styles.label}>Order Amount</div>
        <div className={styles.tip}>(Set Size Order)</div>
      </div>
      {/*//@ts-ignore*/}
      <div className={styles.row}>
        <label className={styles.field}>
          <input
            type="number"
            className={styles.input}
            autoComplete="off"
            name="amount"
            pattern="^\d*(\.\d{0,6})?$"
            onFocus={(e) => e.target.select()}
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            value={orderAmount}
            onChange={(e) => handleOrderAmountChange(e.target.value)}
            required
          />
          <div className={styles.currency}>
            {
              <Image
                size={24}
                srcDark={`/images/tokens/${assetDenomString}.png`}
              />
            }
            {assetDenomString}
          </div>
        </label>
        <label
          className={cn(styles.field, styles.fieldToggle, {
            [styles.hideField]: !showPriceDenom,
          })}
        >
          <input
            className={cn(styles.input, {
              [styles.hideInput]: !showPriceDenom,
            })}
            type="number"
            autoComplete="off"
            name="amount"
            step=".000001"
            onFocus={(e) => e.target.select()}
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            value={orderAmountPriceDenom.toFixed(6) || 0.0}
            onChange={(e) => handleOrderAmountPriceDenomChange(e.target.value)}
            required
          />
          <div
            className={cn(styles.currency, styles.inputToggle)}
            onClick={() => setShowPriceDenom(!showPriceDenom)}
          >
            {
              <Image
                size={24}
                srcDark={`/images/tokens/${priceDenomString}.png`}
              />
            }
            {priceDenomString}
          </div>
        </label>
      </div>
      {errors.amount && (
        <div className={styles.row}>
          <div className={styles.error}>{errors.amount}</div>
        </div>
      )}
      {renderPercentageSelect()}
      <LeverageBar
        value={placeTrade ? placeTrade.leverage : 1.0}
        setter={handleLeverageChange}
      />
      <ConfirmTransaction
        isButtonLoading={isOpeningOrder}
        onClick={handleSubmit}
        classButton={classButton}
        buttonText={buttonText}
        summary={summary}
      />
    </>
  );
};

export default Action;
