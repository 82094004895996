/* eslint-disable no-else-return */
import {
  DeepPartial,
  LineStyleOptions,
  SeriesOptionsCommon,
} from "lightweight-charts";

export const tradingViewCandlestickOptions = (
  width: number,
  height: number
) => ({
  width,
  height,
  priceFormat: {
    type: "custom",
    minMove: "0.000001",
    formatter: (price: string) => {
      const parsedPrice = parseFloat(price);
      if (parsedPrice < 0.000001) return parsedPrice.toPrecision(8);
      else if (parsedPrice >= 0.000001 && parsedPrice < 1)
        return parsedPrice.toPrecision(6);
      else return parsedPrice.toPrecision(6);
    },
  },
  priceScale: {
    autoScale: true,
  },
  localization: {
    locale: "en-US",
    priceFormatter: (price: string) => {
      const parsedPrice = parseFloat(price);
      if (parsedPrice < 0.000001) return parsedPrice.toPrecision(8);
      else if (parsedPrice >= 0.000001 && parsedPrice < 1)
        return parsedPrice.toPrecision(6);
      else return parsedPrice.toPrecision(6);
    },
  },
  timeScale: {
    timeVisible: true,
    secondsVisible: false,
    rightOffset: 12,
    barSpacing: 3,
    fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: false,
    rightBarStaysOnScroll: true,
    borderVisible: false,
    borderColor: "#fff000",
    visible: true,
  },
  layout: {
    backgroundColor: "#0b0d12cc",
    textColor: "#8A8E96",
  },
  grid: {
    vertLines: {
      color: "#23262F",
    },
    horzLines: {
      color: "#23262F",
    },
  },
  crosshair: {
    horzLine: {
      color: "#8A8E96",
    },
    vertLine: {
      color: "#8A8E96",
    },
    mode: 0,
  },
});

export const tradingViewCandlestickColorOptions = {
  upColor: "#00A878",
  downColor: "#C33C54",
  borderDownColor: "#C33C54",
  borderUpColor: "#00A878",
  wickDownColor: "#C33C54",
  wickUpColor: "#00A878",
};

export const tradingViewLineColorOptions: DeepPartial<
  LineStyleOptions & SeriesOptionsCommon
> = {
  color: "#516799",
  lineWidth: 2,
};
