const denoms: { [key: number | string]: number | string } = {
  SEI: 0,
  ATOM: 1,
  BTC: 2,
  ETH: 3,
  SOL: 4,
  AVAX: 5,
  USDC: 6,
  NEAR: 7,
  OSMO: 8,
  UST2: 6,
  0: "SEI",
  1: "ATOM",
  2: "BTC",
  3: "ETH",
  4: "SOL",
  5: "AVX",
  6: "UST2",
  7: "NEAR",
  8: "OSMO",
};

export default denoms;
