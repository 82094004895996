// Dex Indexer queries on chain and stores it in dynamodb for easy querying

// Atlantic 1
// const offchainURL = "https://offchain-v2.sei-chain-incentivized.com";

// Localhost (need to deploy dex-indexer query service)
// const offchainURL = "http://localhost:1301"

// Atlantic 2
const offchainURL =
  "https://dj6bkegrik2wsmg2pfiiwqtppy0bidex.lambda-url.eu-central-1.on.aws";

export default offchainURL;
