import React from "react";
import { EmptySetProps } from "./types";
import styles from "./EmptySet.module.sass";
import { MdErrorOutline } from "react-icons/md";
import cn from "classnames";

const EmptySet = ({ text, small }: EmptySetProps) => {
  return (
    <div className={styles.wrapper}>
      <MdErrorOutline
        className={cn(styles.icon, { [styles.smallIcon]: small })}
      />
      <p className={cn(styles.text, { [styles.smallText]: small })}>{text}</p>
    </div>
  );
};

export default EmptySet;
