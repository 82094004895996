import { OrderbookType } from "../../context/types";
import { atom } from "recoil";

export type OrderBookEntry = {
  shortBook: OrderbookType[];
  longBook: OrderbookType[];
  bestLongPrice: number;
  bestShortPrice: number;
  worstLongPrice: number;
  worstShortPrice: number;
};

export type OrderBook = {
  [pair: string]: OrderBookEntry;
};

export const orderBookState = atom<OrderBook>({
  key: "orderBookState",
  default: {},
});

export const orderBookRefreshState = atom<number>({
  key: "orderBookRefreshState",
  default: 0,
});
