import { contractAddr } from "../constants";
import {
  denoms,
  orderType as orderTypeEnum,
  positionDirection as positionDirectionEnum,
  positionEffect,
} from "../enums";
import useClient from "./useClient";

const useQueryContract = () => {
  const { getCosmWasmClient } = useClient();

  const getFundingPaymentRates = async (
    priceDenom: string,
    assetDenom: string,
    startEpoch: number,
    endEpoch: number
  ) => {
    const client = await getCosmWasmClient();
    // TODO: query latest block epoch (in development on backend)
    const query = {
      get_funding_payment_rates: {
        price_denom: priceDenom,
        asset_denom: assetDenom,
        start_epoch: 73511,
        end_epoch: 73811,
      },
    };

    try {
      const fundingPaymentRates = await client.queryContractSmart(
        contractAddr,
        query
      );
      return fundingPaymentRates;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getPosition = async (
    accountAddr: string,
    priceDenom: string,
    assetDenom: string
  ) => {
    const client = await getCosmWasmClient();
    const queryData = {
      get_position: {
        account: accountAddr,
        price_denom: priceDenom,
        asset_denom: assetDenom,
      },
    };

    try {
      return await client.queryContractSmart(contractAddr, queryData);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getBalances = async (accountAddr: string) => {
    const client = await getCosmWasmClient();
    const queryData = {
      get_balances: {
        account: accountAddr,
      },
    };

    try {
      const balances = await client.queryContractSmart(contractAddr, queryData);
      return balances;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getPortfolioSpecs = async (accountAddr: string) => {
    const client = await getCosmWasmClient();
    const queryData = {
      get_portfolio_specs: {
        account: accountAddr,
      },
    };

    try {
      return await client.queryContractSmart(contractAddr, queryData);
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getInsuranceFundBalance = async (denom: string) => {
    const client = await getCosmWasmClient();
    const queryData = {
      get_insurance_fund_balance: { denom },
    };

    try {
      return await client.queryContractSmart(contractAddr, queryData);
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const getOrderFeeEstimate = async (
    accountAddr: string,
    orderType: string | number,
    positionDirection: string | number,
    price: number,
    quantity: number,
    priceDenom: string | number,
    assetDenom: string | number,
    leverage: number
  ) => {
    const client = await getCosmWasmClient();
    const order = {
      id: 0,
      account: accountAddr,
      price_denom: denoms[priceDenom],
      asset_denom: denoms[assetDenom],
      price: { decimal: price.toString(), negative: false },
      quantity: { decimal: `0${quantity.toString()}`, negative: false },
      remaining_quantity: {
        decimal: `0${quantity.toString()}`,
        negative: false,
      },
      direction: positionDirectionEnum[positionDirection],
      effect: positionEffect[positionEffect.OPEN],
      leverage: { decimal: leverage.toString(), negative: false },
      order_type: orderTypeEnum[orderType],
    };
    const queryData = {
      get_order_estimate: {
        order,
      },
    };

    try {
      return await client.queryContractSmart(contractAddr, queryData);
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  return {
    getFundingPaymentRates,
    getPosition,
    getBalances,
    getPortfolioSpecs,
    getInsuranceFundBalance,
    getOrderFeeEstimate,
  };
};

export default useQueryContract;
