import React, { useEffect, useContext } from "react";
import cn from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import Wallet from "../../components/Wallet";
import { WalletContext } from "../../context";
import WalletConnectSection from "../../components/WalletConnectSection";
import { accountState } from "../../recoil/atoms";
import { fetchAccountInfo } from "../../recoil/selectors";
import styles from "./WalletOverview.module.sass";
import Market from "./Market";
import PortfolioSummary from "./PortfolioSummary";
import AccountBalances from "./AccountBalances";

const WalletOverview = () => {
  const keplrWalletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];

  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const queryAccountInfo = useRecoilValue(fetchAccountInfo(keplrWalletAddress));

  useEffect(() => {
    if (keplrWalletAddress) {
      queryAccountInfo().then(setAccountInfo);
    }
  }, [keplrWalletAddress]);

  return (
    <Wallet>
      <div className={styles.top}>
        <div className={styles.row}>
          <PortfolioSummary
            keplrWalletAddress={keplrWalletAddress}
            accountInfo={accountInfo}
          />
          {!keplrWalletAddress && (
            <div className={styles.connectBox}>
              <div className={styles.smallBox}>
                <WalletConnectSection />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.stats}>
        <div className={styles.item}>
          <div className={styles.body}>
            <AccountBalances accountInfo={accountInfo} />
          </div>
        </div>
      </div>
      <div className={styles.market}>
        <div className={styles.line}>
          <h4 className={cn("h4", styles.text)}>Market</h4>
        </div>
        <Market />
      </div>
    </Wallet>
  );
};
export default WalletOverview;
