import { tendermintURL } from "../constants";
import {
  SeiCosmWasmClient,
  getQueryClient as getSeiQueryClient,
} from "@sei-js/core";
import lcdURL from "../constants/lcdURL";

const useClient = () => {
  const getCosmWasmClient = async () => {
    return await SeiCosmWasmClient.connect(tendermintURL);
  };

  const getQueryClient = async () => {
    const queryClient = await getSeiQueryClient(lcdURL);
    return queryClient;
  };

  return { getCosmWasmClient, getQueryClient };
};

export default useClient;
