import { selectorFamily } from "recoil";
import useGetOrders from "../../context/hooks/useGetOrders";
import useOpenOrders from "../../context/hooks/useOpenOrders";

export const fetchOpenOrdersState = selectorFamily({
  key: "fetchOpenOrdersState",
  get:
    (walletAddress: string) =>
    ({ get }) => {
      // Onchain
      // const { getOrders } = useGetOrders();

      // Offchain database
      const { getOpenOrders } = useOpenOrders();

      return () => getOpenOrders(walletAddress);
    },
});
