import cn from "classnames";
import Wallet from "../../components/Wallet";
import MarketPositions from "../Exchange/Table/MarketPositions";
import styles from "./Positions.module.sass";

const Positions = () => (
  <Wallet>
    <div className={styles.top}>
      <h4 className={cn("h4", styles.text, styles.header)}>Positions</h4>
      <div className={styles.body}>
        <MarketPositions
          showAllPositions
          tableContentClassName={styles.table}
        />
      </div>
    </div>
  </Wallet>
);

export default Positions;
