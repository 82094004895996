import { atom } from "recoil";
import { FundingRateEpochEntry } from "../../context/types/FundingRateType";

export type FundingRate = {
  [pair: string]: FundingRateEpochEntry[];
};

export const fundingRateState = atom<FundingRate>({
  key: "fundingRateState",
  default: {},
});
