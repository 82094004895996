const conversions: { [key: number | string]: number } = {
  0: 1000000,
  6: 1000000,
  aUSDC: 1000000,
  UST2: 1000000,
  WBTC: 100000000,
  WETH: 1000000000000000000,
};

export default conversions;
