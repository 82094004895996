import { selectorFamily } from "recoil";
import useSettlements from "../../context/hooks/useSettlements";

export const fetchUserSettlementsState = selectorFamily({
  key: "fetchUserSettlementsState",
  get:
    (walletAddress: string) =>
    ({ get }) => {
      const { getSettlementsByUser } = useSettlements();
      return async () => await getSettlementsByUser(walletAddress);
    },
});
