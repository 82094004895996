import React from "react";
import BridgeAddressConfirm from "./BridgeAddressConfirm";
import BridgeSuccess from "./BridgeSuccess";
import { useRecoilValue } from "recoil";
import { bridgeModalIndexState } from "../recoil/atoms";

const BridgeModal = () => {
  const modalPageIndex = useRecoilValue(bridgeModalIndexState);

  const renderPage = () => {
    switch (modalPageIndex) {
      case 0:
        return <BridgeAddressConfirm />;
      case 1:
        return <BridgeSuccess />;
    }
  };

  return <div>{renderPage()}</div>;
};

export default BridgeModal;
