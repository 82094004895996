// Atlantic 1
// const contractAddr = "sei1466nf3zuxpya8q9emxukd7vftaf6h4psr0a07srl5zw74zh84yjqpeheyc";

// Atlantic 1 Shadow
// const contractAddr =
//   "sei1yyca08xqdgvjz0psg56z67ejh9xms6l436u8y58m82npdqqhmmtq95rw0g";

// Atlantic 2
const contractAddr =
  "sei12k3aacdygvjuran5hz60067pgu2uuuscz3styw2zk3q4aedj0v2sjq3tsj";

export default contractAddr;
