import { SettlementType } from "../../screens/Exchange/Table/TradeHistory/types";
import axios from "axios";
import offchainURL from "../constants/offchainURL";
import useUrl from "./useUrl";
import { contractAddr } from "../constants";

const useSettlements = () => {
  const getSettlementsByUser = async (accountAddr: string) => {
    let result: SettlementType[] = [];
    try {
      await axios
        .get(`${offchainURL}/settlements/${accountAddr}`)
        .then((results) => {
          result =
            results?.data?.length > 0
              ? results.data.sort((a: any, b: any) => b.timestamp - a.timestamp)
              : [];
        });
      return result;
    } catch (error) {
      console.error(error);
      return result;
    }
  };

  const { getExchangeDenoms } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();

  const getSettlementsByMarket = async () => {
    let result: SettlementType[] = [];

    try {
      await axios
        .get(
          `${offchainURL}/settlementsByMarket//${contractAddr}-${priceDenomString}-${assetDenomString}`
        )
        .then((results) => {
          result =
            results?.data?.length > 0
              ? results.data.sort((a: any, b: any) => b.timestamp - a.timestamp)
              : [];
        });
      return result;
    } catch (error) {
      console.error(error);
      return result;
    }
  };

  return { getSettlementsByUser, getSettlementsByMarket };
};

export default useSettlements;
