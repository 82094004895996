import React from "react";
import {
  Axis,
  Grid,
  buildChartTheme,
  XYChart,
  Tooltip,
  AnimatedAreaSeries,
  Annotation,
  AnnotationLineSubject,
} from "@visx/xychart";
import { curveStepAfter } from "@visx/curve";

import styles from "./DepthChart.module.sass";
import { OrderbookType } from "../../../context/types";

export type DepthChartProps = {
  width: number;
  height: number;
  shortBook: OrderbookType[];
  longBook: OrderbookType[];
};

export type OrderBookData = {
  price: number;
  quantity: number;
};

const DepthChart = ({
  width,
  height,
  shortBook,
  longBook,
}: DepthChartProps) => {
  const shortBookData: OrderBookData[] = [];
  shortBook.forEach((d, i) => {
    const data = {
      price: Number(d.price),
      quantity:
        Number(d.entry.quantity) + (i ? shortBookData[i - 1].quantity : 0),
    };
    shortBookData.push(data);
  });
  shortBookData.sort((a, b) => a.price - b.price);

  const longBookData: OrderBookData[] = [];
  longBook.forEach((d, i) => {
    const data = {
      price: Number(d.price),
      quantity:
        Number(d.entry.quantity) + (i ? longBookData[i - 1].quantity : 0),
    };
    longBookData.push(data);
  });
  longBookData.sort((a, b) => a.price - b.price);

  const accessors = {
    xAccessor: (d: OrderBookData | undefined) => d?.price,
    yAccessor: (d: OrderBookData | undefined) => d?.quantity,
  };

  const customTheme = buildChartTheme({
    backgroundColor: "#08161D",
    colors: ["#008F77", "#FC2D3F"],
    gridColor: "#23262F",
    gridColorDark: "#8A8E96",
    tickLength: 8,
    svgLabelSmall: { fill: "#8A8E96" },
    svgLabelBig: { fill: "#8A8E96" },
    htmlLabel: { fill: "#8A8E96" },
  });

  const getDomains = () => {
    // TODO: Add ability to scale the domains when zooming in/out.
    const minLength = Math.min(shortBookData.length, longBookData.length);
    const dataPointsToDisplay = Math.min(5, minLength);
    const lowPoint = longBookData[longBookData.length - dataPointsToDisplay];
    const highPoint = shortBookData[shortBookData.length - dataPointsToDisplay];
    return {
      xDomain: [lowPoint.price, highPoint.price],
      yDomain: [0, Math.max(lowPoint.quantity, highPoint.quantity)],
    };
  };

  const getMidPoint = () => {
    const center =
      (longBookData[longBookData.length - 1].price + shortBookData[0].price) /
      2;
    const midPoint: OrderBookData = {
      price: center,
      quantity: 1,
    };
    return midPoint;
  };

  const { xDomain, yDomain } = getDomains();
  const midPoint = getMidPoint();

  return (
    <div className={styles.chartContainer}>
      <XYChart
        height={height}
        width={width}
        xScale={{
          type: "linear",
          zero: false,
          domain: xDomain,
        }}
        yScale={{
          type: "linear",
          nice: true,
          domain: yDomain,
        }}
        theme={customTheme}
      >
        <Axis orientation="bottom" />
        <Grid />
        <AnimatedAreaSeries
          dataKey="LongBook"
          data={longBookData}
          renderLine={true}
          fillOpacity={0.4}
          curve={curveStepAfter}
          {...accessors}
        />
        <AnimatedAreaSeries
          dataKey="ShortBook"
          data={shortBookData}
          renderLine={true}
          fillOpacity={0.4}
          curve={curveStepAfter}
          {...accessors}
        />
        <Annotation dataKey="ShortBook" datum={midPoint}>
          <AnnotationLineSubject
            orientation="vertical"
            stroke="#8A8E96"
            min={0}
            max={height}
          />
        </Annotation>
        <Tooltip<OrderBookData>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showDatumGlyph
          showVerticalCrosshair
          showHorizontalCrosshair
          verticalCrosshairStyle={{
            stroke: "#B1B5C3",
            strokeDasharray: "5,5",
          }}
          horizontalCrosshairStyle={{
            stroke: "#B1B5C3",
            strokeDasharray: "5,5",
          }}
          renderTooltip={({ tooltipData, colorScale }) => (
            <div
              className={styles.tooltip}
              style={{
                borderLeftColor:
                  tooltipData?.nearestDatum?.key && colorScale
                    ? colorScale(tooltipData.nearestDatum?.key)
                    : "#B1B5C3",
              }}
            >
              <div className={styles.tooltipRow}>
                <div className={styles.tooltipLabel}>Price</div>
                <div className={styles.tooltipValue}>
                  {accessors.xAccessor(tooltipData?.nearestDatum?.datum)}
                </div>
              </div>
              <div className={styles.tooltipRow}>
                <div className={styles.tooltipLabel}>Total Size</div>
                <div className={styles.tooltipValue}>
                  {accessors.yAccessor(tooltipData?.nearestDatum?.datum)}
                </div>
              </div>
              <div className={styles.tooltipRow}>
                <div className={styles.tooltipLabel}>Total Cost</div>
                <div className={styles.tooltipValue}>
                  {(accessors.xAccessor(tooltipData?.nearestDatum?.datum) ||
                    0) *
                    (accessors.yAccessor(tooltipData?.nearestDatum?.datum) ||
                      0)}
                </div>
              </div>
            </div>
          )}
        />
      </XYChart>
    </div>
  );
};

export default DepthChart;
