import React, { useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import web3 from "web3";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import DropdownTokens from "../../../../components/DropdownTokens";
import {
  BridgeChainOption,
  bridgeChainOptions,
  seiChainOptions,
} from "../../../../context/constants/bridging";
import styles from "../BridgeCard.module.sass";
import WalletConnectModal from "../../../../components/WalletConnectModal";
import Modal from "../../../../components/Modal";
import { WalletContext } from "../../../../context";
import { openInNewTab, truncateAddress } from "../../../../utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  bridgeDestinationAddressState,
  bridgeDestinationChainState,
  bridgeDestinationTokenState,
  bridgeErrorState,
  bridgeFromChainState,
  bridgeFromTokenState,
  bridgeLoadingState,
  bridgeModalIndexState,
  bridgeOrderAmountInputState,
  bridgeStatusState,
} from "../../recoil/atoms";
import { sendTokens } from "../../../../utils/bridging/axelarSendToken";
import { toast, ToastOptions } from "react-toastify";
import { BRIDGING_STATUS_OPTIONS } from "./config";
import { BridgingStatus } from "./types";
import { connectMetaMask } from "../../../../components/WalletConnectModal/WalletUtils/MetaMask";
import { ethers } from "ethers";
import { formatNumber } from "../../../../utils/formatting";

const BridgeAddressConfirm = () => {
  const { wallet, setWallet } = useContext(WalletContext);

  const [destinationToken, setDestinationToken] = useRecoilState(
    bridgeDestinationTokenState
  );

  const setDepositAddressError = useSetRecoilState(bridgeErrorState);
  const [isLoading, setLoading] = useRecoilState(bridgeLoadingState);
  const [fromToken, setFromToken] = useRecoilState(bridgeFromTokenState);
  const [modalPageIndex, setModalPageIndex] = useRecoilState(
    bridgeModalIndexState
  );
  const [destinationChain, setDestinationChain] = useRecoilState(
    bridgeDestinationChainState
  );
  const [orderAmountInput, setOrderAmountInput] = useRecoilState(
    bridgeOrderAmountInputState
  );
  const [txStatus, setTxStatus] = useRecoilState(bridgeStatusState);
  const [destinationAddress, setDestinationAddress] = useRecoilState(
    bridgeDestinationAddressState
  );
  const [fromChain, setFromChain] = useRecoilState(bridgeFromChainState);

  const [metamaskBalance, setMetamaskBalance] = useState<string>("");
  const [isMMConnecting, setIsMMConnecting] = useState<boolean>(false);
  const [swappedDirection, setSwappedDirection] = useState(true);
  const [txHash, setTxHash] = useState("");
  const [visibleWalletConnectModal, setVisibleWalletConnectModal] =
    useState(false);

  const { cosmosAccounts, metamaskAccounts } = useContext(WalletContext).wallet;

  const transactionToast = React.useRef();

  const txAddressURL = `https://goerli.etherscan.io/address/${metamaskAccounts[0]}`;
  const txExploreURL = `https://goerli.etherscan.io/tx/${txHash}`;

  const notifyFailure = (failureMsg: string) => {
    if (failureMsg.includes("user rejected transaction")) {
      return toast(<div>🚫 User rejected</div>);
    }

    return toast(<div>🚫 {failureMsg}</div>);
  };

  const currentStatusIndex = useMemo(
    () => BRIDGING_STATUS_OPTIONS.indexOf(txStatus),
    [txStatus]
  );

  const component = useMemo(() => {
    const renderSection = (section: BridgingStatus) => {
      switch (section) {
        case "connecting":
          if (currentStatusIndex < 1) return null;
          if (currentStatusIndex === 1)
            return (
              <p className={styles.status}>
                <span className={styles.emoji}>⌛</span>Connecting to bridge...
              </p>
            );
          return (
            <p className={styles.status}>
              <span className={styles.emoji}>✅</span>Connected to bridge
            </p>
          );
        case "awaitingContractApproval":
          if (currentStatusIndex < 2) return null;
          if (currentStatusIndex === 2)
            return (
              <p className={styles.status}>
                <span className={styles.emoji}>⌛</span>Contract funds
                approval...
              </p>
            );
          return (
            <p className={styles.status}>
              <span className={styles.emoji}>✅</span>Contract funds approved
            </p>
          );
        case "awaitingBridgeSigning":
          if (currentStatusIndex < 3) return null;
          if (currentStatusIndex === 3)
            return (
              <p className={styles.status}>
                <span className={styles.emoji}>⌛</span>Signing...
              </p>
            );
          return (
            <p className={styles.status}>
              <span className={styles.emoji}>✅</span> Signed
            </p>
          );
        case "bridgeInitialized":
          if (currentStatusIndex < 4) return null;
          return (
            <>
              <p className={styles.status}>
                <span className={styles.emoji}>✅</span> Bridge successful
              </p>
              <p
                className={styles.exploreLink}
                onClick={() => openInNewTab(txExploreURL)}
              >
                View on Explorer
              </p>
            </>
          );
      }
    };

    return (
      <div>
        {BRIDGING_STATUS_OPTIONS.map((section, i) => {
          return <div key={i}>{renderSection(section)}</div>;
        })}
      </div>
    );
  }, [isLoading, txStatus]);

  useEffect(
    () =>
      transactionToast?.current &&
      toast.update(transactionToast.current, {
        render: component,
        autoClose: false,
      }),
    [component]
  );

  const handleClickContinue = async () => {
    setLoading(true);
    // @ts-ignore
    transactionToast.current = toast(component, {
      autoClose: false,
    } as ToastOptions);
    try {
      setTxHash(
        await sendTokens({
          destinationChain: destinationChain.axelarName,
          destinationAddress,
          symbol: fromToken.symbol,
          axelarTokenAddress: fromToken.axelarTokenAddress || "",
          axelarChainAddress: fromChain.axelarChainAddress || "",
          amount: orderAmountInput,
          setTxStatus: setTxStatus,
        })
      );
      setModalPageIndex(modalPageIndex + 1);
      setLoading(false);
    } catch (err) {
      toast.dismiss(transactionToast.current);
      setLoading(false);
      setTxStatus("awaitingUserInput");
      setDepositAddressError(err.message);
      notifyFailure(err.message);
    }
  };

  const onClickAutofill = () => {
    if (destinationChain.symbol === "SEI") {
      if (cosmosAccounts && cosmosAccounts.length === 0) {
        setVisibleWalletConnectModal(true);
      }
      setDestinationAddress(cosmosAccounts[0]);
    }
  };

  const onClickSwapDirection = () => {
    const tempSwapChain = fromChain;
    setFromChain(destinationChain);
    setDestinationChain(tempSwapChain);

    const tempSwapToken = fromToken;
    setFromToken(destinationToken);
    setDestinationToken(tempSwapToken);

    setSwappedDirection(!swappedDirection);
    setDestinationAddress("");

    // if (swappedDirection) {
    //   setVisibleWalletConnectModal(true);
    // }
  };

  const setChangeChain = (chain: BridgeChainOption) => {
    setFromChain(chain);
    setFromToken(chain.tokens[0]);
    // if (
    //   cosmosAccounts &&
    //   cosmosAccounts.length === 0 &&
    //   metamaskAccounts &&
    //   metamaskAccounts.length === 0
    // ) {
    //   setVisibleWalletConnectModal(true);
    // }
    // if (
    //   cosmosAccounts &&
    //   cosmosAccounts.length > 0 &&
    //   metamaskAccounts &&
    //   metamaskAccounts.length === 0 &&
    //   !chain.supportedWallets.includes("keplr")
    // ) {
    //   setVisibleWalletConnectModal(true);
    // }
  };

  const confirmationAmount = (
    sourceChain: BridgeChainOption,
    destinationChain: BridgeChainOption
  ) => (
    <div className={styles.confirmationCard}>
      {
        <div className={styles.rowText}>
          <div className={styles.rowKey}>You will receive</div>
          <div className={styles.rowValue}>
            {Number(orderAmountInput) * 0.9231344235} {fromToken.symbol}
          </div>
        </div>
      }
      {/*<div className={styles.rowText}>*/}
      {/*  <div className={styles.rowKey}>Gas on {sourceChain.name}</div>*/}
      {/*  <div className={styles.rowValue}>0.057687 {sourceChain.symbol}</div>*/}
      {/*</div>*/}
      <div className={styles.rowText}>
        <div className={styles.rowKey}>Wait Time</div>
        <div className={styles.rowValue}>Up to 20 mins</div>
      </div>
    </div>
  );

  const renderButton = () => {
    if (isLoading)
      return (
        <button className={cn("button", styles.button)}>Loading...</button>
      );
    if (destinationAddress !== "" && orderAmountInput !== "") {
      return (
        <button
          className={cn("button", styles.button)}
          onClick={() => handleClickContinue()}
        >
          Bridge {fromToken.symbol} from Metamask
        </button>
      );
    }
    return (
      <button className={cn("button", styles.button)} disabled>
        Bridge From {fromChain.name === "SEI Network" ? "Sei" : "Metamask"}
      </button>
    );
  };

  const connectToMetamask = async () => {
    setIsMMConnecting(true);
    const { accounts } = await connectMetaMask("atlantic-1", "sei");
    const updateWallet = { ...wallet };
    updateWallet.metamaskAccounts = accounts;
    //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    updateWallet.metamaskSigner = provider.getSigner();
    const mmBalance = await provider.getBalance(accounts[0]);
    setMetamaskBalance(
      formatNumber(web3.utils.fromWei(mmBalance.toString(), "ether"), {
        maximumFractionDigits: 6,
      })
    );
    setWallet(updateWallet);
    setIsMMConnecting(false);
  };

  const renderAvailable = () => {
    if (!swappedDirection) return null;
    if (metamaskBalance) {
      return (
        <p className={styles.label}>
          Available:{" "}
          <span className={styles.mmBalance}>{metamaskBalance} ETH</span>
        </p>
      );
    }
    return (
      <p className={styles.label}>
        Available:{" "}
        <span onClick={connectToMetamask} className={styles.connect}>
          {isMMConnecting ? "connecting..." : "connect to metamask"}
        </span>
      </p>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.withdraw}>
        <div className={cn("h4", styles.text)}>Bridge</div>
        <div className={styles.field}>
          <div className={styles.row}>
            <div className={styles.cardTitle}>From</div>
            <DropdownTokens
              className={styles.fromDropdown}
              value={fromChain}
              setValue={setChangeChain}
              options={swappedDirection ? bridgeChainOptions : seiChainOptions}
            />
            <DropdownTokens
              type="narrow"
              className={styles.tokenDropdown}
              value={fromToken}
              setValue={setFromToken}
              options={fromChain.tokens}
            />
          </div>
          {destinationChain.symbol === "SEI" && (
            <TextInput
              className={styles.field}
              label="Amount"
              placeholder="Enter quantity of token"
              autoComplete="off"
              type="number"
              name="amount"
              value={orderAmountInput}
              onChange={(e) => setOrderAmountInput(e.target.value)}
              required
            />
          )}
          <div className={styles.metamask}>
            {renderAvailable()}
            {metamaskBalance &&
            metamaskAccounts &&
            metamaskAccounts.length > 0 ? (
              <div
                className={styles.exploreLink}
                onClick={() => openInNewTab(txAddressURL)}
              >
                <span>{truncateAddress(metamaskAccounts[0])}</span>
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.card}>
          <div className={cn("h4", styles.icon)} onClick={onClickSwapDirection}>
            <Icon
              className={styles.clickableIcon}
              name="swap-direction"
              size="40"
              viewSize="40"
            />
          </div>
          <div className={styles.field}>
            <div className={styles.row}>
              <div className={styles.cardTitle}>To</div>
              <DropdownTokens
                className={styles.dropdown}
                value={destinationChain}
                setValue={setDestinationChain}
                options={
                  swappedDirection ? seiChainOptions : bridgeChainOptions
                }
              />
            </div>
          </div>
          {/* <div className={styles.row}>
          <label className={styles.inputBox}>
            <input
              disabled
              className={styles.input}
              type="number"
              autoComplete="off"
              name="amount"
              onFocus={(e) => {
                e.target.select();
              }}
              onWheel={(e) => e.target.blur()}
              value={orderAmountInput * 0.9231344235}
              onChange={(e) =>
                setOrderAmountInput(+Number(e.target.value).toFixed(6))
              }
              required
            />
          </label>
        </div> */}
          <div className={styles.box}>
            {destinationChain.symbol === "SEI" && (
              <>
                <button className={styles.autofill} onClick={onClickAutofill}>
                  autofill
                </button>
                <TextInput
                  className={styles.field}
                  label="Destination Sei Address"
                  name="Destination"
                  value={destinationAddress}
                  onChange={(e) => setDestinationAddress(e.target.value)}
                  placeholder="Enter destination address"
                  type="text"
                  required
                />
              </>
            )}
          </div>
        </div>

        {destinationAddress !== "" &&
          fromChain.name !== "Sei Network" &&
          confirmationAmount(fromChain, destinationChain)}

        {fromChain.name === "SEI Network" && (
          <div className={cn("description", styles.text)}>
            Currently, to bridge from Sei to another chain, it is recommended
            that you use
            <span
              className={styles.link}
              onClick={() =>
                openInNewTab("https://testnet.satellite.money/?source=sei")
              }
            >
              Satellite Money
            </span>
          </div>
        )}
        {renderButton()}
        <Modal
          outerClassName={styles.modal}
          visible={visibleWalletConnectModal}
          onClose={() => setVisibleWalletConnectModal(false)}
        >
          <WalletConnectModal
            chain={destinationChain}
            onConnectCloseModal={() => setVisibleWalletConnectModal(false)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default BridgeAddressConfirm;
