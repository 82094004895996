import React, { useState, useContext } from "react";
import cn from "classnames";
import { ethers } from "ethers";
import { supportedWallets } from "../../context/constants/supportedWallets.ts";
import { AutoConnectContext, WalletContext } from "../../context";
import { openInNewTab } from "../../utils";
import styles from "./WalletConnectModal.module.sass";
import { connectKeplr } from "./WalletUtils/Keplr";
import { connectMetaMask } from "./WalletUtils/MetaMask";
import WalletConnectTerms from "./WalletConnectTerms/WalletConnectTerms";
import { connectLeap } from "./WalletUtils/Leap";

const WalletConnectModal = (props) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [clickedContinue, setClickedContinue] = useState(false);

  const { wallet, setWallet } = useContext(WalletContext);
  const { autoConnect, setAutoConnect } = useContext(AutoConnectContext);

  const { onConnectCloseModal, chain } = props;

  async function triggerWalletConnect(walletType, chainID, chainName) {
    if (walletType === "Keplr") {
      const { accounts, signer } = await connectKeplr();
      if (accounts !== null && accounts !== undefined) {
        setWallet(() => {
          const updateWallet = { ...wallet };
          updateWallet.cosmosAccounts = accounts.map(
            (account) => account.address
          );
          updateWallet.cosmosSigner = signer;
          return updateWallet;
        });
        setAutoConnect({ ...autoConnect, keplr: true });
      }
      onConnectCloseModal();
      return accounts;
    }

    if (walletType === "Leap") {
      const { accounts, signer } = await connectLeap();

      if (accounts) {
        setWallet(() => {
          const updateWallet = { ...wallet };
          updateWallet.cosmosAccounts = accounts.map(
            (account) => account.address
          );
          updateWallet.cosmosSigner = signer;
          return updateWallet;
        });
        setAutoConnect({ ...autoConnect, leap: true });
      }
      onConnectCloseModal();
      return accounts;
    }

    if (walletType === "MetaMask") {
      const { accounts } = await connectMetaMask(chainID, chainName);
      const updateWallet = { ...wallet };
      updateWallet.metamaskAccounts = accounts;
      // A Web3Provider wraps a standard Web3 provider, which is
      // what MetaMask injects as window.ethereum into each page
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // MetaMask requires requesting permission to connect users accounts
      await provider.send("eth_requestAccounts", []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();

      updateWallet.metamaskSigner = signer;
      setWallet(updateWallet);
      setAutoConnect({ ...autoConnect, metaMask: true });
      onConnectCloseModal();
      return accounts;
    }

    return null;
  }

  const connectWalletRow = (wallet, i) => (
    <div
      key={i}
      className={styles.walletRow}
      onClick={() =>
        triggerWalletConnect(wallet.title, wallet.chainID, wallet.chainName)
      }
    >
      <div className={styles.featureTitle}>{wallet.title}</div>
      <img height={26} src={wallet.image} alt={wallet.title} />
    </div>
  );

  const defaultWalletConnect = () => (
    <div>
      <div className={styles.col}>
        <div className={cn("h4", styles.text)}>Connect crypto wallet</div>
        <div className={cn("description", styles.text)}>
          By connecting to your wallet, you agree to our Term of Service and
          Privacy Policy.
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.col}>
          {Object.entries(supportedWallets).map((wallet, i) =>
            connectWalletRow(wallet[1], i)
          )}
        </div>
      </div>
      <div className={styles.subtextRow}>
        <div className={styles.subtext}>New to Cosmos? Learn about</div>
        <div
          className={styles.link}
          onClick={() => openInNewTab("https://cosmos.network")}
        >
          <span>the Cosmo ecosystem</span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {!clickedContinue && !chain && (
        <WalletConnectTerms
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          setClickedContinue={setClickedContinue}
        />
      )}

      {chain && (
        <div>
          <div className={styles.col}>
            <div className={cn("h4", styles.text)}>Connect crypto wallet</div>
            <div className={cn("description", styles.text)}>
              By connecting to your wallet, you agree to our Term of Service and
              Privacy Policy.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.col}>
              {chain.supportedWallets.map((wallet, i) =>
                connectWalletRow(supportedWallets[wallet], i)
              )}
            </div>
          </div>
        </div>
      )}

      {clickedContinue && defaultWalletConnect()}
    </div>
  );
};

export default WalletConnectModal;
