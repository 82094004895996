import cn from "classnames";
import { useRecoilValue } from "recoil";
import { useUrl } from "../../../context";
import {
  parseFetchedBook,
  spreadDifference,
} from "../../../context/hooks/useOrderbook";
import { pairOrderBookState } from "../../../recoil/selectors";
import styles from "./Orderbook.module.sass";
import { OrderbookProps } from "./types";
import { formatNumber } from "../../../utils/formatting";

const BOOK_LENGTH = 30;

const Orderbook = ({ decimalRounding }: OrderbookProps) => {
  const { pair } = useUrl();
  const [assetDenomString, priceDenomString] = pair.split("-");

  const pairOrderBook = useRecoilValue(pairOrderBookState(pair));
  if (!pairOrderBook) return null;
  const longBook = parseFetchedBook(pairOrderBook.longBook, decimalRounding);
  const shortBook = parseFetchedBook(pairOrderBook.shortBook, decimalRounding);

  const { spread, spreadPercentage } = spreadDifference(longBook, shortBook);

  const renderShortBook = () => {
    if (!shortBook.length) {
      return (
        <div className={cn(styles.item, { [styles.negative]: true })}>
          <div className={styles.price}>No shorts available</div>
          <div className={styles.amount}>n/a</div>
          <div className={styles.line} style={{ width: `100%` }} />
        </div>
      );
    }
    return (
      <div className={cn(styles.book, styles.bookShort)}>
        {shortBook
          .slice(-BOOK_LENGTH)
          .reverse()
          .map((x, index) => (
            <div
              className={cn(styles.item, { [styles.negative]: true })}
              key={index}
            >
              <div className={styles.price}>{x.price}</div>
              <div className={styles.amount}>
                {formatNumber(x.amount, {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 2,
                })}
              </div>
              <div className={styles.line} style={{ width: `${x.percent}%` }} />
            </div>
          ))}
      </div>
    );
  };

  const renderDivider = () => {
    const spreadText = shortBook.length && longBook.length ? spread : " ";
    return (
      <div className={cn(styles.statistics, styles.positive)}>
        <div className={styles.index}>{spreadText}</div>
        {shortBook.length && longBook.length ? (
          <div className={styles.spread}>Spread</div>
        ) : (
          <div> </div>
        )}

        <div className={styles.percent}>
          {shortBook.length && longBook.length ? `${spreadPercentage}%` : " "}
        </div>
      </div>
    );
  };

  const renderLongBook = () => {
    if (!longBook.length) {
      return (
        <div className={cn(styles.item, { [styles.positive]: true })}>
          <div className={styles.price}>No longs available</div>
          <div className={styles.amount}>n/a</div>
          <div className={styles.line} style={{ width: `100%` }} />
        </div>
      );
    }
    return (
      <div className={styles.book}>
        {longBook.slice(0, BOOK_LENGTH).map((x, index) => (
          <div
            className={cn(styles.item, { [styles.positive]: true })}
            key={index}
          >
            <div className={styles.price}>{x.price}</div>
            <div className={styles.amount}>
              {formatNumber(x.amount, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              })}
            </div>
            <div className={styles.line} style={{ width: `${x.percent}%` }} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={styles.top}>
        <div className={styles.price}>Price ({priceDenomString})</div>
        <div className={styles.amount}>Size ({assetDenomString})</div>
      </div>

      <div className={styles.bookList}>
        {renderShortBook()}
        {renderDivider()}
        {renderLongBook()}
      </div>
    </>
  );
};

export default Orderbook;
