import { OfflineSigner, Registry } from "@cosmjs/proto-signing";
import { defaultRegistryTypes } from "@cosmjs/stargate";
import { getSigningClient } from "@sei-js/core";
import { seiprotocolProtoRegistry } from "@sei-js/proto";
import { tendermintURL } from "../context";

export const getSigningClientWithRegistry = async (signer: OfflineSigner) => {
  const registry = new Registry([
    ...defaultRegistryTypes,
    ...seiprotocolProtoRegistry,
  ]);
  const signingClient = await getSigningClient(tendermintURL, signer, {
    registry,
  });
  return signingClient;
};
