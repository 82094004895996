function formatDataForChart(data) {
  const result = [];
  data.forEach((dateData, index) => {
    result.push({ name: index.toString(), price: dateData[1] });
    return null;
  });
  return result;
}

function numberWithCommas(x, type = "currency") {
  let prefix = "";
  if (type === "currency") {
    prefix = "$"
  }
  const suffix = type === "percentage" ? "%" : "";
  return `${prefix}${Number(x).toLocaleString("en-us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}${suffix}`;
}

function formatDateFromTimestamp(timestamp) {
  return `${(new Date(timestamp * 1000)).toLocaleDateString("en-US").toString()} - ${(new Date(timestamp * 1000)).toLocaleTimeString("en-US").toString()}`;
}

function formatTitleString(x) {
  return x.charAt(0).toUpperCase() + x.split("_").join(" ").slice(1);
}

export { numberWithCommas, formatDataForChart, formatTitleString, formatDateFromTimestamp };
