import { NavigationItem } from "./types";

export const NAVIGATION_ITEMS: NavigationItem[] = [
  // {
  //   title: "Market",
  //   urls: ["/"],
  // },
  {
    title: "Portfolio",
    urls: ["/", "/positions", "/orders", "/history"],
  },
  {
    title: "Trade",
    urls: ["/trade"],
  },
  {
    title: "Bridge",
    urls: ["/bridge"],
  },
  // {
  //   title: "Lite",
  //   urls: ["/lite"],
  // },
];
