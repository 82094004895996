import { useContext } from "react";
// @ts-ignore
import { txClient } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module";
// @ts-ignore
import { MsgPlaceOrders } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module/types/dex/tx";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import {
  contractAddr,
  denoms,
  positionEffect,
  tendermintURL,
  txnFactor,
  WalletContext,
} from "..";
import { orderBookRefreshState } from "../../recoil/atoms/orderbook";
import { getSigningClientWithRegistry } from "../../utils";

const usePlaceTrade = () => {
  const { cosmosAccounts, cosmosSigner } = useContext(WalletContext).wallet || {
    cosmosSigner: null,
    cosmosAccounts: [],
  };

  const [orderBookRefresh, setOrderBookRefresh] = useRecoilState(
    orderBookRefreshState
  );

  const [firstAccount] = cosmosAccounts;

  const openOrder = async (
    orderType: number | string,
    positionDirection: number,
    price: number,
    quantity: number,
    priceDenom: number | string,
    assetDenom: number | string,
    leverage: any,
    openClosePosition = positionEffect.OPEN
  ) => {
    if (!cosmosSigner) return undefined;

    const { msgPlaceOrders } = await txClient(cosmosSigner, {
      addr: tendermintURL,
    });
    const signingClient = await getSigningClientWithRegistry(cosmosSigner);

    const queryData = JSON.stringify({
      // @ts-ignore
      position_effect: positionEffect[openClosePosition],
      leverage: leverage.toString(),
    });

    const order = {
      account: firstAccount,
      contractAddr,
      positionDirection,
      price: BigInt(price * txnFactor).toString(10),
      quantity: BigInt(quantity * txnFactor).toString(10),
      priceDenom: denoms[priceDenom],
      assetDenom: denoms[assetDenom],
      orderType,
      data: queryData,
    };

    const feeObject = {
      amount: [
        {
          denom: "usei",
          amount: "15000",
        },
      ],
      gas: "300000",
    };

    const msg = MsgPlaceOrders.fromPartial({
      creator: firstAccount,
      orders: [order],
      contractAddr,
      funds: [],
    });

    try {
      const result = await signingClient?.signAndBroadcast(
        firstAccount,
        [msgPlaceOrders(msg)],
        feeObject
      );
      setOrderBookRefresh(orderBookRefresh + 1);
      return result;
    } catch (error) {
      if (error?.message?.split("Log: ")?.[1]) {
        toast.error(error?.message?.split("Log: ")?.[1]);
      } else {
        console.error(error);
        toast.error("Transaction error.");
      }
      return undefined;
    }
  };

  return { openOrder };
};

export default usePlaceTrade;
