import React, { useState, useEffect, useContext } from "react";
// import cn from "classnames";
import { Link } from "react-router-dom";
import axios from "axios";
import Icon from "../../../components/Icon";
import { CoinGeckoContext } from "../../../context/CoinGeckoContext";
import { numberWithCommas } from "../../../utils";
import { tokenPairs } from "../../../context/constants/tokenPairs.ts";
import MiniPriceChart from "../../MiniPriceChart";
import styles from "./ExploreMarketList.module.sass";

const ExploreMarketList = () => {
  const [chartData, setChartData] = useState([]);

  const { coinGeckoMarketData } = useContext(CoinGeckoContext);

  useEffect(() => {
    let isMounted = true;
    const promises = [];
    Object.keys(tokenPairs).map((key) => {
      if (key === "sei") {
        return null;
      }
      promises.push(
        axios.get(
          `https://api.coingecko.com/api/v3/coins/${key}/market_chart?vs_currency=usd&days=14`
        )
      );
      return null;
    });

    Promise.all(promises)
      .then(
        axios.spread((...res) => {
          if (isMounted) setChartData(res);
        })
      )
      .catch(() => { });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={styles.trade}>
      <div className={styles.table}>
        <div className={styles.headerRow}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>24h %</div>
          <div className={styles.col}>7d %</div>
          <div className={styles.col}>
            Marketcap <Icon name="coin" size="20" />
          </div>
          <div className={styles.col}>
            Volume (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>Chart (14d)</div>
        </div>
      </div>

      <div className={styles.table}>
        {coinGeckoMarketData &&
          chartData &&
          coinGeckoMarketData.length > 0 &&
          chartData.length > 0 &&
          coinGeckoMarketData.map((x, index) => (
            <Link
              to={tokenPairs[x.data.id].url}
              className={styles.row}
              key={index}
            >
              <div className={styles.col}>
                <div className={styles.line}>{index + 1}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <img
                      alt="Avatar"
                      src={`/images/tokens/${x.data.symbol.toUpperCase()}.png`}
                    />
                  </div>
                  <div className={styles.details}>
                    <span className={styles.subtitle}>{x.data.name}</span>
                    <span className={styles.currency}>
                      {x.data.symbol.toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Price</div>
                {numberWithCommas(x.data.market_data.current_price.usd)}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>24h</div>
                <div
                  className={
                    x.data.market_data.price_change_percentage_24h > 0
                      ? styles.positive
                      : styles.negative
                  }
                >
                  {`${x.data.market_data.price_change_percentage_24h.toFixed(
                    2
                  )}%`}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>7d</div>
                <div
                  className={
                    x.data.market_data.price_change_percentage_7d > 0
                      ? styles.positive
                      : styles.negative
                  }
                >
                  {`${x.data.market_data.price_change_percentage_7d.toFixed(2)}%`}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Marketcap</div>
                {numberWithCommas(x.data.market_data.market_cap.usd)}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Volume (24h)</div>
                {numberWithCommas(x.data.market_data.total_volume.usd)}
              </div>
              <div className={styles.col}>
                <MiniPriceChart
                  key={index}
                  positive={x.data.market_data.price_change_percentage_7d > 0}
                  prices={chartData[index].data.prices}
                />
              </div>
              {/* <Link
                className={cn("button-stroke button-small", styles.button)}
                to={tokenPairs[x.data.id].url}
              >
                Buy
              </Link> */}
            </Link>
            // </Link>
          ))}
      </div>
    </div>
  );
};

export default ExploreMarketList;
