type TokenPair = {
  title: string;
  currency: string;
  url: string;
};
export const tokenPairs: { [key: string]: TokenPair } = {
  // bitcoin: {
  //   title: "Bitcoin",
  //   currency: "BTC",
  //   url: "/trade/BTC-USDC",
  // },
  // ethereum: {
  //   title: "Ethereum",
  //   currency: "ETH",
  //   url: "/trade/ETH-USDC",
  // },
  // solana: {
  //   title: "Solana",
  //   currency: "SOL",
  //   url: "/trade/SOL-USDC",
  // },
  // "avalanche-2": {
  //   title: "Avalanche",
  //   currency: "AVAX",
  //   url: "/trade/AVAX-USDC",
  // },
  // near: {
  //   title: "NEAR Protocol",
  //   currency: "NEAR",
  //   url: "/trade/NEAR-USDC",
  // },
  cosmos: {
    title: "Cosmos Hub",
    currency: "ATOM",
    url: "/trade/ATOM-UST2",
  },
  // osmosis: {
  //   title: "Osmosis",
  //   currency: "OSMO",
  //   url: "/trade/OSMO-USDC",
  // },
  // sei: {
  //   title: "USDC",
  //   currency: "USDC",
  //   url: "/trade/USDC-SEI",
  // },
};

type Market = {
  currency?: string;
  symbol: string;
  name: string;
  coinGeckoAPIKey: string;
};

export const markets: { [key: string]: Market } = {
  "BTC-USDC": {
    currency: "BTC",
    symbol: "BINANCE:BTCUSDC",
    name: "Bitcoin Perpetual",
    coinGeckoAPIKey: "bitcoin",
  },
  "ETH-USDC": {
    currency: "ETH",
    symbol: "BINANCE:ETHUSDC",
    name: "Ethereum Perpetual",
    coinGeckoAPIKey: "ethereum",
  },
  "SOL-USDC": {
    symbol: "BINANCE:SOLUSDC",
    name: "Solana Perpetual",
    coinGeckoAPIKey: "solana",
  },
  // "AVAX-USDC": {
  //   currency: "AVAX",
  //   symbol: "KUCOIN:AVAXUSDC",
  //   name: "Avalanche Perpetual",
  //   coinGeckoAPIKey: "avalanche-2",
  // },
  // "NEAR-USDC": {
  //   currency: "NEAR",
  //   symbol: "BINANCE:NEARUSD",
  //   name: "Near Protocol Perpetual",
  //   coinGeckoAPIKey: "near",
  // },
  "ATOM-UST2": {
    currency: "ATOM",
    symbol: "BINANCE:ATOMUSDC",
    name: "Cosmos Hub Perpetual",
    coinGeckoAPIKey: "cosmos",
  },
  "OSMO-USDC": {
    currency: "OSMO",
    symbol: "COINEX:OSMOUSDT",
    name: "Osmosis Perpetual",
    coinGeckoAPIKey: "osmosis",
  },
  "USDC-SEI": {
    currency: "USDC",
    symbol: "",
    name: "USDC Perpetual",
    coinGeckoAPIKey: "",
  },
};
