import cn from "classnames";
import { numberWithCommas } from "../../../utils";
import AssetBalances from "../AssetBalances";
import styles from "./PortfolioSummary.module.sass";

const PortfolioSummary = (props) => {
  const { keplrWalletAddress, accountInfo } = props;

  return (
    <div>
      <div className={styles.line}>
        <h4 className={cn("h4", styles.text)}>Portfolio Summary</h4>
      </div>
      <div className={styles.details}>
        <div className={styles.info}>Total balance</div>
        {keplrWalletAddress && accountInfo && accountInfo.buying_power ? (
          <>
            <div className={styles.balance}>
              <div className={styles.number}>
                {accountInfo.buying_power.negative && `-`}
                {numberWithCommas(accountInfo.balance.decimal)}
              </div>
              <div className={styles.currency}>USD</div>
            </div>
            {/* <div className={styles.price}>
              {accountInfo.buying_power &&
                accountInfo.unrealized_pnl.negative &&
                `-`}
              {`$${accountInfo.unrealized_pnl.toFixed(2)}`} (
              {accountInfo.unrealized_pnl.negative && `-`}
              {`${accountInfo.unrealized_pnl.percentage}`}
              %)
            </div> */}
          </>
        ) : (
          <>
            <div className={styles.balance}>
              <div className={styles.number}>$0.00</div>
              <div className={styles.currency}>USD</div>
            </div>
            {/* <div className={styles.price}>$0.00 (0.00%)</div> */}
          </>
        )}

        <AssetBalances />
      </div>
    </div>
  );
};

export default PortfolioSummary;
