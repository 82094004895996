import React from "react";

type ImageProps = {
  className?: string;
  size?: number;
  src?: string;
  srcDark?: string;
  srcSet?: string;
  srcSetDark?: string;
  alt?: string;
  darkMode?: boolean;
};
const Image = ({
  className,
  size,
  src,
  srcDark,
  srcSet,
  srcSetDark,
  alt,
  darkMode = true,
}: ImageProps) => {
  return (
    <img
      className={className}
      width={size}
      height={size}
      srcSet={darkMode ? srcSetDark : srcSet}
      src={darkMode ? srcDark : src}
      alt={alt}
    />
  );
};

export default Image;
