import React, { useState, useEffect } from "react";
import useSettlements from "../../../context/hooks/useSettlements";
import useTrades from "../../../context/hooks/useTrades";
import { useUrl } from "../../../context/hooks";
import styles from "./Trades.module.sass";

const Trades = () => {
  const { parseFetchedTrades } = useTrades();
  const { getSettlementsByMarket } = useSettlements();
  const { getExchangeDenoms } = useUrl();

  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchTrades = async () => {
      const settlements = await getSettlementsByMarket();
      const parsedTrades = parseFetchedTrades(settlements);
      if (isMounted) setTrades(parsedTrades);
    };
    fetchTrades().then();
    const interval = setInterval(fetchTrades, 60000);

    return () => {
      clearInterval(interval);
      isMounted = false;
    };
  }, []);

  const renderTrades = () => {
    if (!trades) return null;
    if (trades.length === 0) return <div>No trades</div>;

    return trades.map((trade, index) => (
      <div className={styles.row} key={index}>
        <div className={styles.col}>
          <div
            className={
              trade.positiondirection === "Long"
                ? styles.positive
                : styles.negative
            }
          >
            {trade.price}
          </div>
        </div>
        <div className={styles.col}>{trade.quantity}</div>
        <div className={styles.col}>{trade.timestamp}</div>
      </div>
    ));
  };

  return (
    <div className={styles.trades}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Price ({priceDenomString})</div>
          <div className={styles.col}>Amount ({assetDenomString})</div>
          <div className={styles.col}>Time</div>
        </div>
        {renderTrades()}
      </div>
    </div>
  );
};

export default Trades;
