import { createContext } from "react";

type AutoConnectContext = {
  autoConnect: { [key: string]: boolean };
  setAutoConnect: (value: { [key: string]: boolean }) => void;
};

export default createContext<AutoConnectContext>({
  autoConnect: {},
  setAutoConnect: () => {},
});
