import { OrderType } from "./../../screens/Exchange/Table/OpenOrders/types";
/* eslint-disable no-console */
import axios from "axios";
import offchainURL from "../constants/offchainURL";

const useOpenOrders = () => {
  const getOpenOrders = async (accountAddr: string) => {
    let result: OrderType[] = [];
    try {
      await axios
        .get(`${offchainURL}/orders/${accountAddr}`)
        .then((results) => {
          result =
            results?.data?.length > 0
              ? results.data.sort((a: any, b: any) => b.id - a.id)
              : [];
        });

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return { getOpenOrders };
};

export default useOpenOrders;
