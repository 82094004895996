import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { formatDataForChart } from "../../utils";
import styles from "./MiniPriceChart.module.sass";

const MiniPriceChart = (props) => {
  const { positive, prices, isPanel } = props;

  return (
    <div className={isPanel ? styles.panelChart : styles.chart}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={formatDataForChart(prices)}
          margin={{
            top: 3,
            right: 0,
            left: 0,
            bottom: 3,
          }}
        >
          <defs>
            <linearGradient id="greenColorPrice" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#45B36B" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#45B36B" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="redColorPrice" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF6838" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#FF6838" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="price"
            stroke={positive ? "url(#greenColorPrice)" : "url(#redColorPrice)"}
            fillOpacity={1}
            fill={positive ? "url(#greenColorPrice)" : "url(#redColorPrice)"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MiniPriceChart;
