import cn from "classnames";
import ExploreMarketList from "../../Exchange/ExploreMarketList";
import styles from "./Market.module.sass";

const Market = () => (
  <div className={cn("section", styles.details)}>
    <div className={cn("container", styles.container)}>
      <ExploreMarketList />
    </div>
  </div>
);

export default Market;
