import React, { useState } from "react";
import cn from "classnames";
import { useRecoilState } from "recoil";
import Action from "../Actions/Form/Action";
import "react-toastify/dist/ReactToastify.css";
import { placeTradeState } from "../../../recoil/atoms";
import styles from "./PlaceTrade.module.sass";

const navigation = ["Market", "Limit", "Stop"];
const direction = ["Buy", "Sell"];

const PlaceTrade = (props) => {
  const {
    notifyOnSubmit,
    onTradeCloseModal,
    assetDenomString,
    priceDenomString,
  } = props;

  const [placeTrade, setPlaceTrade] = useRecoilState(placeTradeState);

  const [visible, setVisible] = useState(false);

  const handleClickBuy = () => {
    setVisible(true);
    setPlaceTrade({
      ...placeTrade,
      orderDirection: 0,
    });
  };

  const handleClickSell = () => {
    setVisible(true);
    setPlaceTrade({
      ...placeTrade,
      orderDirection: 1,
    });
  };

  const handleToggleMarketType = (index) => {
    setPlaceTrade({
      ...placeTrade,
      orderType: index,
    });
  };

  return (
    <div className={styles.placeTrade}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.button, "tabSelect tooltip thirds", {
              active: index === placeTrade.orderType,
            })}
            disabled={index === 2}
            onClick={() => handleToggleMarketType(index)}
            key={index}
          >
            {x}
            {index > 1 && (
              <span className={cn("tooltip-text", styles.button)}>
                Coming Soon
              </span>
            )}
          </button>
        ))}
      </div>
      <div className={styles.content}>
        <div className={styles.btns}>
          <button
            className={cn(styles.buttonBuy, {
              [styles.active]: placeTrade.orderDirection === 0,
            })}
            onClick={() => handleClickBuy()}
          >
            Buy
          </button>
          <button
            className={cn(styles.buttonSell, {
              [styles.active]: placeTrade.orderDirection === 1,
            })}
            onClick={() => handleClickSell()}
          >
            Sell
          </button>
        </div>
        <div className={cn(styles.wrapper, { [styles.show]: visible })}>
          <Action
            onTradeCloseModal={onTradeCloseModal}
            notifyOnSubmit={notifyOnSubmit}
            priceDenomString={priceDenomString}
            assetDenomString={assetDenomString}
            classButton="button-green"
            buttonText={`Confirm ${navigation[placeTrade.orderType]} ${direction[placeTrade.orderDirection]
              }`}
          />
        </div>
      </div>
    </div>
  );
};

export default PlaceTrade;
