/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  denoms,
  baseDenom,
  orderType,
  positionEffect,
  useQueryContract,
  WalletContext,
  useUrl,
} from "../../../context";
import usePlaceTrade from "../../../context/hooks/usePlaceTrade";
import { accountState, openOrdersState } from "../../../recoil/atoms";
import { accountBalanceState } from "../../../recoil/atoms/balance";
import { openPositionsState } from "../../../recoil/atoms/openPositions";
import {
  fetchAccountBalanceState,
  fetchAccountInfo,
  fetchOpenOrdersState,
  pairOrderBookState,
} from "../../../recoil/selectors";
import { fetchOpenPositionsState } from "../../../recoil/selectors/openPositions";
import { getOrderPricing } from "../../../utils/transaction";
import Image from "../../Image";
import ConfirmClosePosition from "../ConfirmClosePosition";
import styles from "./Action.module.sass";

const Action = ({
  marketPosition,
  onTradeCloseModal,
  notifyOnSubmit,
  priceDenomString,
  assetDenomString,
  orderDirection,
  classButton,
  buttonText,
}: any) => {
  const walletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];

  const queryAccountInfo = useRecoilValue(fetchAccountInfo(walletAddress));
  const setAccountInfo = useSetRecoilState(accountState);

  const queryOpenOrdersInfo = useRecoilValue(
    fetchOpenOrdersState(walletAddress)
  );
  const setOpenOrders = useSetRecoilState(openOrdersState);

  const queryOpenPositionsInfo = useRecoilValue(
    fetchOpenPositionsState(walletAddress)
  );
  const setOpenPositions = useSetRecoilState(openPositionsState);

  const setAccountBalanceState = useSetRecoilState(accountBalanceState);
  const queryAccountBalanceState = useRecoilValue(
    fetchAccountBalanceState(walletAddress)
  );
  const [marketPrice, setMarketPrice] = useState("");

  const { pair } = useUrl();

  const pairOrderBook = useRecoilValue(
    pairOrderBookState(assetDenomString + "-" + priceDenomString)
  );

  useEffect(() => {
    if (
      pairOrderBook &&
      pairOrderBook.bestLongPrice &&
      pairOrderBook.bestShortPrice
    ) {
      const price =
        orderDirection === 0
          ? Number(pairOrderBook.bestLongPrice).toFixed(6)
          : Number(pairOrderBook.bestShortPrice).toFixed(6);
      setMarketPrice(price);
    }
  }, [pairOrderBook]);

  const { getOrderFeeEstimate } = useQueryContract();
  const [orderFeeEstimate, setOrderFeeEstimate] = useState(0.0);

  const [errors, setErrors] = useState({ amount: "" });
  const { openOrder } = usePlaceTrade();

  const [orderQuantity, setOrderQuantity] = useState(
    orderDirection === 0
      ? marketPosition?.long_position?.decimal
      : marketPosition?.short_position?.decimal
  );
  const leverage = 1;

  const handleSubmit = () => {
    // Basically to buy or sell at any price (market order),
    // we need to set price to tolerate all possible prices, so we give it the worst price in orderbook
    const marketOrderPrice = pairOrderBook
      ? orderDirection === 0
        ? pairOrderBook.worstLongPrice
        : pairOrderBook.worstShortPrice
      : 1.0;

    const assetDenom = denoms[assetDenomString];
    openOrder(
      orderType.MARKET,
      orderDirection ? 0 : 1,
      marketOrderPrice,
      orderQuantity,
      baseDenom,
      assetDenom,
      leverage,
      positionEffect.CLOSE
    ).then((tx) => {
      if (tx !== undefined && tx.transactionHash !== undefined)
        notifyOnSubmit(tx);
      queryOpenPositionsInfo().then(setOpenPositions);
      queryAccountInfo().then(setAccountInfo);
      queryAccountBalanceState().then(setAccountBalanceState);
      queryOpenOrdersInfo().then(setOpenOrders);
    });

    onTradeCloseModal(false);
  };

  useEffect(() => {
    const calculateEstimate = async () => {
      if (walletAddress && orderQuantity > 0 && marketPrice && leverage) {
        const priceDenom = denoms[priceDenomString];
        const assetDenom = denoms[assetDenomString];
        const estimate = await getOrderFeeEstimate(
          walletAddress,
          orderType.MARKET,
          orderDirection === 0 ? 1 : 0, // To close position, place opposite order
          Number(marketPrice),
          orderQuantity,
          priceDenom,
          assetDenom,
          leverage
        );
        if (estimate && estimate.order_fee_estimate) {
          setOrderFeeEstimate(Number(estimate.order_fee_estimate.decimal));
        }
      }
    };
    calculateEstimate();
  }, [walletAddress, marketPrice, marketPosition]);

  const { total, orderPrice, fee } = getOrderPricing(
    Number(marketPrice),
    orderQuantity,
    orderFeeEstimate
  );

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>Price</div>
        <div className={styles.tip}>(Price determined by market)</div>)
      </div>

      <label className={styles.disabled}>
        <input
          className={styles.input}
          type="number"
          name="price"
          onWheel={(e: any) => e.target.blur()}
          value={marketPrice}
          autoComplete="off"
          required
          disabled
        />
        <div className={styles.currency}>
          {
            <Image
              size={24}
              srcDark={`/images/tokens/${priceDenomString}.png`}
            />
          }
          {priceDenomString}
        </div>
      </label>

      <div className={styles.row}>
        <div className={styles.label}>Order Amount</div>
        <div className={styles.tip}>(Set Size Order)</div>
      </div>
      <label className={styles.field}>
        <input
          className={styles.input}
          type="number"
          autoComplete="off"
          name="amount"
          step=".000001"
          onFocus={(e) => {
            e.target.select();
          }}
          onWheel={(e: any) => e.target.blur()}
          value={orderQuantity}
          onChange={(e) => setOrderQuantity(e.target.value)}
          required
        />
        <div className={styles.currency}>
          {
            <Image
              size={24}
              srcDark={`/images/tokens/${assetDenomString}.png`}
            />
          }
          {assetDenomString}
        </div>
      </label>
      {errors.amount && (
        <div className={styles.row}>
          <div className={styles.error}>{errors.amount}</div>
        </div>
      )}
      <ConfirmClosePosition
        onClick={handleSubmit}
        classButton={classButton}
        buttonText={buttonText}
        orderQuantity={orderQuantity}
      />
    </>
  );
};

export default Action;
