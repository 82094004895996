import { useState } from "react";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown";
import styles from "./Table.module.sass";
import MarketPositions from "./MarketPositions";
import OpenOrders from "./OpenOrders";
import TradeHistory from "./TradeHistory";

const navigation = ["Positions", "Orders", "Trade History"];

const Table = () => {
  const [activeIndex, setActiveIndex] = useState(navigation[0]);

  const renderPage = () => {
    switch (activeIndex) {
      case "Positions":
        return <MarketPositions />;
      case "Orders":
        return (
          <OpenOrders
            pageLength={20}
            tableContentClassName={styles.tableContent}
          />
        );
      case "Trade History":
        return <TradeHistory />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.inner}>
      {/* <Dropdown
        className={styles.dropdown}
        value={activeIndex}
        setValue={setActiveIndex}
        options={navigation}
      /> */}
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.button, "tabSelect", {
              active: x === activeIndex,
            })}
            onClick={() => setActiveIndex(navigation[index])}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {renderPage()}
    </div>
  );
};

export default Table;
