import React, { useState } from "react";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { markets } from "../../context/constants/tokenPairs.ts";
import { useUrl } from "../../context";
import { openInNewTab } from "../../utils";
import styles from "./Exchange.module.sass";
import Main from "./Main";
import Balance from "./Balance";
import Account from "./Account";
import Table from "./Table";
import Charts from "./Charts";
import PlaceTrade from "./PlaceTrade";
import ToggleMarket from "./ToggleMarket";

const navigation = ["Chart", "Orderbook", "Account", "Place Trade"];

const Exchange = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [toggleSelectMarket, setToggleSelectMarket] = useState(false);
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();

  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  const notifyOnSubmit = async (tx) => {
    if (tx !== undefined && tx.transactionHash !== undefined) {
      const txExploreURL = `https://testnet-explorer.brocha.in/sei%20atlantic%202/tx/${tx.transactionHash}`;
      toast(
        <div>
          🌊 View the status in the Orders tab
          <div
            className={styles.exploreLink}
            onClick={() => openInNewTab(txExploreURL)}
          >
            <span>View on Explorer</span>
          </div>
        </div>
      );
    } else {
      toast(<div>🚫 Order was not placed</div>);
    }
  };

  return (
    <div className={styles.exchange}>
      <Main
        pair={pair}
        name={markets[pair].name}
        toggleSelectMarket={toggleSelectMarket}
        setToggleSelectMarket={setToggleSelectMarket}
      />
      <div className={styles.content}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isTablet ? (
          <div className={styles.balanceWrapper}>
            {toggleSelectMarket ? (
              <ToggleMarket
                pairs={markets}
                closeDropdown={() => setToggleSelectMarket(false)}
              />
            ) : (
              <>
                {activeIndex === 0 && (
                  <div className={styles.box}>
                    <Charts
                      tradingViewSymbol={
                        pair in markets
                          ? markets[pair].symbol
                          : "BINANCE:BTCUSDC"
                      }
                    />
                    <Table />
                  </div>
                )}
                {activeIndex === 1 && <Balance />}
                {activeIndex === 2 && (
                  <div className={styles.box}>
                    <Account />
                  </div>
                )}
                {activeIndex === 3 && (
                  <div className={styles.box}>
                    <PlaceTrade
                      notifyOnSubmit={notifyOnSubmit}
                      assetDenomString={assetDenomString}
                      priceDenomString={priceDenomString}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={styles.flexContainer}>
            <div className={styles.balance}>
              {toggleSelectMarket ? (
                <ToggleMarket
                  pairs={markets}
                  closeDropdown={() => setToggleSelectMarket(false)}
                />
              ) : (
                <Balance />
              )}
            </div>
            <div className={styles.chartContainer}>
              <div className={styles.chartGraphTable}>
                <div className={styles.chart}>
                  <Charts
                    tradingViewSymbol={
                      pair in markets ? markets[pair].symbol : "BINANCE:BTCUSDC"
                    }
                  />
                </div>
                <div className={styles.table}>
                  <Table />
                </div>
              </div>
              <div className={styles.account}>
                <Account />
                <PlaceTrade
                  notifyOnSubmit={notifyOnSubmit}
                  assetDenomString={assetDenomString}
                  priceDenomString={priceDenomString}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Exchange;
