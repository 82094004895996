import React, { useState } from "react";
import Modal from "../Modal";
import WalletConnectModal from "../WalletConnectModal";
import styles from "./WalletConnectSection.module.sass";

const WalletConnectSection = (props) => {
  const [visibleWalletConnectModal, setVisibleWalletConnectModal] =
    useState(false);

  const maxWidth = { props };

  return (
    <div style={{ maxWidth }}>
      <div className={styles.connectInnerBox}>
        <h4 className={styles.message}>
          Connect to any Cosmo’s wallet to fund your account and start trading.
        </h4>
        <button
          className={styles.connectWalletButton}
          onClick={() => setVisibleWalletConnectModal(true)}
        >
          Connect Wallet
        </button>
      </div>
      <Modal
        classname={styles.modal}
        visible={visibleWalletConnectModal}
        onClose={() => setVisibleWalletConnectModal(false)}
      >
        <WalletConnectModal
          onConnectCloseModal={() => setVisibleWalletConnectModal(false)}
        />
      </Modal>
    </div>
  );
};

export default WalletConnectSection;
