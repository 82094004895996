import React from "react";
import cn from "classnames";
import "react-toastify/dist/ReactToastify.css";
import Action from "./Action";
import styles from "./ClosePositionModal.module.sass";

const ClosePositionModal = (props: any) => {
  const {
    notifyOnSubmit,
    onTradeCloseModal,
    assetDenomString,
    priceDenomString,
    orderDirection,
    marketPosition,
  } = props;

  return (
    <div className={styles.closePosition}>
      <div className={styles.title}>Close Position</div>
      <div className={styles.btns}>
        <button
          className={cn(styles.buttonBuy, {
            [styles.active]: orderDirection === 1,
          })}
          disabled
        >
          Buy
        </button>
        <button
          className={cn(styles.buttonSell, {
            [styles.active]: orderDirection === 0,
          })}
          disabled
        >
          Sell
        </button>
      </div>
      <div className={cn(styles.wrapper, { [styles.show]: true })}>
        <Action
          orderDirection={orderDirection}
          marketPosition={marketPosition}
          onTradeCloseModal={onTradeCloseModal}
          notifyOnSubmit={notifyOnSubmit}
          priceDenomString={priceDenomString}
          assetDenomString={assetDenomString}
          classButton="button-green"
          buttonText="Close Position"
        />
      </div>
    </div>
  );
};

export default ClosePositionModal;
