import React from "react";
import cn from "classnames";
import styles from "./Checkbox.module.sass";

const Checkbox = ({ className, content, note, value, onChange }) => (
  <label className={cn(styles.checkbox, className)}>
    <input
      className={styles.input}
      type="checkbox"
      onChange={onChange}
      checked={value}
    />
    <span className={styles.inner}>
      <span className={styles.tick} />
      <span
        className={styles.text}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {note && <span className={styles.note}>{note}</span>}
    </span>
  </label>
);

export default Checkbox;
