import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { oraclePricesState } from "../../recoil/atoms/oraclePrices";
import { contractAddr } from "../constants";
import minimalDenom from "../constants/minimalDenom";
import offchainURL from "../constants/offchainURL";
import { OraclePriceOnchainType, OraclePriceType } from "../types";
import useClient from "./useClient";
import useUrl from "./useUrl";

const useGetOraclePrices = () => {
  const { getQueryClient } = useClient();
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [oraclePrices, setOraclePrices] = useRecoilState(oraclePricesState);
  let interval: NodeJS.Timer | undefined;

  const getOracleExchangeRate = async () => {
    let result: OraclePriceType = {} as OraclePriceType;

    try {
      await axios
        .get(
          `${offchainURL}/oraclePrice/${contractAddr}-${minimalDenom[assetDenomString]}`
        )
        .then((results) => {
          result = results.data[0];
        });
      return result;
    } catch (error) {
      console.error(error);
      return result;
    }
  };

  const getOracleExchangeRatesOnchain = async () => {
    try {
      const client = await getQueryClient();
      const oracleResponse =
        await client.seiprotocol.seichain.oracle.exchangeRates();
      oracleResponse.denom_oracle_exchange_rate_pairs[0].oracle_exchange_rate
        .exchange_rate;
      const mappedExchangeRatePairs =
        oracleResponse.denom_oracle_exchange_rate_pairs?.map((exchangeRate) => {
          return {
            denom: exchangeRate.denom,
            exchange_rate: parseFloat(
              exchangeRate.oracle_exchange_rate.exchange_rate
            ),
            last_update: parseFloat(
              exchangeRate.oracle_exchange_rate.last_update
            ),
          };
        }) || [];

      return mappedExchangeRatePairs.reverse();
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  useEffect(() => {
    if (interval) clearInterval(interval);

    getOracleExchangeRate().then((exchangeRate) => {
      setOraclePrices({
        ...oraclePrices,
        [minimalDenom[assetDenomString]]: exchangeRate,
      });
    });

    interval = setInterval(() => {
      if (!pair) return;

      getOracleExchangeRate().then((exchangeRate) => {
        setOraclePrices({
          ...oraclePrices,
          [minimalDenom[assetDenomString]]: exchangeRate,
        });
      });
    }, 60000);
    return () => clearInterval(interval);
  }, [pair]);
};

export default useGetOraclePrices;
