import { atom } from "recoil";
import { OraclePriceType } from "../../context/types";

export type OraclePrices = {
  [pair: string]: OraclePriceType;
};

export const oraclePricesState = atom<OraclePrices>({
  key: "oraclePricesState",
  default: {},
});
