import React, { useState, useContext, useEffect } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import Image from "../Image";
import Modal from "../Modal";
import { AutoConnectContext, WalletContext } from "../../context";
import WalletConnectModal from "../WalletConnectModal";
import copyToClipboard from "../../utils/clipboard";
import { openInNewTab, truncateAddress } from "../../utils";
import Icon from "../Icon";
import Dropdown from "./Dropdown";
import styles from "./Header.module.sass";
import { NAVIGATION_ITEMS } from "./config";
import { HeaderProps } from "./types";
import OutsideClickHandler from "react-outside-click-handler";

const Header = ({ headerWide }: HeaderProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [visibleNav, setVisibleNav] = useState(false);
  const [visibleWalletConnect, setVisibleWalletConnect] = useState(false);
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const { autoConnect, setAutoConnect } = useContext(AutoConnectContext);
  const { setWallet } = useContext(WalletContext);

  const { cosmosAccounts } = useContext(WalletContext).wallet || {
    cosmosAccounts: [],
  };

  useEffect(() => {
    if (recentlyCopied) {
      setTimeout(() => setRecentlyCopied(false), 1200);
    }
  }, [recentlyCopied]);

  const loggedInButtons = () => {
    const firstAddress =
      cosmosAccounts && cosmosAccounts.length > 0 ? cosmosAccounts[0] : [];

    const copyAddress = () => {
      copyToClipboard(firstAddress);
      setShowMenu(false);
      setRecentlyCopied(true);
    };

    const onClickDisconnect = () => {
      setAutoConnect({ ...autoConnect, keplr: false, leap: false });
      setWallet({
        cosmosAccounts: [],
        cosmosSigner: null,
        metamaskAccounts: [],
      });
    };

    const renderMenu = () => {
      if (!showMenu) return null;

      return (
        <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
          <div className={styles.menu}>
            <button className={styles.menuButton} onClick={copyAddress}>
              <Icon
                className={styles.icon}
                name="copyAlt"
                size="24"
                fill="#f1f1f1"
              />
              <span>Copy</span>
            </button>
            <button
              onClick={() => {
                openInNewTab(
                  `https://testnet-explorer.brocha.in/sei%20atlantic%202/account/${firstAddress}`
                );
                setShowMenu(false);
              }}
              className={styles.menuButton}
            >
              <Icon
                className={styles.icon}
                name="explorer"
                size="24"
                fill="#f1f1f1"
              />
              <span>Explorer</span>
            </button>
            <button className={styles.menuButton} onClick={onClickDisconnect}>
              <Icon
                className={styles.icon}
                name="closeAlt"
                size="24"
                fill="#f1f1f1"
              />
              <span>Disconnect</span>
            </button>
          </div>
        </OutsideClickHandler>
      );
    };

    const text = recentlyCopied
      ? "Copied!"
      : cosmosAccounts?.length > 0
      ? truncateAddress(firstAddress)
      : "";

    return (
      <div className={styles.control}>
        <button
          onClick={() => setShowMenu(!showMenu)}
          className={cn("button-stroke button-small tooltip", styles.button)}
        >
          {text}
          <Icon
            className={styles.chevron}
            name={showMenu ? "upChevron" : "downChevron"}
            size="12"
            viewSize="24"
            fill="#f1f1f1"
          />
        </button>
        {renderMenu()}
      </div>
    );
  };

  const loginButtons = () => (
    <div className={styles.control}>
      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => setVisibleWalletConnect(true)}
      >
        Connect Wallet
      </button>
    </div>
  );

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <a
          className={styles.logo}
          href="https://vortexprotocol.io"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.png"
            alt="Vortex"
          />
          <img className={styles.picMobile} src="/logo.png" alt="Vortex" />
        </a>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            <nav className={styles.nav}>
              {NAVIGATION_ITEMS.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.active]:
                        x.urls.filter(
                          (url) =>
                            url.replace("/", "") ===
                            window.location.pathname.split("/")[1]
                        ).length > 0,
                    })}
                    onClick={() => setVisibleNav(false)}
                    to={x.urls[0]}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              )}
            </nav>
          </div>
          {cosmosAccounts && cosmosAccounts.length > 0
            ? loggedInButtons()
            : loginButtons()}
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          />
          <Modal
            outerClassName={styles.modal}
            visible={visibleWalletConnect}
            onClose={() => setVisibleWalletConnect(false)}
          >
            <WalletConnectModal
              onConnectCloseModal={() => setVisibleWalletConnect(false)}
            />
          </Modal>
        </div>
      </div>
    </header>
  );
};

export default Header;
