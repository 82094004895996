import React from "react";
import cn from "classnames";
import Image from "../Image";
import styles from "./Currency.module.sass";
import { CurrencyProps } from "./types";

const Currency = ({ symbol, name, type }: CurrencyProps) => (
  <>
    <Image size={24} srcDark={`/images/tokens/${symbol}.png`} />
    <div className={styles.nameAndSymbol}>
      <div className={styles.selection}>
        {type === "symbol" ? symbol : name}
      </div>
      {!type && (
        <button className={cn("currency", styles.button)}>{symbol}</button>
      )}
    </div>
  </>
);

export default Currency;
