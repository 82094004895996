import React, { useState } from "react";
import { LeverageBarProps } from "./types";
import styles from "./LeverageBar.module.sass";
import cn from "classnames";

const LeverageBar = ({ value, setter }: LeverageBarProps) => {
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const stepPrice = 0.5;
  const minPrice = 1;
  const maxPrice = 4;

  const renderContent = () => {
    const renderValue = () => {
      return (
        <label className={styles.valueRow}>
          <input
            className={styles.value}
            type="number"
            autoComplete="off"
            name="amount"
            step=".5"
            max={4}
            onFocus={(e) => e.target.select()}
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            value={value}
            onChange={(e) =>
              setter(
                parseFloat(e.target.value) > 4
                  ? 4
                  : parseFloat(e.target.value) < 0
                  ? 0
                  : e.target.value
              )
            }
            required
          />
          <div className={styles.placeholder}>x</div>
        </label>
      );
    };
    const renderInput = () => {
      if (showSlider) {
        return (
          <input
            type="range"
            min={minPrice}
            max={maxPrice}
            className={styles.slider}
            defaultValue={value}
            onChange={(e) => setter(e.target.value)}
            step={stepPrice}
          />
        );
      }

      const leveragePresets = [2, 3, 4];

      return (
        <div className={styles.presets}>
          {leveragePresets.map((preset, i) => {
            return (
              <div
                key={i}
                className={styles.presetLeverage}
                onClick={() => setter(preset)}
              >
                {preset} x
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <div className={styles.container}>
        {renderValue()}
        {renderInput()}
      </div>
    );
  };
  const renderWarning = () => {
    return (
      <>
        {value > 1 && (
          <label className={styles.warning}>
            <div className={styles.warningText}>
              Leveraged trading carries the risk of liquidation. Visit the docs
              for more information.
            </div>
          </label>
        )}
      </>
    );
  };

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.info}>
          <div className={styles.label}>Leverage</div>
          <div className={styles.tip}>(Up to 4x)</div>
        </div>
        <div className={styles.info}>
          <div className={styles.switchLabel}>Slider</div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={showSlider}
              onChange={(e) => setShowSlider(e.target.checked)}
            />
            <span className={styles.checkbox} />
          </label>
        </div>
      </div>
      {renderContent()}
      {renderWarning()}
    </div>
  );
};

export default LeverageBar;
