import React, { useState, useMemo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { RecoilRoot } from "recoil";
import { CoinGeckoContext } from "./context/CoinGeckoContext";
import { WalletContext, AutoConnectContext } from "./context";
import { tokenPairs } from "./context/constants/tokenPairs.ts";
import "./styles/app.sass";
import Page from "./components/Page";
import Exchange from "./screens/Exchange";
import WalletOverview from "./screens/WalletOverview";
import Bridge from "./screens/Bridge";
import { useAutoWalletConnect } from "./context/hooks";
import Orders from "./screens/Orders";
import Positions from "./screens/Positions";
import History from "./screens/History";

const App = () => {
  const [wallet, setWallet] = useState({
    cosmosAccounts: [],
    cosmosSigner: null,
    metamaskAccounts: [],
    metamaskSigner: null,
  });
  const walletProviderValue = useMemo(
    () => ({ wallet, setWallet }),
    [wallet, setWallet]
  );
  const [coinGeckoMarketData, setCoinGeckoMarketData] = useState(null);
  const coinGeckoMarketProviderValue = useMemo(
    () => ({ coinGeckoMarketData, setCoinGeckoMarketData }),
    [coinGeckoMarketData, setCoinGeckoMarketData]
  );

  const autoWalletConnectValue = useAutoWalletConnect(walletProviderValue);

  useEffect(() => {
    const promises = [];
    Object.keys(tokenPairs).map((key) => {
      if (key === "sei") {
        return null;
      }
      promises.push(
        axios.get(
          `https://api.coingecko.com/api/v3/coins/${key}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
        )
      );
      return null;
    });
    Promise.all(promises)
      .then(
        axios.spread((...res) => {
          setCoinGeckoMarketData(res);
        })
      )
      .catch(() => { });
  }, []);

  return (
    <Router>
      <RecoilRoot>
        <WalletContext.Provider value={walletProviderValue}>
          <AutoConnectContext.Provider value={autoWalletConnectValue}>
            <CoinGeckoContext.Provider value={coinGeckoMarketProviderValue}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Page headerWide footerHide>
                      <WalletOverview />
                    </Page>
                  )}
                />
                <Redirect exact from="/trade" to="/trade/ATOM-UST2" />
                <Route
                  path="/trade/ATOM-UST2"
                  render={() => (
                    <Page headerWide footerHide>
                      <Exchange />
                    </Page>
                  )}
                />
                <Redirect from="/trade/*" to="/trade/ATOM-UST2" />
                <Route
                  exact
                  path="/portfolio"
                  render={() => (
                    <Page headerWide footerHide>
                      <WalletOverview />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/orders"
                  render={() => (
                    <Page headerWide footerHide>
                      <Orders />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/positions"
                  render={() => (
                    <Page headerWide footerHide>
                      <Positions />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/history"
                  render={() => (
                    <Page headerWide footerHide>
                      <History />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/bridge"
                  render={() => (
                    <Page headerWide footerHide>
                      <Bridge />
                    </Page>
                  )}
                />
                {/* <Route
                exact
                path="/lite"
                render={() => (
                  <Page>
                    <Lite />
                  </Page>
                )}
              /> */}
                <Redirect from="/*" to="/" />
              </Switch>
            </CoinGeckoContext.Provider>
          </AutoConnectContext.Provider>
        </WalletContext.Provider>
      </RecoilRoot>
    </Router>
  );
};

export default App;
