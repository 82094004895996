import React, { useState, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";
import { useUrl, WalletContext } from "../../../../context";
import styles from "../Table.module.sass";
import PositionRow from "./PositionRow.js";
import { openPositionsState } from "../../../../recoil/atoms";
import { fetchOpenPositionsState } from "../../../../recoil/selectors";
import { EmptySet } from "../../../../components/EmptySet";

type MarketPositionsProps = {
  showAllPositions?: boolean;
  tableContentClassName?: string;
};

const MarketPositions = ({
  showAllPositions,
  tableContentClassName,
}: MarketPositionsProps) => {
  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();

  const [showEmptyPositions, setShowEmptyPositions] = useState(false);

  const keplrWalletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts?.[0];
  const [openPositions, setOpenPositions] = useRecoilState(openPositionsState);
  const queryOpenPositionsInfo = useRecoilValue(
    fetchOpenPositionsState(keplrWalletAddress)
  );

  useEffect(() => {
    if (keplrWalletAddress) {
      queryOpenPositionsInfo().then((res) => {
        setOpenPositions(res);
      });
      // getPosition(keplrWalletAddress, priceDenomString, assetDenomString).then(
      //   (res) => {
      //     console.log("chain position res", res);
      //   }
      // );
      // getPositionsByUser(keplrWalletAddress).then((res) => {
      //   console.log("positions", res);
      //   setAvgOpenPrices(res);
      // });
    }
  }, [keplrWalletAddress]);

  const positionEntries: any[] = Object.entries(openPositions);

  const renderTableHeader = () => {
    return (
      <div className={styles.header}>
        <div className={cn(styles.headerRow, tableContentClassName)}>
          <div className={styles.col} style={{ minWidth: 300, maxWidth: 300 }}>
            <div className="sorting">Market</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Position</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Order Amount</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Average Open</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Margin Debt</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Last Funding Epoch</div>
          </div>
          <div className={styles.col}>
            <div className="sorting"> </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOpenLongPositions = () => {
    if (!openPositions || !assetDenomString) return null;

    return positionEntries.map((position: [string, any]) => {
      if (position?.length > 0 && position[1]?.long_position?.decimal > 0) {
        return (
          <PositionRow
            showAllPositions={showAllPositions}
            key={`${position[0]}long`}
            direction={0}
            marketPositions={position[1]}
            assetDenomString={position[0].split("-")[0]}
            priceDenomString={position[0].split("-")[1]}
          />
        );
      }
      return null;
    });
  };

  const renderOpenShortPositions = () => {
    if (!openPositions || !assetDenomString) return null;

    return positionEntries.map((position: [string, any]) => {
      if (position?.length > 0 && position[1]?.short_position?.decimal > 0) {
        return (
          <PositionRow
            showAllPositions={showAllPositions}
            key={`${position[0]}long`}
            direction={1}
            marketPositions={position[1]}
            assetDenomString={position[0].split("-")[0]}
            priceDenomString={position[0].split("-")[1]}
          />
        );
      }
      return null;
    });
  };

  const renderPositions = () => {
    if (!keplrWalletAddress) return <p>Connect your wallet</p>;

    const numberOfPositions = positionEntries.filter((entry) => {
      return (
        entry[1]?.long_position?.decimal > 0 ||
        entry[1]?.short_position?.decimal > 0
      );
    }).length;

    if (numberOfPositions === 0) {
      return <EmptySet text="No Open Positions" small />;
    }

    return (
      <>
        {renderOpenLongPositions()}
        {renderOpenShortPositions()}
      </>
    );
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableScroll}>
        {renderTableHeader()}
        <div className={cn(styles.content, tableContentClassName)}>
          {renderPositions()}
        </div>
      </div>
    </div>
  );
};

export default MarketPositions;
