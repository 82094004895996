import React, { useContext, useState, useEffect } from "react";
import cn from "classnames";
import { WalletContext } from "../../../context";
import styles from "./ConfirmClosePosition.module.sass";

const ConfirmClosePosition = ({
  onClick,
  classButton,
  buttonText,
  orderQuantity,
}: any) => {
  const wallet = useContext(WalletContext).wallet || {
    cosmosSigner: null,
    cosmosAccounts: [],
  };
  const [error, setError] = useState("");
  useEffect(() => {
    let isMounted = true;
    let tempError = "";
    if (wallet.cosmosAccounts && wallet.cosmosAccounts.length === 0) {
      tempError = "Wallet Not Connected";
    } else if (Number.isNaN(Number(orderQuantity))) {
      tempError = "Order Can't Be Empty";
    } else if (Number(orderQuantity) <= 0) {
      tempError = "Please enter a positive amount";
    }
    //  else if (
    //   accountInfo &&
    //   accountInfo.buying_power &&
    //   (accountInfo.buying_power.negative ||
    //     Number(accountInfo.buying_power.decimal) <= summary[4].amount)
    // ) {
    //   tempError = "Insufficient funds. Deposit into Vortex.";
    // }

    if (isMounted) setError(tempError);
    return () => {
      isMounted = false;
    };
  }, [orderQuantity, wallet]);

  return (
    <div className={styles.box}>
      {/* <div className={styles.table}>
        {summary.map((x, index) => (
          <div className={styles.tableRow} key={index}>
            <div className={styles.col}>
              <div className={styles.tip}>{x.title}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.value}>{`${x.amount} ${x.denom}`}</div>
            </div>
          </div>
        ))}
      </div> */}

      <button
        id="submitButton"
        disabled={!!error}
        onClick={() => {
          onClick();
        }}
        className={cn(classButton, styles.button)}
      >
        {error || buttonText}
      </button>
    </div>
  );
};

export default ConfirmClosePosition;
