import { selectorFamily } from "recoil";
import { marketSummaryState } from "../atoms";

export const pairMarketSummaryState = selectorFamily({
  key: "PairMarketSummaryState",
  get:
    (pair: string) =>
    ({ get }) => {
      return get(marketSummaryState)[pair];
    },
});
