import { atom } from "recoil";
import { MarketSummaryType } from "./../../context/types/MarketSummaryType";

export type MarketSummary = {
  [pair: string]: MarketSummaryType;
};

export const marketSummaryState = atom<MarketSummary>({
  key: "marketSummaryState",
  default: {},
});
