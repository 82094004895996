import { createContext } from "react";

type WalletContext = {
  wallet: {
    cosmosSigner: any;
    cosmosAccounts: any;
    metamaskSigner: any;
    metamaskAccounts: any;
  };
  setWallet: (wallet: object) => void;
};

export default createContext<WalletContext>({
  wallet: {
    cosmosSigner: undefined,
    cosmosAccounts: [],
    metamaskSigner: undefined,
    metamaskAccounts: [],
  },
  setWallet: () => {},
});
