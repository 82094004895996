import React from "react";
import cn from "classnames";
import ReactLoading from "react-loading";
import styles from "../BridgeCard.module.sass";
import Currency from "../../../../components/Currency";
import { openInNewTab } from "../../../../utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  bridgeDepositAddressState,
  bridgeDestinationChainState,
  bridgeErrorState,
  bridgeFromChainState,
  bridgeFromTokenState,
  bridgeLoadingState,
  bridgeModalIndexState,
} from "../../recoil/atoms";

const BridgeSuccess = () => {
  const loading = useRecoilValue(bridgeLoadingState);
  const fromChain = useRecoilValue(bridgeFromChainState);
  const fromToken = useRecoilValue(bridgeFromTokenState);
  const depositAddressError = useRecoilValue(bridgeErrorState);
  const destinationChain = useRecoilValue(bridgeDestinationChainState);

  const setDepositAddress = useSetRecoilState(bridgeDepositAddressState);
  const setDepositAddressError = useSetRecoilState(bridgeErrorState);
  const setModalPageIndex = useSetRecoilState(bridgeModalIndexState);

  const handleClickComplete = () => {
    setModalPageIndex(0);
    setDepositAddress(null);
    setDepositAddressError(null);
  };

  return (
    <div className={styles.smallerContainer}>
      <div className={styles.withdraw}>
        <div className={styles.rowHeading}>
          <div className={cn("h4", styles.text)}>Bridge token initiated 🎉</div>
        </div>

        <div className={styles.blankFullWidthBox}>
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.cardTitle}>Token</div>
              <div className={styles.currencyBox}>
                <Currency name={fromToken.name} symbol={fromToken.symbol} />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.cardTitle}>From</div>
              <div className={styles.currencyBox}>
                <Currency name={fromChain.name} symbol={fromChain.symbol} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.cardTitle}>To</div>
              <div className={styles.currencyBox}>
                <Currency
                  name={destinationChain.name}
                  symbol={destinationChain.symbol}
                />
              </div>
            </div>
          </div>
        </div>

        {loading && (
          <div className={styles.loading}>
            <ReactLoading type="spin" color="#223E50" height={50} width={50} />
          </div>
        )}

        {/* {!loading && depositAddress && (
        <div className={styles.currencyBox}>
          <div className={cn("description", styles.text)}>
            Deposit Address: <br />
            {depositAddress}
          </div>
          <div className={cn("description", styles.text)}>
            If you're depositing via deposit address, please only initiate a
            transfer from your wallet to this deposit address with: (
            {fromChain.name} →{destinationChain.name}) with {fromToken.symbol}.
            At the destination address: {truncateAddress(destinationAddress)}.
          </div>
        </div>
      )} */}

        {depositAddressError && (
          <div className={styles.currencyBox}>
            <div className={cn("description", styles.text, styles.errorBox)}>
              {depositAddressError}
            </div>
          </div>
        )}

        {fromChain.name === "SEI Network" && (
          <>
            <div className={cn("description", styles.text)}>
              Currently, to transfer out of Sei, your Keplr wallet needs to add
              Axelar Lisbon Testnet Chain, and the channel ID: `channel-29`. If
              you do not have this, please use Satellite instead:
            </div>
            <div
              className={styles.link}
              onClick={() =>
                openInNewTab("https://testnet.satellite.money/?source=sei")
              }
            >
              <span>Satellite Money</span>
            </div>
          </>
        )}

        <button
          className={cn("button", styles.button)}
          onClick={handleClickComplete}
        >
          Send another bridge
        </button>
      </div>
    </div>
  );
};

export default BridgeSuccess;
