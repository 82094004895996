export const connectMetaMask = async (chainId, chainName) => {
  if (
    typeof window.ethereum === "undefined" ||
    typeof window.ethereum.isMetaMask === "undefined"
  ) {
    alert(
      "Please ensure the latest version of MetaMask is installed!",
      chainId,
      chainName
    );
  }
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  return { accounts };
};

// Metamask: Eth is 0x1, Ropsten testnet is 0x3, BNB is 0x38
export const getMetamaskChainId = async () => {
  const chainId = await window.ethereum.request({
    method: "eth_chainId",
  });
  return chainId;
};
