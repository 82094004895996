// Chain Docs: https://github.com/axelarnetwork/axelar-docs/blob/11edefd0ab56adec9867e4975ace3ff27c006201/data/gateways.json
// Token Docs: https://github.com/axelarnetwork/axelar-docs/blob/7cb217f9295c43a4c16a50001b8834b4e0e474be/data/evm_assets.json
export type BridgeChainToken = {
  name: string;
  symbol: string;
  assetDenom: string;
  axelarTokenAddress?: string;
};

export type BridgeChainOption = {
  name: string;
  axelarName: string;
  symbol: string;
  axelarChainAddress?: string;
  supportedWallets: string[];
  tokens: BridgeChainToken[];
};

export const bridgeChainOptions: BridgeChainOption[] = [
  {
    name: "Avalanche",
    axelarName: "avalanche",
    symbol: "AVAX",
    axelarChainAddress: "0xC249632c2D40b9001FE907806902f63038B737Ab",
    supportedWallets: ["metamask"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0x57f1c63497aee0be305b8852b354cec793da43bb",
      },
      {
        name: "Ethereum",
        symbol: "WETH",
        assetDenom: "weth-wei",
        axelarTokenAddress: "0x3613C187b3eF813619A25322595bA5E297E4C08a",
      },
    ],
  },
  {
    name: "Binance",
    axelarName: "bnb",
    symbol: "BNB",
    axelarChainAddress: "0x4D147dCb984e6affEEC47e44293DA442580A3Ec0",
    supportedWallets: ["keplr", "leap"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0xc2fA98faB811B785b81c64Ac875b31CC9E40F9D2",
      },
      {
        name: "Ethereum",
        symbol: "WETH",
        assetDenom: "weth-wei",
        axelarTokenAddress: "0x03Dc012b7851b7D65592Aebc40a6aF9A171E9315",
      },
    ],
  },
  // {
  //   name: "Cosmoshub",
  //   axelarName: "cosmoshub",
  //   symbol: "ATOM",
  //   supportedWallets: ["keplr", "leap"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //   ],
  // },
  // {
  //   name: "Crescent",
  //   axelarName: "crescent",
  //   symbol: "CRE",
  //   supportedWallets: ["metamask"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //     { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
  //   ],
  // },
  {
    name: "Ethereum Goerli",
    axelarName: "ethereum",
    symbol: "ETH",
    axelarChainAddress: "0xe432150cce91c13a887f7D836923d5597adD8E31",
    supportedWallets: ["metamask"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0x254d06f33bDc5b8ee05b2ea472107E300226659A",
      },
      // {
      //   name: "Ethereum",
      //   symbol: "WETH",
      //   assetDenom: "weth-wei",
      //   axelarTokenAddress: "",
      // },
      // {
      //   name: "Bitcoin",
      //   symbol: "WBTC",
      //   assetDenom: "wbtc-satoshi",
      //   axelarTokenAddress: "",
      // },
    ],
  },
  // {
  //   name: "e-Money",
  //   symbol: "NGM",
  //   supportedWallets: ["keplr", "leap"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //     { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
  //   ],
  // },
  {
    name: "Fantom",
    axelarName: "fantom",
    symbol: "FTM",
    axelarChainAddress: "0x97837985Ec0494E7b9C71f5D3f9250188477ae14",
    supportedWallets: ["metamask"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0x75cc4fdf1ee3e781c1a3ee9151d5c6ce34cf5c61",
      },
      {
        name: "Ethereum",
        symbol: "WETH",
        assetDenom: "weth-wei",
        axelarTokenAddress: "0x930640ef299Bf772f786Cf7E88DA951D76E33168",
      },
    ],
  },
  // {
  //   name: "Juno",
  //   axelarName: "juno",
  //   symbol: "JUNO",
  //   supportedWallets: ["keplr", "leap"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //     { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
  //   ],
  // },
  // {
  //   name: "Kujira",
  //   axelarName: "kujira",
  //   symbol: "KUJI",
  //   supportedWallets: ["metamask"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //     { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
  //   ],
  // },
  {
    name: "Moonbeam",
    axelarName: "moonbeam",
    symbol: "GLMR",
    axelarChainAddress: "0x5769D84DD62a6fD969856c75c7D321b84d455929",
    supportedWallets: ["keplr", "leap"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0xd1633f7fb3d716643125d6415d4177bc36b7186b",
      },
      {
        name: "Ethereum",
        symbol: "WETH",
        assetDenom: "weth-wei",
        axelarTokenAddress: "0xc40Fdaa2cB43C85eAA6D43856df42E7A80669fca",
      },
    ],
  },
  // {
  //   name: "Osmosis",
  //   axelarName: "osmosis",
  //   symbol: "OSMO",
  //   supportedWallets: ["keplr", "leap"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //   ],
  // },
  {
    name: "Polygon",
    axelarName: "polygon",
    symbol: "MATIC",
    axelarChainAddress: "0xBF62ef1486468a6bd26Dd669C06db43dEd5B849B",
    supportedWallets: ["metamask"],
    tokens: [
      {
        name: "USD Coin",
        symbol: "aUSDC",
        assetDenom: "uausdc",
        axelarTokenAddress: "0x2c852e740B62308c46DD29B982FBb650D063Bd07",
      },
      {
        name: "Ethereum",
        symbol: "WETH",
        assetDenom: "weth-wei",
        axelarTokenAddress: "0x526f0A95EDC3DF4CBDB7bb37d4F7Ed451dB8e369",
      },
    ],
  },
  // {
  //   name: "Terra 2.0",
  //   symbol: "LUNA",
  //   supportedWallets: ["metamask", "keplr"],
  //   tokens: [
  //     { name: "USD Coin", symbol: "USDC", assetDenom: "uausdc" },
  //     { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
  //     { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
  //   ],
  // },
];

export const seiChainOptions: BridgeChainOption[] = [
  {
    name: "SEI Network",
    axelarName: "sei",
    symbol: "SEI",
    supportedWallets: ["keplr", "leap"],
    tokens: [
      { name: "USD Coin", symbol: "aUSDC", assetDenom: "uausdc" },
      { name: "Ethereum", symbol: "WETH", assetDenom: "weth-wei" },
      { name: "Bitcoin", symbol: "WBTC", assetDenom: "wbtc-satoshi" },
    ],
  },
];
