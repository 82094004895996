import { selectorFamily } from "recoil";
import { useQueryContract } from "../../context";

export const fetchAccountInfo = selectorFamily({
  key: "FetchAccountInfo",
  get:
    (walletAddress: string) =>
    ({ get }) => {
      const { getPortfolioSpecs } = useQueryContract();
      return () => getPortfolioSpecs(walletAddress);
    },
});
