const orderType: { [key: number | string]: number | string } = {
  LIMIT: 0,
  MARKET: 1,
  LIQUIDATION: 2,
  0: 'Limit',
  1: 'Market',
  2: 'Liquidation',
};

export default orderType;
