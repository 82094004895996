import React, { useEffect, useState } from "react";
import cn from "classnames";
import axios, { AxiosResponse } from "axios";
import { useRecoilValue } from "recoil";
import Icon from "../../../components/Icon";
import { useUrl, useMarketSummary, useQueryContract } from "../../../context";
import { numberWithCommas } from "../../../utils";
import useFetchOrderBooks from "../../../context/hooks/useFetchOrderBooks";
import { pairOrderBookState } from "../../../recoil/selectors";
import useOraclePrices from "../../../context/hooks/useOraclePrices";
import { markets } from "../../../context/constants/tokenPairs";
import Image from "../../../components/Image";
import styles from "./Main.module.sass";
import { formatNumber } from "../../../utils/formatting";
import useFundingRate from "../../../context/hooks/useFundingRate";
import { pairFundingRateState } from "../../../recoil/selectors/fundingRate";
import { pairMarketSummaryState } from "../../../recoil/selectors/marketSummary";
import { pairOraclePricesState } from "../../../recoil/selectors/oraclePrices";
import minimalDenom from "../../../context/constants/minimalDenom";

const items: { title: string; icon: string }[] = [
  // {
  //   title: "Index Price",
  //   icon: "arrow-top",
  // },
  // {
  //   title: "24h change",
  //   icon: "clock",
  //   color: "#00C076",
  // },
  {
    title: "Last price",
    icon: "clock",
  },
  {
    title: "Oracle price",
    icon: "arrow-top",
  },
  {
    title: "24h high",
    icon: "arrow-top",
  },
  {
    title: "24h low",
    icon: "arrow-bottom",
  },
  // {
  //   title: "Market cap",
  //   icon: "chart",
  // },
  // {
  //   title: "Total volume",
  //   icon: "chart",
  // },
  {
    title: "1h Funding",
    icon: "chart",
  },
  {
    title: "Insurance Fund",
    icon: "chart",
  },
];

type MainProps = {
  toggleSelectMarket: boolean;
  setToggleSelectMarket: (value: boolean) => void;
};

const Main = (props: MainProps) => {
  useFundingRate();
  useOraclePrices();
  useMarketSummary();
  useFetchOrderBooks();
  const { toggleSelectMarket, setToggleSelectMarket } = props;

  const { getExchangeDenoms, pair } = useUrl();
  const [assetDenomString, priceDenomString] = getExchangeDenoms();
  const [marketDataForPair, setMarketDataForPair] =
    useState<AxiosResponse | null>(null);
  const pairFundingRate = useRecoilValue(pairFundingRateState(pair));
  const [insuranceFund, setInsuranceFund] = useState<{
    balance: {
      decimal: string;
    };
  } | null>(null);
  const pairMarketSummary = useRecoilValue(pairMarketSummaryState(pair));
  const pairOrderBook = useRecoilValue(pairOrderBookState(pair));

  const pairOraclePrice = useRecoilValue(
    pairOraclePricesState(minimalDenom[assetDenomString])
  );

  const { getInsuranceFundBalance } = useQueryContract();

  useEffect(() => {
    const fetchInsuranceFund = async () => {
      try {
        const ifResponse = await getInsuranceFundBalance(assetDenomString);
        setInsuranceFund(ifResponse);
      } catch (e) {
        console.error("Error fetching insurance fund", e);
      }
    };

    fetchInsuranceFund().then();

    let interval: NodeJS.Timer;

    interval = setInterval(() => {
      if (!priceDenomString || !assetDenomString) return;
      fetchInsuranceFund().then();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [priceDenomString, assetDenomString, pairOrderBook]);

  useEffect(() => {
    let isMounted = true;
    const fetchSpotMarketPrice = async () => {
      const key = markets ? markets[pair]?.coinGeckoAPIKey : "";
      if (key === "") {
        setMarketDataForPair(null);
        return null;
      }

      axios
        .get(
          `https://api.coingecko.com/api/v3/coins/${key}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
        )
        .then((res) => {
          if (isMounted) setMarketDataForPair(res);
        })
        .catch((error) => console.error(JSON.stringify(error)));
      return null;
    };

    fetchSpotMarketPrice().then();

    let interval: NodeJS.Timer;

    interval = setInterval(() => {
      if (!priceDenomString || !assetDenomString) return;
      fetchSpotMarketPrice().then();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [priceDenomString, assetDenomString, markets[pair]?.coinGeckoAPIKey]);

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div className={styles.marketBox}>
          <div className={styles.line}>
            <button
              className={cn(styles.dropdown, {
                [styles.active]: toggleSelectMarket,
              })}
              value={String(toggleSelectMarket)}
              onClick={() => setToggleSelectMarket(!toggleSelectMarket)}
            >
              <div className={styles.column}>
                <div className={styles.row}>
                  <Image
                    size={24}
                    srcDark={`/images/tokens/${markets[pair].currency}.png`}
                  />
                  <div className={styles.info}>{assetDenomString}</div>
                </div>
                <div className={styles.content}>{markets[pair].name}</div>
              </div>
              <div className={styles.arrow}>
                <Icon name="arrow-down" size="24" />
              </div>
            </button>
          </div>
        </div>

        {marketDataForPair && pairOrderBook && (
          <div className={styles.box}>
            <div className={styles.price}>{pairOrderBook.bestShortPrice}</div>
            <div className={styles.content}>
              <Icon name="coin" size="16" />
              {marketDataForPair.data.market_data &&
                numberWithCommas(
                  marketDataForPair.data.market_data.current_price.usd
                )}{" "}
              (Spot Price)
            </div>
          </div>
        )}
      </div>

      {marketDataForPair && (
        <div className={styles.list}>
          {items.map((x, index) => {
            const formattedInsuranceFund = formatNumber(
              Number(insuranceFund?.balance?.decimal),
              {
                maximumFractionDigits: 4,
                minimumFractionDigits: 2,
              }
            );

            return (
              <div className={styles.item} key={index}>
                <div className={styles.subtitle}>
                  <Icon name={x.icon} size="16" />
                  {x.title}
                </div>
                <div className={styles.content}>
                  {x.title === "Last price" &&
                    pairMarketSummary &&
                    numberWithCommas(
                      Number(pairMarketSummary.last_price).toFixed(2)
                    )}
                  {x.title === "Oracle price" &&
                    pairOraclePrice &&
                    numberWithCommas(pairOraclePrice.price)}
                  {x.title === "24h high" &&
                    pairMarketSummary &&
                    numberWithCommas(
                      Number(pairMarketSummary.high_price).toFixed(2)
                    )}
                  {x.title === "24h low" &&
                    pairMarketSummary &&
                    numberWithCommas(
                      Number(pairMarketSummary.low_price).toFixed(2)
                    )}
                  {/* {x.title === "Total volume" &&
                  marketSummary &&
                  numberWithCommas(
                    Number(marketSummary.totalVolume).toFixed(2)
                  )} */}
                  {/* Get One Hour Funding Rate instead of 1 minute */}
                  {x.title === "1h Funding" &&
                    pairFundingRate &&
                    (pairFundingRate.length > 0
                      ? (Number(pairFundingRate[0].funding_rate) * 100).toFixed(
                          2
                        ) + "%"
                      : 0.0)}

                  {x.title === "Insurance Fund" ? formattedInsuranceFund : ""}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Main;
