/* eslint-disable no-loss-of-precision */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { tokenPairs } from "../../../context/constants/tokenPairs.ts";
import { numberWithCommas } from "../../../utils";
import { CoinGeckoContext } from "../../../context/CoinGeckoContext";
import styles from "./ToggleMarket.module.sass";

// const seiCoin = {
//   name: "Sei Network",
//   symbol: "SEI",
//   url: "/trade/USDC-SEI",
// };

const ToggleMarket = ({ closeDropdown }) => {
  const { coinGeckoMarketData } = useContext(CoinGeckoContext);
  const history = useHistory();

  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        <div className={styles.nav}>
          {coinGeckoMarketData &&
            coinGeckoMarketData.length > 0 &&
            coinGeckoMarketData.map((x, index) => (
              <div
                key={index}
                className={styles.row}
                onClick={() => {
                  closeDropdown();
                  history.push(tokenPairs[x.data.id].url);
                }}
              >
                <div className={styles.coinText}>
                  <div className={styles.icon}>
                    <img
                      alt="Avatar"
                      src={`/images/tokens/${x.data.symbol.toUpperCase()}.png`}
                    />
                  </div>
                  <button key={x.data.name} className={styles.link}>
                    {x.data.name}
                  </button>
                  <button className={styles.currency}>
                    {x.data.symbol.toUpperCase()}
                  </button>
                </div>
                <div>
                  <div className={styles.amount}>
                    {numberWithCommas(x.data.market_data.current_price.usd)}
                  </div>
                </div>
              </div>
            ))}
          {/* <div
            key={seiCoin.symbol}
            className={styles.row}
            onClick={() => {
              closeDropdown();
              history.push(seiCoin.url);
            }}
          >
            <div className={styles.coinText}>
              <div className={styles.icon}>
                <img src="/images/sei-logo.svg" width={25} alt="Coin" />
              </div>
              <button key={seiCoin.name} className={styles.link}>
                {seiCoin.name}
              </button>
              <button className={styles.currency}>
                {seiCoin.symbol.toUpperCase()}
              </button>
            </div>
            <div>
              <div className={styles.amount}>{numberWithCommas(1)}</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ToggleMarket;
