/* eslint-disable no-console */
import { useContext } from "react";
import { SeiSigningCosmWasmClient } from "@sei-js/core";
import { coins } from "@cosmjs/proto-signing";
import { contractAddr, tendermintURL } from "../constants";
import WalletContext from "../WalletContext";
import { createCoin } from "../../utils/transaction.ts";

const useWithdraw = () => {
  const { cosmosAccounts, cosmosSigner } = useContext(WalletContext).wallet || {
    cosmosSigner: null,
    cosmosAccounts: [],
  };
  const [firstAccount] = cosmosAccounts;

  const withdraw = async (token, amount) => {
    if (!cosmosSigner) {
      return undefined;
    }

    const signingCosmWasmClient =
      await SeiSigningCosmWasmClient.connectWithSigner(
        tendermintURL,
        cosmosSigner
      );

    const coin =
      token === "aUSDC"
        ? createCoin("USDC", amount.toString())
        : createCoin(token, amount.toString());

    const fee = {
      amount: coins(15000, "usei"),
      gas: "300000",
    };

    try {
      return await signingCosmWasmClient.execute(
        firstAccount,
        contractAddr,
        { withdraw: { coins: [coin] } },
        fee,
        "withdraw ust2",
        []
      );
    } catch (error) {
      return undefined;
    }
  };

  return { withdraw };
};

export default useWithdraw;
