/* eslint-disable no-console */
import { contractAddr } from "..";
import { offchainURL } from "../constants";
import axios from "axios";

const useHistoricalPrices = () => {
  const getCandlesticks = async (
    client: any,
    priceDenomString: string,
    assetDenomString: string,
    periodLengthInSeconds: number,
    numOfPeriods: number = 500
  ) => {
    try {
      return await axios
        .get(
          `${offchainURL}/getHistoricalPrices/${contractAddr}-${priceDenomString}-${assetDenomString}/${periodLengthInSeconds}/${numOfPeriods}`
        )
        .then((results) => {
          const mappedPrices = results?.data?.prices.map(
            (price: any, index: number) => {
              const nDate = new Date(parseFloat(price.end_timestamp) * 1000);
              const formattedTime = Date.parse(nDate.toUTCString()) / 1000;
              return {
                time: formattedTime,
                open: parseFloat(price.open),
                high: parseFloat(price.high),
                low: parseFloat(price.low),
                close: parseFloat(price.close),
              };
            }
          );

          return mappedPrices.reverse();
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getCandlesticksOnchain = async (
    client: any,
    priceDenomString: string,
    assetDenomString: string,
    periodLengthInSeconds: number,
    numOfPeriods: number = 500
  ) => {
    const prices = await client.queryGetHistoricalPrices(
      contractAddr,
      priceDenomString,
      assetDenomString,
      periodLengthInSeconds,
      numOfPeriods
    );

    const mappedPrices =
      prices?.data?.prices?.map((price: any) => {
        const nDate = new Date(price.endTimestamp * 1000);
        const formattedTime = Date.parse(nDate.toUTCString()) / 1000;
        return {
          time: formattedTime,
          open: parseFloat(price.open),
          high: parseFloat(price.high),
          low: parseFloat(price.low),
          close: parseFloat(price.close),
        };
      }) || [];

    return mappedPrices.reverse();
  };

  return { getCandlesticks, getCandlesticksOnchain };
};

export default useHistoricalPrices;
