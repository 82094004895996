import { groupByDecimal } from "../../utils";
import { OrderbookType } from "../types";

const spreadDifference = (longs: any[], shorts: any[]) => {
  const lowestSell = Math.min(...shorts.map((item) => parseFloat(item.price)));
  const highestBuy = Math.max(...longs.map((item) => parseFloat(item.price)));
  const spread = (lowestSell - highestBuy).toFixed(2);
  return {
    spread,
    spreadPercentage: ((Number(spread) / highestBuy) * 100).toFixed(2),
  };
};

const parseFetchedBook = (book: OrderbookType[], decimal: number) => {
  if (!book) {
    return [];
  }
  const ordersTotal = book.reduce(
    (accumulator, object) => accumulator + parseInt(object.entry.quantity, 10),
    0
  );

  return groupByDecimal(
    book.map((item) => {
      return {
        price: Number(item.entry.price).toFixed(3),
        amount: Number(item.entry.quantity).toFixed(3),
        percent:
          Number((parseInt(item.entry.quantity, 10) / ordersTotal).toFixed(2)) *
          100,
      };
    }),
    decimal
  ).sort((a, b) => b.price - a.price);
};

export { spreadDifference, parseFetchedBook };
