import React, { useState } from "react";
import Deposit from "../../components/Deposit";
import BridgeCard from "./BridgeCard";
import styles from "./Bridge.module.sass";

const Bridge = () => {
  const [showBridge, setShowBridge] = useState(false);

  return (
    <div className={styles.bridge}>
      {showBridge ? <Deposit showBridge={setShowBridge} /> : <BridgeCard />}
    </div>
  );
};

export default Bridge;
