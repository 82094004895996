import { selectorFamily } from "recoil";
import useQueryContract from "../../context/hooks/useQueryContract";

export const fetchAccountBalanceState = selectorFamily({
  key: "fetchAccountBalanceState",
  get:
    (walletAddress: string) =>
    ({ get }) => {
      const { getBalances } = useQueryContract();
      return () => getBalances(walletAddress);
    },
});
