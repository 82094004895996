// @ts-ignore
import { Coin } from "matrix-sdk-test/src/proto/generated/CosmWasm/wasmd/cosmwasm.wasm.v1/module/types/cosmos/base/v1beta1/coin";
import { OrderSummary } from "../screens/Exchange/Actions/Form/Action/types";

const getOrderPricing = (
  price: number,
  quantity: number,
  feeEstimate: number = 0
) => {
  const total = +(price * quantity + feeEstimate).toFixed(6);
  const orderPrice = +(price * quantity).toFixed(6);
  return { total, fee: feeEstimate, orderPrice };
};

const createCoin = (denom: string, amount: number) =>
  Coin.fromPartial({ denom, amount });

const unitsAdjustedAmount = (amount: number, conversion: number) =>
  (amount * conversion).toString();

const unitsAdjustedAmountOpposite = (amount: number, conversion: number) =>
  (amount / conversion).toString();

const getOrderSummary = (
  price: number,
  quantity: number,
  leverage: number,
  fee: number,
  total: number,
  priceDenomString: string,
  assetDenomString: string
) => {
  const orders: OrderSummary[] = [
    {
      title: "Amount",
      amount: quantity,
      denom: assetDenomString,
    },
    {
      title: "Order Price",
      amount: price,
      denom: priceDenomString,
    },
    {
      title: "Estimated Fee",
      amount: fee,
      denom: priceDenomString,
    },
    {
      title: "Total",
      amount: total,
      denom: priceDenomString,
    },
    {
      title: "Leverage",
      amount: leverage,
      denom: "X",
    },
    {
      title: "Purchase price",
      amount: total / leverage,
      denom: priceDenomString,
    },
  ];

  // if (leverage && leverage > 1) {
  //   orders.push(
  //     {
  //       title: "Total",
  //       amount: total,
  //       denom: priceDenomString,
  //     },
  //     {
  //       title: "Leverage",
  //       amount: leverage,
  //       denom: "X",
  //     },
  //     {
  //       title: "Purchase price",
  //       amount: total / leverage,
  //       denom: priceDenomString,
  //     }
  //   );
  // } else {
  //   orders.push({
  //     title: "Total",
  //     amount: total,
  //     denom: priceDenomString,
  //   });
  // }

  return orders;
};

const formattedStringInput = (amount: number) => ({
  amount: amount.toString(),
  negative: false,
});

export {
  createCoin,
  unitsAdjustedAmount,
  unitsAdjustedAmountOpposite,
  getOrderPricing,
  getOrderSummary,
  formattedStringInput,
};
