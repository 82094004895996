const truncateAddress = (address) =>
  `${address.slice(0, 3)}....${address.slice(address.length - 5)}`;

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const groupByDecimal = (array, decimals) => {
  const result = [];
  array.forEach((a) => {
    const price = Number(a.price).toFixed(decimals);
    if (result.some(x => x.price.startsWith(price))) {
      result.find(x => x.price.startsWith(price)).amount += Number(a.amount);
    } else {
      result.push({ price, amount: Number(a.amount), percent: a.percent });
    }
  });
  return result
}

const getNumberOfDecimals = (num) => { if (num !== "1") { return num.toString().split(".")[1].length || 0 } return 0 }

const formatAmount = (amount, decimals) => {
  const formattedAmount = parseInt(amount, 10) / 10 ** decimals;
  return formattedAmount.toString();
}

export { truncateAddress, openInNewTab, groupByDecimal, getNumberOfDecimals, formatAmount };
