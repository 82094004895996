import { atom } from "recoil";
import {
  BridgeChainOption,
  bridgeChainOptions,
  BridgeChainToken,
  seiChainOptions,
} from "../../../context/constants/bridging";
import { BridgingStatus } from "../BridgeCard/BridgeAddressConfirm/types";

export const bridgeFromChainState = atom<BridgeChainOption>({
  key: "bridgeFromChainState",
  default: bridgeChainOptions[2],
});

export const bridgeDestinationChainState = atom<BridgeChainOption>({
  key: "bridgeDestinationChainState",
  default: seiChainOptions[0],
});

export const bridgeFromTokenState = atom<BridgeChainToken>({
  key: "bridgeFromTokenState",
  default: bridgeChainOptions[2].tokens[0],
});

export const bridgeDestinationTokenState = atom<BridgeChainToken>({
  key: "bridgeDestinationTokenState",
  default: seiChainOptions[0].tokens[0],
});

export const bridgeErrorState = atom<string | null>({
  key: "bridgeErrorState",
  default: null,
});

export const bridgeDestinationAddressState = atom<string>({
  key: "bridgeDestinationAddressState",
  default: "",
});

export const bridgeOrderAmountInputState = atom<string>({
  key: "bridgeOrderAmountInputState",
  default: "",
});

export const bridgeDepositAddressState = atom<string | null>({
  key: "bridgeDepositAddressState",
  default: null,
});

export const bridgeLoadingState = atom<boolean>({
  key: "bridgeLoadingState",
  default: false,
});

export const bridgeModalIndexState = atom<number>({
  key: "bridgeModalIndexState",
  default: 0,
});

export const bridgeStatusState = atom<BridgingStatus>({
  key: "bridgeStatusState",
  default: "awaitingUserInput",
});
