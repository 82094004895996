import React from "react";
import cn from "classnames";
import Checkbox from "../../Checkbox";
import styles from "../WalletConnectModal.module.sass";

const WalletConnectTerms = (props) => {
  const { acceptedTerms, setAcceptedTerms, setClickedContinue } = props;

  return (
    <div>
      <div className={styles.col}>
        <div className={cn("h4", styles.text)}>Welcome to Vortex!</div>
        <div className={cn("description", styles.text)}>
          The Vortex protocol is still in public beta. This might include risk
          of smart contract and fund loss. Please use at your own risk. We currently do not support Ledger.
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.row}>
          <div className={styles.sign}>
            <img src="/images/content/icons/lightening.svg" alt="Lightening" />
          </div>
          <div className={styles.col}>
            <div className={styles.featureTitle}>Ultra-fast speed</div>
            <div className={cn("description", styles.text)}>
              Experience cross collateralized trading for lightening quick speed
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.sign}>
            <img
              src="/images/content/icons/fancyCircle.svg"
              alt="FancyCircle"
            />
          </div>
          <div className={styles.col}>
            <div className={styles.featureTitle}>Realtime settlement</div>
            <div className={cn("description", styles.text)}>
              Powerful orderbook run by Sei with deep liquidity and accurate
              price
            </div>
          </div>
        </div>
        <Checkbox
          className={styles.checkbox}
          value={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
          content="I understand the risks of the protocol"
        />
        <div className={styles.continue}>
          <button
            className={styles.continue}
            disabled={!acceptedTerms}
            onClick={() => setClickedContinue(true)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default WalletConnectTerms;
