import { OrderType } from "../../screens/Exchange/Table/OpenOrders/types";
import { useContext } from "react";
//@ts-ignore
import { MsgCancelOrders } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module/types/dex/tx";
// @ts-ignore
import { txClient } from "matrix-sdk-test/src/proto/generated/sei-protocol/sei-chain/seiprotocol.seichain.dex/module";
import { contractAddr, tendermintURL, WalletContext, txnFactor } from "..";
import { unitsAdjustedAmount } from "../../utils/transaction";
import { getSigningClientWithRegistry } from "../../utils";

const useCancelOrder = () => {
  const wallet = useContext(WalletContext).wallet || {
    cosmosSigner: null,
    cosmosAccounts: [],
  };
  const { cosmosSigner } = wallet;
  const [firstAccount] = wallet.cosmosAccounts;

  const cancelOrder = async (order: OrderType) => {
    const { msgCancelOrders } = await txClient(cosmosSigner, {
      addr: tendermintURL,
    });

    const signingClient = await getSigningClientWithRegistry(cosmosSigner);

    const cancellationObject = {
      id: order.id,
      initiator: firstAccount,
      creator: firstAccount,
      contractAddr,
      priceDenom: order.market.split("-")[1],
      assetDenom: order.market.split("-")[2],
      positionDirection: order.position_direction,
      price: unitsAdjustedAmount(Number(order.price), txnFactor),
    };

    const feeObject = {
      amount: [{ denom: "usei", amount: "15000" }],
      gas: "300000",
    };

    const msg = MsgCancelOrders.fromPartial({
      creator: firstAccount,
      cancellations: [cancellationObject],
      contractAddr,
    });

    try {
      return signingClient?.signAndBroadcast(
        firstAccount,
        [msgCancelOrders(msg)],
        feeObject
      );
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  return {
    cancelOrder,
  };
};

export default useCancelOrder;
