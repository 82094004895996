import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../Icon";
import Currency from "../Currency";
import styles from "./DropdownTokens.module.sass";
import { DropdownTokensProps } from "./types";
import {
  BridgeChainOption,
  BridgeChainToken,
} from "../../context/constants/bridging";

const DropdownTokens = ({
  type,
  className,
  value,
  setValue,
  options,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
}: DropdownTokensProps) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (val: BridgeChainOption | BridgeChainToken) => {
    setValue(val);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <div className={cn(styles.arrow, classDropdownArrow)}>
            <Icon name="arrow-down" size="24" />
          </div>
          <Currency
            type={type === "narrow" ? "symbol" : undefined}
            symbol={value.symbol}
            name={value.name}
          />
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          {Object.entries(options).map((x, index) => (
            <div
              className={cn(classDropdownOption, styles.option, {
                [styles.selected]: x[1].name === value,
              })}
              onClick={() => handleClick(x[1])}
              key={index}
            >
              <Currency
                type={type === "narrow" ? "symbol" : undefined}
                symbol={x[1].symbol}
                name={x[1].name}
              />
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownTokens;
