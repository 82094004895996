import { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import TextInput from "../TextInput";
import { useWithdraw, WalletContext } from "../../context";
import "react-toastify/dist/ReactToastify.css";
import { accountState, accountBalanceState } from "../../recoil/atoms";
import {
  fetchAccountBalanceState,
  fetchAccountInfo,
} from "../../recoil/selectors";
import { openInNewTab, truncateAddress } from "../../utils";
import { seiChainDepositOptions } from "../../context/constants/deposit";
import DropdownTokens from "../DropdownTokens";
import styles from "./WithdrawModal.module.sass";
import { WithdrawalModalProps } from "./types";

const WithdrawModal = ({ onClose }: WithdrawalModalProps) => {
  const { withdraw } = useWithdraw();
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [fromChain, setFromChain] = useState(seiChainDepositOptions[0]);
  const [fromToken, setFromToken] = useState(
    seiChainDepositOptions[0].tokens[0]
  );

  const walletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];

  const queryAccountInfo = useRecoilValue(fetchAccountInfo(walletAddress));
  const setAccountInfo = useSetRecoilState(accountState);

  const setAccountBalanceState = useSetRecoilState(accountBalanceState);
  const queryAccountBalanceState = useRecoilValue(
    fetchAccountBalanceState(walletAddress)
  );

  useEffect(() => {
    if (!amount) {
      setError("Amount is required");
    } else if (!Number(amount)) {
      setError("Amount is invalid");
    } else if (Number(amount) <= 0) {
      setError("Amount must be positive number");
    } else {
      setError("");
    }
  }, [amount]);

  const notifySuccessful = async (tx: any) => {
    if (tx !== undefined && tx.transactionHash !== null) {
      const txExploreURL = `https://testnet-explorer.brocha.in/sei%20atlantic%202/tx/${tx.transactionHash}`;

      toast(
        <div>
          🌊 ${amount} UST2 submitted for withdraw from Vortex. Please check
          wallet.
          <div
            className={styles.exploreLink}
            onClick={() => openInNewTab(txExploreURL)}
          >
            <span>View on Explorer</span>
          </div>
        </div>
      );
    } else {
      toast(<div>🚫 An error occurred.</div>);
    }
  };

  const handleSubmit = async () => {
    if (!amount) {
      return;
    }
    const numberAmount = Number(amount);
    if (!numberAmount) {
      return;
    }
    setSubmitting(true);
    await withdraw(fromToken.symbol, numberAmount).then((tx) => {
      onClose();
      notifySuccessful(tx);
      queryAccountInfo().then(setAccountInfo);
      queryAccountBalanceState().then(setAccountBalanceState);
      setSubmitting(false);
    });
  };

  const buttonText = error || (submitting ? "Submitting..." : "Withdraw");

  return (
    <div className={styles.withdraw}>
      <h1 className={cn("h4", styles.title)}>Withdraw</h1>
      <div className={styles.description}>
        Withdraw funds from your Vortex account and into your connected Sei
        Chain wallet address.
      </div>
      <div className={styles.row}>
        <DropdownTokens
          className={styles.dropdown}
          value={fromChain}
          setValue={setFromChain}
          options={seiChainDepositOptions}
        />
        <DropdownTokens
          type="narrow"
          className={styles.dropdown}
          value={fromToken}
          setValue={setFromToken}
          options={fromChain.tokens}
        />
      </div>
      <TextInput
        className={styles.field}
        label="Amount (UST2)"
        name="Amount"
        placeholder="0"
        type="text"
        required
        value={amount}
        onChange={(e) =>
          parseInt(e.target.value)
            ? setAmount(parseInt(e.target.value).toString())
            : setAmount("")
        }
      />
      <div className={styles.bottom}>
        <div className={styles.summary}>
          <p className={styles.label}>Fee</p>
          <p className={styles.value}>
            0.005 <span className={styles.label}>SEI</span>
          </p>
        </div>
        {walletAddress && (
          <div className={styles.summary}>
            <p className={styles.label}>Deposit Address</p>
            <p className={styles.value}>{truncateAddress(walletAddress)}</p>
          </div>
        )}
        {/* <div className={styles.summary}> */}
        {/*   <p className={styles.label}>Total Deposit</p> */}
        {/*   <p className={styles.value}> */}
        {/*     {amount - 0.005} <span className={styles.label}>SEI</span> */}
        {/*   </p> */}
        {/* </div> */}
        <button
          id="submitButton"
          disabled={Boolean(error || submitting)}
          onClick={handleSubmit}
          className={cn("button", styles.button)}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default WithdrawModal;
