import React, { useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { WalletContext } from "../../../context";
import { accountBalanceState } from "../../../recoil/atoms/balance";
import { fetchAccountBalanceState } from "../../../recoil/selectors";
import { numberWithCommas } from "../../../utils";
import styles from "./AssetBalances.module.sass";

const AssetBalances = () => {
  const keplrWalletAddress = (
    useContext(WalletContext).wallet || { cosmosAccounts: [] }
  ).cosmosAccounts[0];

  const [accountBalance, setAccountBalance] =
    useRecoilState(accountBalanceState);
  const queryAccountBalanceState = useRecoilValue(
    fetchAccountBalanceState(keplrWalletAddress)
  );

  useEffect(() => {
    if (keplrWalletAddress) {
      queryAccountBalanceState().then(setAccountBalance);
    }
  }, [keplrWalletAddress]);

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        {keplrWalletAddress && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.text}>Token</div>
            </div>
            <div className={styles.col}>
              <div className={styles.text}>Total Balance</div>
            </div>
          </div>
        )}
        {keplrWalletAddress &&
          accountBalance &&
          accountBalance.symbols &&
          accountBalance.symbols.map((symbol, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.currency}>{symbol}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.info}>
                  {accountBalance.amounts[index].negative ? "-" : " "}
                  {numberWithCommas(accountBalance.amounts[index].decimal)}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AssetBalances;
