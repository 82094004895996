import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useUrl = () => {
  const location = useLocation();

  const getExchangeDenoms = () => location.pathname.split("/").pop().split("-");
  const pair = location.pathname.split("/").pop();

  return { getExchangeDenoms, pair };
};

export default useUrl;
