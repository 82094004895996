import { suggestChain } from "@sei-js/core";
import { chainId, tendermintURL, lcdURL, chainName } from "../../../context";

export const connectLeap = async () => {
  if (!window?.leap) {
    alert("Leap Wallet not detected, please install extension");
    return {
      accounts: null,
    };
  }

  // Leap doesn't have atlantic-2 supported natively yet, so we have to suggest
  // chain before enabling.
  await suggestChain("leap", {
    chainId,
    chainName,
    restUrl: lcdURL,
    rpcUrl: tendermintURL,
  });

  await window.leap.enable(chainId);

  const sendingSigner = await window.leap.getOfflineSigner(chainId);
  if (!sendingSigner) {
    throw new Error(`Failed to get sendingSigner for ${chainId}`);
  }

  const accounts = await sendingSigner.getAccounts();

  return { accounts, signer: sendingSigner };
};
