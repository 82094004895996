import { Contract, ethers } from "ethers";

import { AxelarGateway } from "@axelar-network/axelarjs-sdk";

import { conversions } from "../../context";
import {
  unitsAdjustedAmount,
  unitsAdjustedAmountOpposite,
} from "../transaction";
import { BridgingStatus } from "../../screens/Bridge/BridgeCard/BridgeAddressConfirm/types";

const erc20Abi = require("./erc20.json");
const axelarGatewayAbi = require("./axelarGateway.json");

const getAxelarGateway = async (
  axelarChainAddress: string,
  axelarTokenAddress: string
) => {
  const provider = new ethers.providers.Web3Provider((window as any).ethereum);
  if (!provider) {
    throw new Error("Please install Metamask extension");
  }
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  const sourceChainContract = new Contract(
    axelarChainAddress,
    axelarGatewayAbi,
    signer
  );

  return {
    sourceChainGateway: new AxelarGateway(axelarChainAddress, provider),
    provider,
    signer,
    sourceChainContract,
    sourceTokenContract: new Contract(axelarTokenAddress, erc20Abi, signer),
    sourceWalletAddress: address,
  };
};

export const sendTokens = async (args: {
  destinationChain: string;
  destinationAddress: string;
  symbol: string;
  axelarChainAddress: string;
  axelarTokenAddress: string;
  amount: string;
  setTxStatus: (status: BridgingStatus) => void;
}) => {
  args.setTxStatus("connecting");
  const { sourceChainContract, sourceTokenContract } = await getAxelarGateway(
    args.axelarChainAddress,
    args.axelarTokenAddress
  );

  const adjustedAmount = unitsAdjustedAmount(
    Number(args.amount),
    conversions[args.symbol]
  );
  args.setTxStatus("awaitingContractApproval");
  await (
    await sourceTokenContract.approve(args.axelarChainAddress, adjustedAmount)
  ).wait();

  args.setTxStatus("awaitingBridgeSigning");
  const tx = await (
    await sourceChainContract.sendToken(
      args.destinationChain,
      args.destinationAddress,
      args.symbol,
      adjustedAmount.toString()
    )
  ).wait();
  args.setTxStatus("bridgeInitialized");
  return tx.transactionHash;
};

export async function getSourceAssetBalance(
  symbol: string,
  axelarChainAddress: string,
  axelarTokenAddress: string
): Promise<string> {
  const { sourceWalletAddress, sourceTokenContract } = await getAxelarGateway(
    axelarChainAddress,
    axelarTokenAddress
  );
  const balance = await sourceTokenContract.balanceOf(sourceWalletAddress);
  const adjustedAmount = unitsAdjustedAmountOpposite(
    balance.toNumber(),
    conversions[symbol]
  );
  return adjustedAmount;
}
