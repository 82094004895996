import { WalletNavItem } from "./types";

export const WALLET_NAV_ITEMS: WalletNavItem[] = [
  {
    title: "Overview",
    color: "#868c6c",
    url: "/portfolio",
  },
  {
    title: "Positions",
    color: "#918fc7",
    url: "/positions",
  },
  {
    title: "Orders",
    color: "#a9f7a1",
    url: "/orders",
  },
  {
    title: "Trade History",
    color: "#f7a1a1",
    url: "/history",
  },
];
