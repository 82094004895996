import React from "react";
import cn from "classnames";
import Icon from "../Icon";
import styles from "./TextInput.module.sass";
import { TextInputProps } from "./types";

const TextInput = (props: TextInputProps) => {
  const { className, classLabel, classInput, label, empty, view, icon, note } =
    props;

  const inputProps = { ...props, className: cn(classInput, styles.input) };

  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        { [styles.icon]: icon },
        className
      )}
    >
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.wrap}>
        <input autoComplete="off" {...inputProps} pattern="^\d*(\.\d{0,6})?$" />
        {view && (
          <button className={styles.toggle}>
            <Icon name="eye" size="24" />
          </button>
        )}
        {icon && (
          <div className={styles.preview}>
            <Icon name={icon} size="24" />
          </div>
        )}
        {note && <div className={styles.note}>{note}</div>}
      </div>
    </div>
  );
};

export default TextInput;
