// Atlantic 1 Shadow
// const tendermintURL = "https://sei-chain-atlantic.com/sei-chain-tm/";

// Atlantic 1
// const tendermintURL =
//   process.env.NODE_ENV === "development"
//     ? "https://sei-chain-incentivized.com/sei-chain-tm/"
//     : "https://node-6.sei-chain-incentivized.com/sei-chain-tm/";

// Atlantic 2
const tendermintURL = "https://rpc.atlantic-2.seinetwork.io/";

export default tendermintURL;
