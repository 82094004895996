import React, { useEffect, useContext, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";
import {
  useUrl,
  WalletContext,
  useCancelOrder,
  positionDirection,
  orderType,
  statuses,
} from "../../../../context";
import styles from "../Table.module.sass";
import { fetchOpenOrdersState } from "../../../../recoil/selectors";
import { openOrdersState } from "../../../../recoil/atoms";
import { OpenOrdersProps, OrderType } from "./types";
import { openInNewTab } from "../../../../utils";
import { toast } from "react-toastify";
import orderStatus from "../../../../context/enums/orderStatus";
import { EmptySet } from "../../../../components/EmptySet";

const OpenOrders = ({
  tableContentClassName,
  pageLength = 4,
}: OpenOrdersProps) => {
  const { cancelOrder } = useCancelOrder();

  const { getExchangeDenoms } = useUrl();

  const [pageIndex, setPageIndex] = useState<number>(0);

  const wallet = useContext(WalletContext).wallet || { cosmosAccounts: [] };

  const [assetDenomString] = getExchangeDenoms();

  const keplrWalletAddress = useMemo(
    () => wallet.cosmosAccounts[0],
    [wallet.cosmosAccounts]
  );

  const queryOpenOrdersInfo = useRecoilValue(
    fetchOpenOrdersState(keplrWalletAddress)
  );
  const [openOrders, setOpenOrders] = useRecoilState(openOrdersState);

  useEffect(() => {
    if (keplrWalletAddress) {
      queryOpenOrdersInfo().then(setOpenOrders);
    }
  }, [keplrWalletAddress]);

  const formatNumber = (value: string) => {
    const number = Number(value);
    return Number(number.toFixed(6));
  };

  const notifyOnSubmit = async (tx: any, id: string) => {
    if (tx !== undefined && tx.transactionHash !== null) {
      const txExploreURL = `https://testnet-explorer.brocha.in/sei%20atlantic%202/tx/${tx.transactionHash}`;
      toast(
        <div className={styles.normalText}>
          🌊 Cancel order #{id} request submitted.
          <div
            className={styles.exploreLink}
            onClick={() => openInNewTab(txExploreURL)}
          >
            <span>View on Explorer</span>
          </div>
        </div>
      );
    } else {
      toast(<div>🚫 An error in Cancel occurred.</div>);
    }
  };

  const onClickCancelOrder = async (order: OrderType) => {
    await cancelOrder(order).then((tx) => {
      if (tx !== undefined && tx.transactionHash !== undefined)
        notifyOnSubmit(tx, order.id);
    });
  };

  const filterOpenOrdersToShow = (orders: OrderType[]) => {
    const pageStart = pageLength * pageIndex;
    return orders?.slice(pageStart, pageStart + pageLength);
  };

  const filteredOrders: OrderType[] = filterOpenOrdersToShow(openOrders);

  const renderTableHeader = () => (
    <div className={cn(styles.headerRow, tableContentClassName)}>
      <div className={styles.col}>
        <div className="sorting">ID</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Position</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Status</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">
          <div className={styles.inlineRow}>
            Price
            <button className={cn("currency", styles.button)}>UST2</button>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="sorting">
          <div className={styles.inlineRow}>
            Order Amount
            {assetDenomString !== "orders" && (
              <button className={cn("currency", styles.button)}>
                {assetDenomString}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Leverage</div>
      </div>
      <div className={styles.col}>
        <div className="sorting">Type</div>
      </div>
      <div />
      <div className={styles.col}>
        <div className="sorting"> </div>
      </div>
      <div />
    </div>
  );

  const renderTableContent = () => {
    if (!keplrWalletAddress) return null;
    if (filteredOrders.length === 0) {
      return <EmptySet small text="No Open Orders" />;
    }

    return filteredOrders.map((x, index) => {
      let data = null;
      if (x.data) {
        try {
          data = JSON.parse(x.data);
        } catch (err) {
          return {};
        }
      }

      return (
        <div
          className={cn(styles.row, {
            [styles.active]: x.status_description !== "",
          })}
          key={`${x.id}-${x.account}-${x.market}`}
        >
          <div className={styles.col}>{x.id}</div>
          <div className={styles.col}>
            <button
              className={cn(
                positionDirection[x.position_direction]
                  .toString()
                  .toLowerCase(),
                styles.button
              )}
            >
              {positionDirection[x.position_direction]}
            </button>
          </div>
          <div className={styles.col}>
            <div className={styles.stacked}>
              <div className={styles.faintLargerText}>
                {orderStatus[x.status].toString().charAt(0).toUpperCase() +
                  orderStatus[x.status]
                    .toString()
                    .split("_")
                    .join(" ")
                    .slice(1)
                    .toLowerCase()}
              </div>
              <div className={styles.faintText}>{x.status_description}</div>
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.marketPositionText}>
              {`$${formatNumber(x.price)}`}
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.inlineRow}>
              <div className={styles.marketPositionText}>
                {formatNumber(x.quantity)}
              </div>
              {assetDenomString === "orders" && (
                <button className={cn("currency", styles.button)}>
                  {x.market.split("-")[2]}
                </button>
              )}
            </div>
          </div>

          {data && (
            <div className={styles.col}>
              <div
                className={styles.marketPositionText}
              >{`${data.leverage}x`}</div>
            </div>
          )}

          <div className={styles.col}>
            <div className={styles.faintLargerText}>
              {orderType[x.order_type].toString().charAt(0).toUpperCase() +
                orderType[x.order_type].toString().slice(1).toLowerCase()}
            </div>
          </div>
          {x.status == statuses.PLACED ? (
            <div className={styles.col}>
              <button
                className={styles.callToAction}
                onClick={() => onClickCancelOrder(x)}
                key={x.id}
              >
                Cancel Order
              </button>
            </div>
          ) : (
            <div className={styles.col} />
          )}
          <div />
        </div>
      );
    });
  };

  const renderTableFooter = () => {
    if (openOrders.length <= pageLength) return null;
    const numberOfPages = Math.ceil(openOrders.length / pageLength);
    const renderPageNumbers = () => {
      const elements = [];
      for (let i = 0; i < numberOfPages; i++) {
        elements.push(
          <div
            key={i + 1}
            className={cn(
              styles.footerCol,
              i === pageIndex ? styles.footerColSelected : ""
            )}
            onClick={() => setPageIndex(i)}
          >
            <div
              className={
                pageIndex === i ? styles.footerTextBold : styles.footerText
              }
            >
              {i + 1}
            </div>
          </div>
        );
      }
      return elements;
    };
    return <div className={styles.footerRow}>{renderPageNumbers()}</div>;
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableScroll}>
        {renderTableHeader()}
        <div className={cn(styles.content, tableContentClassName)}>
          <>{renderTableContent()}</>
        </div>
      </div>
      {renderTableFooter()}
    </div>
  );
};

export default OpenOrders;
